import { MultiSelectOption } from "@/components/dashboard/pages/global-pricing-rule/components/MultiSelectDropdown";
import { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 1, // Remove padding
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 1, // Remove padding
  }),
  multiValue: (base: any) => ({
    ...base,
    // backgroundColor: "#e5e7eb", // Light gray background
    borderRadius: "8px", // Rounded corners
    padding: "2px 0px", // Adjust padding
  }),
  // multiValueLabel: (base: any) => ({
  //   ...base,
  //   color: "#374151", // Dark text color
  //   fontWeight: "normal",
  // }),
  // multiValueRemove: (base: any) => ({
  //   ...base,
  //   color: "#6b7280", // Gray color
  //   cursor: "pointer",
  //   backgroundColor: "#e5e7eb",
  //   "&:hover": {
  //     backgroundColor: "#e5e7eb", // Light gray background
  //     color: "#f05252",
  //   },
  // }),
};


interface AssetClassesStageProps {
  values: string[]; // Selected Asset Classes
  availableForUse: string[]; // Other Asset Classes available for use
  onValueChanged?: (values: string[]) => void;
}

export const AssetClassesStage: React.FC<AssetClassesStageProps> = ({
  values,
  availableForUse,
  onValueChanged: onValueChange
}) => {

  const options: MultiSelectOption[] = [...values, ...availableForUse].sort().map(ac => ({
    label: ac,
    value: ac
  }));

  // Initialize selectedValues from the passed labels
  const [selectedValues, setSelectedValues] = useState<MultiSelectOption[]>(
    values
      .map((label) => options.find((option) => option.label === label))
      .filter(Boolean) as MultiSelectOption[] // Filter out any unmatched labels
  );

  useEffect(() => {
    setSelectedValues(
      values
        .map((label) => options.find((option) => option.label === label))
        .filter(Boolean) as MultiSelectOption[]
    );
  }, [values, availableForUse]);

  // Handle MultiSelect changes
  const handleMultiSelectChange = (selected: MultiSelectOption[]) => {
    setSelectedValues(selected);
    onValueChange?.(selected.map((opt) => opt.value));
  };

  return (
    <div className="shadow-custom-button flex min-h-36 min-w-[13rem] flex-col items-center justify-center rounded-sm border border-gray-400 bg-white p-4 shadow-lg">
      <div className="font-base mb-5 text-center text-sm">For Asset Classes</div>
      <CreatableSelect
        isMulti
        isClearable
        options={options}
        value={selectedValues}
        className="icon-multi-select-box p-0 px-0 py-0 max-w-[14rem]"
        isSearchable={false}
        classNamePrefix="select"
        onChange={(value: any) => handleMultiSelectChange(value)}
        placeholder="Select Asset Classes"
        styles={customStyles}
      />
      {/* <MultiSelectDropdown
        options={options}
        selectedValues={selectedValues.map((opt) => opt.value)} // Map to values for the dropdown
        onChange={handleMultiSelectChange}
      /> */}
    </div>
  );
};