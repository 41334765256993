import React, { useState } from 'react'
import { PricingRuleDropDownData } from '../models/PricingRules';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Save, Trash2 } from 'lucide-react';
import ConfirmDialog from '@/components/dashboard/pages/global-pricing-rule/components/ConfirmDialog';
import moment from 'moment';

interface EditRuleDialogProps {
    heading?: string;
    defaultRuleName?: string;
    defaultRuleDescription?: string;
    currentRule?: PricingRuleDropDownData;
    onOpenChange: () => void;
    onSaveClick: (name: string, description: string, active: boolean, currentRule?: PricingRuleDropDownData) => void;
    onDeleteRule?: () => void;
}

const EditRuleDialog: React.FC<EditRuleDialogProps> = ({heading, defaultRuleDescription, defaultRuleName, currentRule, onOpenChange, onSaveClick, onDeleteRule}) => {
    const [RuleName, setRuleName] = useState(defaultRuleName || "New Rule");
        const [ruleDescription, setRuleDescription] = useState(defaultRuleDescription || "");
        const [active, setActive] = useState<boolean>(false);
        const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    
        const handleSaveClick = () => {
            onSaveClick(RuleName, ruleDescription, active, currentRule);
            onOpenChange();
        };
    
        const handleDeleteRule = () => {
          setDeleteConfirmation(false);
          onDeleteRule && onDeleteRule();
        };
  return (
    <>
    <Dialog open={true} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen flex flex-col p-5"> {/* border-0 p-5 outline-none */}
        <DialogHeader>
          <DialogTitle>{heading}</DialogTitle>
        </DialogHeader>
        {/* <DialogDescription className='flex flex-row justify-between'>
          
        </DialogDescription> */}
        <div className="p-4">
            {/* Task Name Input */}
            <h2 className="my-3 text-base font-normal">Name:<span className="text-red-600">*</span></h2>
            <input
              type="text"
              value={RuleName}
              onChange={(e) => setRuleName(e.target.value)}
              className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 w-[30vw]"
              required
            />
            {/* <p className="italic text-xs mt-1 text-gray-500">
              File name is required*
            </p> */}
            <h2 className="my-3 text-base font-normal">Description</h2>
            <textarea
              placeholder="Optional description for future reference"
              value={ruleDescription}
              onChange={(e) => setRuleDescription(e.target.value)}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          
            <div className='flex flex-col'>
            <h2 className="my-3 text-base font-normal">Created At: </h2>
              <span className='w-full border p-3 rounded-md mb-2 text-gray-500'>{moment.utc(currentRule?.dateCreated).local().format("YYYY-MM-DD hh:mm:ss A")}</span>
            <h2 className="my-3 text-base font-normal">Last Updated: </h2>
              <span className='w-full border p-3 rounded-md mb-2 text-gray-500'>{moment.utc(currentRule?.dateUpdated).local().format("YYYY-MM-DD hh:mm:ss A")}</span>
            <h2 className="my-3 text-base font-normal">Status: </h2>
              <span className='w-full flex flex-row items-center justify-between gap-4 border p-3 rounded-md mb-2 text-gray-500'>{currentRule?.status}
              {currentRule?.status !== "Active" && (
                <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  className="peer"
                  checked={active}
                  onChange={() => setActive((prev) => !prev)}
                />
                <div className="flex items-center text-blue-600 peer-checked:text-green-400">
                  Set As Active
                </div>
              </label>
              
              )}
              </span>
            </div>
          </div>
        <DialogFooter >
          <div className={`flex w-full flex-row justify-between`}>
          <button title="Remove this rule" 
          className="flex items-center gap-2 rounded-md bg-red-600 px-4 py-2 shadow-md hover:bg-red-700 text-white" 
          onClick={() => setDeleteConfirmation(true)}>
              <Trash2 className='h-4 w-4' />
              Delete Rule
            </button>
        <button
            className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400"
            onClick={handleSaveClick}
          >
            <Save className="h-4 w-4" />
            Save
          </button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    {deleteConfirmation && (
        <Dialog open = {true}>
          <DialogContent className='w-[40rem]'>
          <ConfirmDialog 
            open={true} 
            title="Please Confirm"
            message="Do you want to Delete this rule?" 
            handleClose={() => setDeleteConfirmation(false)} // Only closes confirm dialog
            handleConfirm={handleDeleteRule} // Closes everything
          />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default EditRuleDialog
