import { useState, useRef, useEffect } from "react";
import Spreadsheet, {
  CellBase,
  EntireColumnsSelection,
  EntireRowsSelection,
  RangeSelection,
} from "react-spreadsheet";
import Papa from "papaparse";
import Dialog from "./Dialog";
import {
  Field,
  ThirdPartyLoanTape,
} from "./ThirdPartyLoanTape";
import { CircleHelp } from "lucide-react";
import { motion } from "framer-motion";
import { Button } from "@mui/material";

interface ManualUploadPopup {
  show?: boolean;
  handleDataChange?: (data: ThirdPartyLoanTape[]) => void;
}

const generateEmptyRows = (count: number): ThirdPartyLoanTape[] => {
  const emptyRow = {
    apn: "",
    address: "",
    city: "",
    county: "",
    state: "",
    zip: "",
    parcel_number: "",
    lot_size: "",
    number_of_units: "",
    square_footage: "",
    year_built: "",
    legal_description: "",
    property_type: "",
    property_value: "",
    occupancy_status: "",
    current_occupancy: "",
    original_occupancy: "",
    borrower_first_name: "",
    borrower_last_name: "",
    borrower_ssn_tin: "",
    borrower_fico_score: "",
    borrower_military_status: "",
    borrower_bankruptcy: "",
    borrower_in_contact: "",
    co_borrower_first_name: "",
    co_borrower_last_name: "",
    co_borrower_ssn_tin: "",
    co_borrower_fico_score: "",
    co_borrower_bankruptcy: "",
    loan_id: "",
    loan_type: "",
    loan_purpose: "",
    loan_servicer: "",
    original_creditor: "",
    loan_term_months: "",
    origination_date: "",
    maturity_date: "",
    current_maturity_date: "",
    modification_date: "",
    modification_flag: "",
    original_loan_amount: "",
    unpaid_principal_balance: "",
    current_upb_accruing: "",
    current_upb_deferred: "",
    current_rate: "",
    current_pi_payment: "",
    monthly_interest_amount: "",
    monthly_principal_amount: "",
    monthly_escrow_amount: "",
    escrow_balance: "",
    total_loan_balance: "",
    late_fees_other_charges: "",
    accrued_expenses: "",
    acquisition_cost: "",
    purchase_price: "",
    remaining_holdback_amount: "",
    orig_holdback_amount: "",
    corporate_advances: "",
    default_rate: "",
    delinquency_interest: "",
    delinquency_principal: "",
    delinquency_total: "",
    dutch_interest: "",
    current_aiv: "",
    current_arv: "",
    orig_aiv: "",
    orig_arv: "",
    valuation_date: "",
    fcl_active: "",
    fcl_filing_date: "",
    fcl_stage_most_recent: "",
    fcl_stage_most_recent_date: "",
    bk_active: "",
    bk_chapter: "",
    bk_filing_date: "",
    first_due_date: "",
    first_payment_status: "",
    next_due_date: "",
    days_past_due: "",
    payment_status: "",
    prepaid_months: "",
    first_payoff: "",
    reperforming_date: "",
    lien_position: "",
    legal_status: "",
    law_firm: "",
    flood_flag: "",
    comments: "",
    extension: "",
    mers_id: "",
  };

  return new Array(count).fill(emptyRow);
};

const transformDataToMatrix = (
  data: ThirdPartyLoanTape[],
  loanTapeField: Field[]
): CellBase<any>[][] => {
  return data.map((row) =>
    loanTapeField.map((field: Field) => ({
      value: row[field.key as keyof ThirdPartyLoanTape],
    }))
  );
};

const ManualUploadPopup: React.FC<ManualUploadPopup> = ({
  show = false,
  handleDataChange,
}) => {
  const [data, setData] = useState<ThirdPartyLoanTape[]>([
    ...generateEmptyRows(10),
  ]);
  const [filterData, setFilterData] = useState<ThirdPartyLoanTape[]>([]);
  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [append, setAppend] = useState<boolean | null>(null);
  const [openInstruction, setOpenInstruction] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<{
    start: number;
    end: number;
  } | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const loanTapeField: Field[] = JSON.parse(localStorage.getItem("loanTapeFields") ?? "");
  

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setOpenInstruction(false);
      }
    }
    if (openInstruction) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openInstruction]);

  useEffect(() => {
    setFilterData(
      data.filter((row: ThirdPartyLoanTape) =>
        Object.values(row).some((value) => value !== "")
      )
    );
  }, [data]);

  useEffect(() => {
    handleDataChange && handleDataChange(filterData);
  }, [filterData]);

  useEffect(() => {
    if (append != null) {
      handleAddData();
    }
  }, [append]);

  //handles the import of file from device (parse it to show on the sheet)
  const handleFileImport = (file: File) => {
    if (!file.name.endsWith(".csv")) {
      alert("Only CSV files are supported!");
      return;
    }

    Papa.parse(file, {
      complete: (result: { errors: string | any[]; data: any[] }) => {
        if (result.errors.length > 0) {
          alert("Error reading the CSV file!");
          return;
        }
        const headers: string[] = result.data[0];

        const parsedData: ThirdPartyLoanTape[] = result.data
          .slice(1)
          .map((row: any[]) => {
            return headers.reduce((acc, fieldName, index) => {
              const fieldMeta = loanTapeField.find(
                (field) => field.key === fieldName
              );

              if (fieldMeta) {
                if (
                  fieldMeta.type === "Number" ||
                  fieldMeta.type === "Currency" ||
                  fieldMeta.type === "Percentage"
                ) {
                  acc[fieldName as keyof ThirdPartyLoanTape] = Number(
                    row[index]
                  );
                } else if (fieldMeta.type === "Date") {
                  acc[fieldName as keyof ThirdPartyLoanTape] = new Date(
                    row[index]
                  );
                } else {
                  acc[fieldName as keyof ThirdPartyLoanTape] = row[index] || "";
                }
              }
              return acc;
            }, {} as ThirdPartyLoanTape);
          });
        if (append) {
          setData([...filterData, ...parsedData]);
        } else if (append === false) {
          setData([...parsedData]);
        }
        setAppend(null);
      },
    });
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setSelectedFile(e.target.files[0]);
      // handleFileImport(e.target.files[0]);
      e.target.value = "";
      if (filterData.length > 0) {
        setShowWarningDialog(true);
      } else {
        setAppend(false);
      }
    }
  };

  const handleAddData = () => {
    if (selectedFile) {
      handleFileImport(selectedFile); // Proceed with file import
      setSelectedFile(null); // Clear the stored file
    }
    setShowWarningDialog(false); // Close the dialog
  };

  // Add a new row at the end
  const handleAddRow = () => {
    setData((prevData) => [...prevData, ...generateEmptyRows(1)]);
  };

  // Delete the selected row
  const handleDeleteRow = () => {
    if (selectedRow === null) return;
    setData(
      data.filter(
        (_: any, index: number) =>
          index < selectedRow.start || index > selectedRow.end
      )
    );
    setSelectedRow(null);
  };

  const handleSelection = (selection: any) => {
    if (selection instanceof EntireRowsSelection) {
      const { start, end } = selection;
      setSelectedRow({ start: start, end: end });
    } else if (
      selection instanceof EntireColumnsSelection ||
      selection instanceof RangeSelection
    ) {
      setSelectedRow(null);
    }
  };

  const handleDownload = () => {
    const fileUrl = "/LOAN_TAPE_FILLEDSAMPLE_REAL_DATA_1.csv"; // The file must be inside the `public` folder
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "sample.csv"; // Change this to the file name you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={`${show ? "block" : "hidden"} mt-4`}>
      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileInputChange}
      />
      <div>
        {/* Spreadsheet */}
        <div className={`transition-all duration-150 w-full `}>
          <div className="flex flex-row gap-2 mb-2 items-center justify-end">
            <div className="relative text-right">
              <Button color="secondary" onClick={handleAddRow} >Add a Row</Button>
            </div>
            <div className="relative text-right">
              <Button
              color="secondary"
                onClick={handleDeleteRow}
                disabled={selectedRow === null}
              >Delete Selected Rows</Button>
            </div>
            <div className="relative  text-right">
              <Button
              color="primary"
                onClick={() => fileInputRef.current?.click()}
              >Import File</Button>
            </div>
            <div
              onClick={() => setOpenInstruction(!openInstruction)}
              className="relative cursor-pointer pointer-events-auto"
            >
              <CircleHelp className="text-black" />
              {/* <PrimaryButton text="?" className="!px-3 pt-2 pb-2 rounded-full" /> */}
              {openInstruction && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 0.3 } }}
                  exit={{ opacity: 0, transition: { duration: 0.3 } }}
                  className="absolute top-9 right-0 bg-gray-100 p-4 rounded-xl shadow-lg pointer-events-auto border border-gray-300 w-[30rem] z-50"
                  ref={boxRef}
                >
                  <div className="text-gray-600 mt-5 space-y-1">
                    Please follow these guidelines to prepare your Third-Party
                    Loan Tape:
                  </div>
                  <ul className="list-disc pl-4 text-gray-600 mt-2 space-y-1">
                    <li>
                      <span className="font-bold">
                        {loanTapeField
                          .filter((field: Field) => field.mandatory === "Yes")
                          .map((field: Field) => field.key)
                          .join(", ")}{" "}
                      </span>{" "}
                      are mandatory fields.
                    </li>
                    <li className="font-medium">
                      <span
                        onClick={handleDownload}
                        className="text-blue-600 hover:underline cursor-pointer"
                      >
                        {" "}
                        Download this sample file
                      </span>{" "}
                      for reference or use it as a template to create offline
                      CSV files that you can import in this tool to review and
                      edit.
                    </li>
                    <li>
                      Please note that the first line of any CSV must contain
                      the mandatory header.
                    </li>
                    <li>
                      Once ready, upload the manual entries and add an optional
                      description for future reference.
                    </li>
                  </ul>
                </motion.div>
              )}
            </div>
          </div>
          <div className="overflow-x-scroll w-full">
            <Spreadsheet
              data={transformDataToMatrix(data, loanTapeField)}
              onChange={(matrix) => {
                const updatedData = matrix.map((row) => {
                  const loanData: ThirdPartyLoanTape = {} as ThirdPartyLoanTape;
                  loanTapeField.forEach((header, index) => {
                    (loanData[
                      header.key as keyof ThirdPartyLoanTape
                    ] as any) = row[index]?.value || "";
                  });
                  return loanData;
                });
                setData((prevData) => {
                  if (
                    JSON.stringify(prevData) !== JSON.stringify(updatedData)
                  ) {
                    return updatedData;
                  }
                  return prevData;
                });
              }}
              columnLabels={loanTapeField.map(
                (field: Field) => field.key
              )}
              onSelect={(selection: any) => handleSelection(selection)}
            />
          </div>
        </div>
      </div>

      {/* </Dialog> */}
      {showWarningDialog && filterData.length > 0 && (
        <Dialog
          title="Please Confirm"
          open={true}
          primaryButtonText={"Replace"}
          onCancel={() => {
            setShowWarningDialog(false);
            setSelectedFile(null);
          }}
          onPrimaryButtonClick={handleAddData}
          buttonVisible={false}
        >
          <div className="flex flex-col">
            Do you want the file to replace all entries or append to them?
            <div className="w-full flex flex-row justify-center items-center gap-4 mt-6">
              <Button
              color="primary"
                onClick={() => {
                  setAppend(true);
                  // setShowWarningDialog(false);
                }}
              >Append</Button>
              <Button
              color="secondary"
                onClick={() => {
                  setAppend(false);
                  // setShowWarningDialog(false)
                }}
              >Replace</Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default ManualUploadPopup;
