import SearchIcon from '@/components/icons/SearchIcon';
import useProgressBar from '@/hooks/useProgressBar';
import useStore from '@/store';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import DealsDesktop from '../deals/DealsDesktop';
import DealsMobile from '../deals/DealsMobile';
import './Deals.css';
import DealsBanner from './DealsBanner';
import { PropertyDealDetailsQueryKeys, useDealDetails } from './hooks/'; //useUpdatePropertyDealAttributes
import PropertyDealsTable, { PropertyDealsTableRef } from './PropertyDealsTable';
import { fetchBannersData, fetchDeals, fetchErrorCodes, mapApiResponseToPropertyProps, updateOpportunityNotes, updatePricingForPropertyChanges } from './services/dealService'
import PropertyDetailsModal from './PropertyDetailsModal';
// import { OpportunityPageToken } from './view-models/OpportunityListViewModel';
// import PaginationComponent from "@/components/pagination-component/PaginationComponent";
import { useAuth } from '@/components/AuthProvider';
import PricingIcon from '@/components/icons/PricingIcon';
import { FilterIcon } from 'lucide-react';
import { toast } from 'react-toastify';
import Logo from '../../../branding/Logo';
import QueryBuilderModalComponent from '../../common/QueryBuilderModalComponent';
import { DealStatusType } from './types';
import { OpportunityData } from './view-models/CarouselCardViewModel';
import { OpportunityViewModel } from './view-models/OpportunityViewModel';
import PricingRuleSettingsDialog from '../../../pricing-rule/PricingRuleSettingsDialog';
import { ErrorCode } from './view-models/ErrorCode';
import { formatDateString } from '@/lib/utils';
import { PropertyUpdate } from '../../common/PropertyValuationTypeChangeDialog';
import LoadingModal from '../../common/LoadingModal';
import { BannerDetailsViewModel } from './view-models/BannerDetailsViewModel';
import { useOpportunityCounts } from '../../../OpportunityCountsProvider';
import Dialog from '@/components/uploadLoanTape/Dialog';
import { OfferPropertiesUpload } from '@/components/uploadLoanTape/Uploads';
import { ThirdPartyLoanTape, upload, UploadDetails } from '@/components/uploadLoanTape/ThirdPartyLoanTape';
import { PropertyProps } from './Property.interface';
import AlertStickyToast from '../../common/AlertStickyToast';

// import { set } from "date-fns";
// import { useAuth } from "@/components/AuthProvider";

interface DealsV2Props {
  status: DealStatusType;
  useDynamicPaging?: boolean;
}

export const LIMIT_FILTER_PREVIEW = 5;

const DealsV2 = ({ status }: DealsV2Props) => {
  const auth = useAuth();

  const [activeDealIndex, setActiveDealIndex] = useState(0);
  const [errorCodes, setErrorCodes] = useState<ErrorCode[]>([]);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
  const [page, setPage] = useState(0);
  //const [token,] = useState<OpportunityPageToken>();
  const setRenderMiddleHeaderContent = useStore((state) => state.setRenderMiddleHeaderContent);
  const queryClient = useQueryClient();
  //const cachedOpportunities = queryClient.getQueryData([OpportunityQueryKeys, page]);
  //const { data: opportunityData, error: opportunityError, isLoading: isLoadingOpportunities } = useDeals(page, token, !cachedOpportunities, status, auth?.sub);
  const { data: propertyDetails, error: _propertyDetailsError } = useDealDetails(selectedPropertyId);
  //const { data: bannerDetails, error: bannerDetailsError, isLoading: isLoadingBannerDetails } = useBannerDetails(status);
  const [bannerDetails, setBannerDetails] = useState<BannerDetailsViewModel>();
  const [, setHasMoreItems] = useState(true);
  const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
  const [isOpenQueryModal, setIsOpenQueryModal] = useState(false);
  const [isOpenPricingModal, setIsOpenPricingModal] = useState(false);
  // const [isUnsavedChange, setIsUnsavedChange] = useState(false);
  const [renderProgressBarUI, showProgressBar, hideProgressBar] = useProgressBar();
  const tableRef = useRef<PropertyDealsTableRef | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
  const [_isLoadingBannerDetails, setIsLoadingBannerDetails] = useState<boolean>(false);
  const {setOpportunityCounts} = useOpportunityCounts();
  const [openBorrowDialog, setopenBorrowDialog] = useState(false)
  const [showLoadTapeUpload, setShowLoadTapeUpload] = useState<boolean>(false);

  const [unsavedPropertyChanges, setUnsavedPropertyChanges] = useState<{[opportunityID:string]: PropertyUpdate[]}>({});

  // useEffect(() => {
  //   if (!isLoadingOpportunities && !isLoadingBannerDetails) {
  //     hideProgressBar();
  //   }
  // }, [isLoadingOpportunities, isLoadingBannerDetails]);

  const updateBorrowerDetails = (property : PropertyProps, borrowerThirdPartyID  :string)=>{
    let newOpp = opportunities[activeDealIndex]
    newOpp.properties?.forEach(p => {if(p.id === property.id) p.borrowerThirdPartyID = borrowerThirdPartyID})
    opportunities[activeDealIndex] = newOpp
    setOpportunities(opportunities)
  }

  const setBanner = async (opportunityId?:string) => {
    setIsLoadingBannerDetails(true);
    if (opportunityID) {
      setOpportunities(opportunities.filter(o => o.opportunityId != opportunityId))
    }
    return fetchBannersData()
      .then(data => {
        const currentBannerData = data.find(b => b.status === status);
        setBannerDetails(currentBannerData);
        setOpportunityCounts(data)})
      .catch(err => console.log(err))
      .finally(() => setIsLoadingBannerDetails(false));
  }

  useEffect(() => { 
    setBanner()
    .then(()=>setLoadingMessage("Fetching opportunities...."))
    .then(()=>fetchDeals(status, undefined, undefined, page))
    .then((opportunityData) =>{
      if (opportunityData) {
        hideProgressBar();
        const opportunityList = (opportunityData as OpportunityViewModel)?.opportunities || [];
        const isValidPageToken = !!(opportunityData as OpportunityViewModel).pageToken && !!(opportunityData as OpportunityViewModel).pageToken.opportunityID;
        const opportunityIds = new Set(opportunities.map(ol => ol.opportunityId));
  
        const difference = opportunityList.filter((o: any) => !opportunityIds.has(o.opportunityId));
  
        setHasMoreItems(isValidPageToken);
        if (page === 0 && opportunityList.length === 0) {
          setOpportunities([]);
        } else if (
          page === 1 && opportunities.length === 0
        ) {
          setOpportunities([...opportunityList]);
          setActiveDealIndex(0);
        }
        else if (difference.length > 0) {
          setOpportunities([...opportunities, ...difference]);
        }
        else if(page >= 1 && opportunityList.length == 0) {
          //Ignore
        }
        else {
          setOpportunities([...opportunityList]);
        }
      }
    })
    .catch(()=>{
      toast.error('An error occurred');
    })
    .finally(()=>{
      setLoadingMessage(null);
    }) 
  }, [page]);

  useEffect(() => {
    // Define the middle content
    const middleContent:any = (): JSX.Element => (
      <>
        <div className="hidden w-full items-center md:flex">
          <div className="flex w-1/2 items-center">
            <h1 className="title mr-5 text-4xl">Opportunities</h1>
            {/* {(opportunityError || propertyDetailsError)
                            && <div className="text-red-500">Error: {opportunityError?.message || propertyDetailsError?.message}</div>} 
                        <p className="oppurtunity-count mr-5">25</p> */}
            {/* <p className="new-update !text-[15px]"><span>New: 6</span><span className="ml-3">Updated: 8</span></p> */}
            {/* <p className="new-update !text-[15px] !text-[#929DAD] font-semibold">
                            <span className="ml-3">
                                {DEAL_STATUS.InReview} Bookmarked: {countByStatus(opportunityStatuses, DEAL_STATUS.InReview)}
                            </span>
                            <span className="ml-3">
                                {DEAL_STATUS.Rejected} Bookmarked: {countByStatus(opportunityStatuses, DEAL_STATUS.Rejected)}
                            </span>
                        </p> */}
          </div>
          <div className="w-1/2">
            <div className="flex w-full items-center justify-end">
              <div className="">
                <button className="btn" onClick={() => setIsOpenPricingModal(true)}>
                  <PricingIcon />
                </button>
              </div>
              <div className="pl-5">
                <button className="btn" onClick={() => setIsOpenQueryModal(true)}>
                  <FilterIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center md:hidden">
          <div>
            <button className="btn">
              <SearchIcon />
            </button>
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            <div className="items-center justify-center">
              <Logo className="mb-3 ml-0 mr-auto mt-2 h-auto w-[82px] lg:ml-auto lg:w-[109px] lg:-translate-x-3" />
            </div>
            <div className="flex w-[90%] flex-col items-center justify-center whitespace-nowrap text-sm font-medium">07/17/24-07/31/24</div>
          </div>
        </div>
      </>
    );

    // Set the middle content in the store
    setRenderMiddleHeaderContent(middleContent);
  }, [setRenderMiddleHeaderContent]);

  useEffect(() => {
    if (propertyDetails) {
      hideProgressBar();
      setIsOpenDetailsModal(true);
    }
  }, [propertyDetails]);

  // useEffect(() => {
  //   const error = opportunityError || propertyDetailsError;

  //   if (error) {
  //     hideProgressBar();

  //     console.error(error);
  //     toast.error(error?.message || 'An error occurred');
  //   }
  // }, [opportunityError, propertyDetailsError]);

  const onViewPropertyDetailsHandler = (propertyId: string) => {
    console.log('onViewPropertyDetailsHandler v2: ', propertyId);
    setSelectedPropertyId(propertyId);
    const cachedPropertyDetails = queryClient.getQueryData([PropertyDealDetailsQueryKeys, propertyId]);
    if (cachedPropertyDetails === undefined) {
      showProgressBar();
    } else {
      setIsOpenDetailsModal(true);
    }
  };

  const clearUnsavedPropertyChanges = (opportunityID: string) => {
    if (opportunityID && unsavedPropertyChanges[opportunityID]) {
      const { [opportunityID]: _, ...rest } = unsavedPropertyChanges;
      setUnsavedPropertyChanges(rest);
    }
  }

  const updateOpportunityProperties = async (data: PropertyUpdate[]): Promise<OpportunityData> => {
    const opportunity: OpportunityViewModel = opportunities[activeDealIndex];
    if (!(opportunity && opportunity.opportunityId && opportunity.properties)) {
      return Promise.reject("DEV Error: Invaid Opportunity");
    }
    setLoadingMessage("Please wait while the opportunity is repriced...");
    return updatePricingForPropertyChanges(auth!.sub, opportunity.opportunityId, opportunity.properties.length, data)
      .then((oppor) => {
        toast.success("Repriced offer successfully!");
        if (opportunity.opportunityId) {
          if (handleUpdateTableValue) {
            handleUpdateTableValue(opportunity.opportunityId, oppor);
            setBanner()
          }
          clearUnsavedPropertyChanges(opportunity.opportunityId);
        }
        return oppor;
      }).catch(() => {
        toast.error("Failed to reprice offer!");
        return Promise.reject("Failed to reprice offer!");
      }).finally(() => {
        setLoadingMessage(null);
      });
  };
  const onPageChangeHandler = (page: number) => {
    // const cachedOpportunities = queryClient.getQueryData<OpportunityListViewModel>([OpportunityQueryKeys, status, page, auth!.sub]);

    // If data is already cached, do not fetch again
    // if (cachedOpportunities !== undefined) {
    //   setToken(cachedOpportunities.pageToken);
    //   setPage(page);
    //   return;
    // }

    // console.log('>>>>>> opportunities ', opportunityData, page)
    // if (opportunityData && opportunityData.pageToken && hasMoreItems) {
    //   setToken(opportunityData.pageToken);
    // }
    hideProgressBar(); // Hide progress bar when next set of data is loaded
    setPage(page);
  };

  const handleSlideChange = (index: number) => {
    setActiveDealIndex(index);
    // console.log('>>>>>> opportunities activeDealIndex ...',activeDealIndex, opportunities);

    // Load more data if the last item is reached
    if (index != 0 && index === opportunities.length - 4) {
      onPageChangeHandler(page + 1);
    }
  };

  const onUpdateDealsDesktopValue = (opportunityId: string, newValues: Record<string, number>) => {
    console.log('onUpdateDealsDesktopValue: ', newValues);

    const clonedOpportunities = [...opportunities];
    const updatedOpportunity = clonedOpportunities.find((o) => o.opportunityId === opportunityId);

    if (!updatedOpportunity) {
      console.error('Opportunity not found');
      return;
    }

    // if (newValues.adjustedOpportunityMAOB) {
    //   updatedOpportunity.carouselCard.opportunityData.adjustedOpportunityMAOB = newValues.adjustedOpportunityMAOB;
    // }

    // if (newValues.discountPercentMAOB) {
    //   updatedOpportunity.carouselCard.opportunityData.discountPercentMAOB = newValues.discountPercentMAOB;
    // }
    if (updatedOpportunity.carouselCard) {
      if (newValues.adjustedOpportunityMAOB) {
        updatedOpportunity.carouselCard.opportunityData.adjustedOpportunityMAOB = newValues.adjustedOpportunityMAOB;
      }

      if (newValues.discountPercentMAOB) {
        updatedOpportunity.carouselCard.opportunityData.discountPercentMAOB = newValues.discountPercentMAOB;
      }
    } else {
      console.error('carouselCard is undefined');
    }

    setOpportunities(clonedOpportunities);
    setBanner();
  };

  const handleUpdateTableValue = (opportunityId: string, newValue: OpportunityData) => {
    const clonedOpportunities = [...opportunities];
    const updatedOpportunity = clonedOpportunities.find((o) => o.opportunityId === opportunityId);

    if (!updatedOpportunity) {
      console.error('Opportunity not found');
      return;
    }
    if (updatedOpportunity.carouselCard) {
      updatedOpportunity.carouselCard.opportunityData = newValue;
    }
    
    updatedOpportunity.opportunityData = { ...newValue, properties: newValue.opportunityProperties };
    updatedOpportunity.properties = updatedOpportunity.properties?.map((p: any) =>
      mapApiResponseToPropertyProps({
        ...p,
        ...(updatedOpportunity.opportunityData.properties.find((op: any) => op.propertyID === String(p.id)) || {}),
      })
    );

    setOpportunities(clonedOpportunities);
  };

  const onNoteSave = (opportunityID: string, note: string) => {
    console.log(`OpportunityID: ${opportunityID}, Note: ${note}`);
    const clonedOpportunities = [...opportunities];
    const updatedOpportunity = clonedOpportunities.find(opp => opp.opportunityId === opportunityID);
    setLoadingMessage('Saving note...')
    updateOpportunityNotes(opportunityID, parseInt(updatedOpportunity?.totalProperties ?? "0"), auth!.sub, note)
      .then(({ opportunityData }) => {
        if (updatedOpportunity && updatedOpportunity.carouselCard && updatedOpportunity.carouselCard.opportunityData) {
          updatedOpportunity.carouselCard.opportunityData.notes = opportunityData.notes
          toast.success("Notes saved successfully");
        }
      }).catch(() => {
        toast.error("Failed to save notes");
      })
      .finally(() => {
        setLoadingMessage(null);
        setOpportunities(clonedOpportunities);
      })
  };

  const uploadLoanTape = async (manualEntries?: ThirdPartyLoanTape[], file?: File, name?: string, description?: string): Promise<UploadDetails> => {
    if (!opportunities || !opportunities[activeDealIndex]) {
      throw new Error("No opportunity found");
    }
    const { lenderID, opportunityId, properties } = opportunities[activeDealIndex];
    const response = await upload(
      lenderID ?? "",
      file,
      manualEntries,
      name,
      description,
      opportunityId,
      properties?.length
    );

    if (opportunityId) clearUnsavedPropertyChanges(opportunityId);
    setOpportunities(prev => {
      if (response.updatedOpportunity) prev[activeDealIndex] = response.updatedOpportunity;
      return prev;
    });
    setBanner();
    
    return response;
  }

  useEffect(() => {
    fetchErrorCodes()
      .then(res => {
        setErrorCodes(res);
      })
      .catch(err => console.log(err))
  }, [])

  const opportunityID = opportunities?.[activeDealIndex]?.opportunityId;
  const hasUnsavedChanges = opportunityID != null && unsavedPropertyChanges[opportunityID]?.length > 0;

  return (
    <>
      {renderProgressBarUI()}
      <div className="deals-container hidden w-full flex-col gap-0 md:flex">
        {/* {
                    totalPages > 0 && (
                        <PaginationComponent
                            totalPages={useDynamicPaging ? totalPages : maxPages}
                            setPage={onPageChangeHandler}
                            page={page}
                            isDisabledNext={useDynamicPaging ? disabledNextButton : page === maxPages}
                            numVisibleLinks={LIMIT_OPPORTUNITIES_PER_PAGE} />
                    )
                } */}
        {bannerDetails &&
          <>
            <div className='sticky top-0 z-10 flex flex-col w-full bg-white shadow-md justify-center'>
              <DealsBanner details={bannerDetails} />
            </div>
            <div className="text-center my-3">
              {bannerDetails.createdAt && <p>{formatDateString(bannerDetails.createdAt)}</p>}
            </div>
          </>}
        {/* {bannerData && <DealsBanner
                    details={{
                        totalOpportunities: bannerData.numberOfOpportunities,
                        totalProperties: bannerData.numberOfProperties,
                        totalAIV: bannerData.totalMarketValue,
                        totalUPB: bannerData.totalUPB,
                        averageAIV: bannerData.averageMarketValue,
                        profitPercentage: bannerDetails?.profitPercentage || 0,
                        createdAt: bannerDetails?.createdAt || '',
                        totalPropertiesAsIsValue: bannerDetails?.totalPropertiesAsIsValue || 0,
                        metrics_uuid: bannerDetails?.metrics_uuid || '',
                        averagePropertyAsIsValue: bannerDetails?.averagePropertyAsIsValue || 0,
                    }} />} */}
        {opportunities.length === 0 && (
          <div className="flex h-[300px] items-center justify-center">
            <p className="text-xl">No opportunities found</p>
          </div>
        )}

        {isOpenPricingModal && (
          <PricingRuleSettingsDialog
            isOpen={isOpenPricingModal}
            onOpenChange={() => setIsOpenPricingModal(!isOpenPricingModal)}
            onLoadingStart={showProgressBar}
            onLoadingComplete={hideProgressBar}
          />
        )}

        {isOpenQueryModal && (
          <QueryBuilderModalComponent
            isOpen={isOpenQueryModal}
            toggleModal={() => setIsOpenQueryModal(!isOpenQueryModal)}
            showProgressBar={showProgressBar}
            hideProgressBar={hideProgressBar}
          />
        )}

        <DealsDesktop
          initialOpportunities={opportunities}
          onSlideChange={handleSlideChange}
          hideProgressBar={hideProgressBar}
          showProgressBar={showProgressBar}
          onNoteSave={(opportunityID, note) => onNoteSave(opportunityID, note)}
          onUpdateValue={onUpdateDealsDesktopValue}
          onOpportunityUpdate={setBanner}
        />
        <div>
          {/* {console.log('>>>>>> opportunities ', opportunities, activeDealIndex)} */}
          {activeDealIndex !== undefined && opportunities?.[activeDealIndex] && opportunities?.[activeDealIndex]?.carouselCard && opportunities?.[activeDealIndex]?.carouselCard?.opportunityData && (
            <>
              <div className="flex justify-between">
                <div className="my-5">
                  <h3 className="text-xl font-semibold text-black dark:text-white">
                    {opportunities[activeDealIndex].lenderName} - Property Deals
                  </h3>
                  <div className="flex flex-row items-center justify-left text-xs italic text-gray-500">
                    Select properties to be included in this opportunity.
                  </div>
                </div>
                <div className="flex items-center">
                <button
                  className="apply-new-query-button"
                  onClick={() => setShowLoadTapeUpload(true)}
                >Add Properties</button>
                <button
                  disabled={!hasUnsavedChanges}
                  className={!hasUnsavedChanges ? 'ml-2 apply-new-query-button !bg-gray-300 !text-gray-500' : 'ml-2 apply-new-query-button'}
                  onClick={() => opportunityID && unsavedPropertyChanges[opportunityID] && updateOpportunityProperties(unsavedPropertyChanges[opportunityID])}
                >Save</button>
                <button
                  disabled={!hasUnsavedChanges}
                  onClick={() => {
                      if (opportunityID) clearUnsavedPropertyChanges(opportunityID);
                      tableRef.current?.handleDiscrdClick();
                    }}
                  className={!hasUnsavedChanges ? 'ml-2 apply-new-query-button !bg-gray-300 !text-gray-500' : 'apply-new-query-button ml-2 !bg-red-600 !hover:bg-red-600 text-white'}
                >Discard</button>
                </div>
              </div>
              <PropertyDealsTable
                ref={tableRef}
                lenderName={opportunities[activeDealIndex] && opportunities[activeDealIndex].carouselCard?.lenderName}
                onDetailsClick={onViewPropertyDetailsHandler}
                properties={opportunities[activeDealIndex].properties || []}
                propertyChanges={unsavedPropertyChanges[opportunityID || ''] || []}
                opportunity={opportunities[activeDealIndex]?.carouselCard?.opportunityData}
                onUpdateValue={handleUpdateTableValue}
                errorCodes={errorCodes}
                showProgressBar={showProgressBar}
                hideProgressBar={hideProgressBar}
                onImmediatePropertyUpdates={updateOpportunityProperties}
                onPropertyFieldChange={(propertyId, field, fromValue, toValue) => {
                  if (!opportunityID) return;
                  const property = opportunities[activeDealIndex]?.properties?.find(p => p.id === propertyId);
                  if (!property) return;
                  // remove existing unsaved field change for this property, if any
                  const newPropertyUpdates = unsavedPropertyChanges[opportunityID]?.filter(p => p.propertyID !== propertyId && p.attribute === field) || [];
                  if (fromValue !== toValue) {
                    newPropertyUpdates.push({
                      propertyID: propertyId,
                      attribute: field,
                      value: toValue,
                      operation: "update",
                    });
                  }
                  setUnsavedPropertyChanges({ ...unsavedPropertyChanges, [opportunityID]: newPropertyUpdates });
                }}
                setOpenBorrowDialog={setopenBorrowDialog}
                openBorrowDialog={openBorrowDialog}
                opportunities={opportunities}
                updateBorrowerDetails={updateBorrowerDetails}
                />
            </>
          )}
        </div>
        {activeDealIndex !== undefined && opportunities?.[activeDealIndex] && opportunities?.[activeDealIndex]?.properties && (
          <PropertyDetailsModal
            isOpen={isOpenDetailsModal}
            toggleModal={() => setIsOpenDetailsModal(!isOpenDetailsModal)}
            property={opportunities[activeDealIndex].properties!.find((p) => p.id === selectedPropertyId)}
            details={propertyDetails}
          />
        )}
      </div>
      <div className="deals-container flex w-full flex-col gap-5 md:hidden">
        <DealsMobile />
      </div>

      {showLoadTapeUpload && (
        <Dialog
          open={true}
          onCancel={() => setShowLoadTapeUpload(false)}
          title="Add Properties"
          buttonVisible={false}
        >
          <OfferPropertiesUpload
            steps={[
              "Prepare Loan Tapes",
              "Upload Loan Tapes",
            ]}
            className="my-2 w-full min-w-[60vw] !border-none !py-0"
            onCancel={() => setShowLoadTapeUpload(false)}
            onClickForCloseInLastStep={() => {
              setShowLoadTapeUpload(false);
            }}
            buttonTextToCloseInLastStep="Submit for Reprice"
            handleBulkFileUpload={(file, description) => uploadLoanTape(undefined, file, undefined, description)}
            handleManualFileUpload={(data, name, description) => uploadLoanTape(data, undefined, name, description)}
          />
        </Dialog>
      )}
      <LoadingModal open={loadingMessage != null} message={loadingMessage || ""} />
      <AlertStickyToast open={hasUnsavedChanges} message="There are unsaved changes in this opportunity.\nPlease save." />
    </>
  );
};

export default DealsV2;
