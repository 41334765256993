import React, { useEffect, useRef, useState } from "react";
import SmallLabel from "./SmallLabel";
import SmallValue from "./SmallValue";
import {
  Field,
  ThirdPartyLoanTape,
} from "./ThirdPartyLoanTape";
import Papa from "papaparse";
import { Button } from "../ui/button";

interface UploadedFile {
  name: string;
  file: File;
}
interface BulkUploadPopupProps {
  onClose?: () => void;
  onCompleteUpload?: () => void;
  title?: string;
  show?: boolean;
  handleDataChange?: (data: ThirdPartyLoanTape[]) => void;
  handleFileUpload?: (file: File) => void;
  handleDescriptionChange?: (description: string) => void;
}

export interface ReportData {
  batchID: string;
  numRecords: number;
  numFailedRecords: number;
  startedAt: string;
  durationMS: number;
  downloadOriginalCSV: string;
  downloadFailedCSV: string;
  downloadReport: string;
}

const BulkUploadPopup: React.FC<BulkUploadPopupProps> = ({
  show = false,
  handleDataChange,
  handleFileUpload,
  handleDescriptionChange,
}) => {
  const [bulkData, setBulkData] = useState<ThirdPartyLoanTape[]>([]);
  const [description, setDescription] = useState<string>(""); 
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile>(); 
  const [uploadComplete] = useState<boolean>(false); 

  const fileInputRef = useRef<HTMLInputElement>(null);
  const loanTapeField: Field[] = JSON.parse(localStorage.getItem("loanTapeFields") ?? "");

  useEffect(() => {
    handleDataChange && handleDataChange(bulkData);
  }, [bulkData]);

  useEffect(() => {
    handleFileUpload && uploadedFiles && handleFileUpload(uploadedFiles.file);
  }, [uploadedFiles]);

  // Handle File Change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) {
      return;
    }
    const csvFiles = Array.from(files).filter(
      (file) => file.type === "text/csv"
    );

    if (handleDataChange !== undefined) {
      csvFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const csvData = event.target?.result as string;

          Papa.parse(csvData, {
            header: true, // First row is treated as headers
            skipEmptyLines: true,
            complete: (result) => {
              if (result.errors.length > 0) {
                // setErrorMessage("Error reading the CSV file!");
                return;
              }

              // Transform parsed data into LoanData format
              const parsedData: ThirdPartyLoanTape[] = result.data.map(
                (row: any) => {
                  const parsedRow: Partial<ThirdPartyLoanTape> = {};

                  loanTapeField.forEach(({ key }) => {
                    parsedRow[key as keyof ThirdPartyLoanTape] =
                      row[key];
                  });
                  return parsedRow as ThirdPartyLoanTape;
                }
              );

              // Append parsed data to bulkData state
              setBulkData((prevData) => [...prevData, ...parsedData]);
            },
          });
        };

        reader.readAsText(file);
      });
    }

    // Store uploaded file metadata
    setUploadedFiles({
      name: csvFiles[0].name,
      file: csvFiles[0],
    });
  };

  const onDesctiptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
    handleDescriptionChange && handleDescriptionChange(e.target.value);
  };

  const handleDownload = () => {
    const fileUrl = "/LOAN_TAPE_FILLEDSAMPLE_REAL_DATA_1.csv"; // The file must be inside the `public` folder
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "sample.csv"; // Change this to the file name you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const preventBackgroundScroll = (event: WheelEvent) => {
      event.stopPropagation();
      event.preventDefault();
    };
    document.body.style.overflow = "hidden";
    window.addEventListener("wheel", preventBackgroundScroll, {
      passive: false,
    });
    return () => {
      document.body.style.overflow = "";
      window.removeEventListener("wheel", preventBackgroundScroll);
    };
  }, []);
  // Remove File
  const removeFile = () => {
    setUploadedFiles(undefined);
    // setErrorMessage("");
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <>
      {/* Task Name and File Upload */}
      {!uploadComplete && (
        <>
          <div className={`${show ? "block" : "hidden"}`}>
            <div className="p-4]">
              <div className="mt-5 mb-8">
                <div className="text-gray-600 space-y-1">
                  Please follow these guidelines to prepare your Third-Party
                  Loan Tape:
                </div>
                <ul className="list-disc pl-4 text-gray-600 mt-2 space-y-1">
                  <li>
                    <span className="font-bold">
                      {loanTapeField
                        .filter((field: Field) => field.mandatory === "Yes")
                        .map((field: Field) => field.key)
                        .join(", ")}{" "}
                    </span>{" "}
                    are mandatory fields.
                  </li>
                  <li className="font-medium">
                    <span
                      onClick={handleDownload}
                      className="text-blue-600 hover:underline cursor-pointer"
                    >
                      {" "}
                      Download this sample file
                    </span>{" "}
                    for reference or use it as a template to create CSV files.
                    Please note that the first line must contain the mandatory
                    header.
                  </li>
                  <li>
                    Upload the CSV file below and add an optional description
                    for future reference.
                  </li>
                </ul>
              </div>
              {/* Task Name Input */}
              <div>
                <SmallLabel className="mb-3">
                  Third-Party Loan Tape File
                </SmallLabel>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept=".csv"
                  // multiple
                  onChange={handleFileChange}
                  className="hidden"
                />
                <div className="flex flex-col justify-start mt-4 gap-0 w-auto">
                  <Button
                  variant="secondary"
                  className="w-fit"
                    onClick={() => fileInputRef.current?.click()}
                  >Select CSV file</Button>
                  <p className="text-xs text-gray-500 mt-1">
                    <i>Only *.csv files are accepted.</i>
                  </p>
                </div>
              </div>

              {/* Selected Files */}
              <div className="mt-3">
                {uploadedFiles && (
                  <div
                    key={uploadedFiles.name}
                    className="flex items-center justify-between bg-gray-50 p-2 rounded border mb-2"
                  >
                    <SmallValue className="truncate">
                      {uploadedFiles.name}
                    </SmallValue>
                    <button
                      className="ml-2 text-gray-500 hover:text-red-500"
                      onClick={() => removeFile()}
                    >
                      ✕
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="p-4">
              {/* Task Name Input */}
              <SmallLabel className="mb-3">Description</SmallLabel>
              <textarea
                id="taskName"
                placeholder="Optional description for future reference"
                value={description}
                onChange={onDesctiptionChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div className="flex items-center justify-center mx-3 mb-3 gap-3"></div>
        </>
      )}
    </>
  );
};

export default BulkUploadPopup;
