import './EditableNumberField.css';
import React, { useEffect, useRef, useState } from 'react';
import { Pencil, Check, X, Undo } from 'lucide-react';

interface EditableNumberFieldProps {
  /**
   * If provided, a revert button will be provided to reset the value
   */
  defaultValue?: number;
  value?: number;
  prefix?: string;
  suffix?: string;
  allowDecimal?: boolean;
  locale?: string;
  /**
   * Called when the user clicks the 'check' icon or presses ENTER.
   * @param newValue - The new value.
   */
  onChange?: (newValue: number) => void;
  /**
   * Called if the user discards changes (clicks the 'x' icon or presses ESC).
   */
  onDiscard?: () => void;
  /**
   * Called if the user reverts changes (clicks the 'undo' icon).
   */
  onRevert?: () => void;
  /** Optional class name for external styling. */
  className?: string;
  /** Optional style object for inline styling. */
  style?: React.CSSProperties;
  /**
   * Fixes the width of the display field to accomodate these number
   * of digits with the formatting, if any. Ignore to fit to content.
   */
  numDigitsFixedDisplayWidth?: number;
  /**
   * Fixes the width of the input field to accomodate these number
   * of digits with the formatting, if any. Defauls to 10 digits.
   */
  numDigitsFixedInputWidth?: number;
}

const EditableNumberField = ({
  defaultValue,
  value = defaultValue,
  prefix,
  suffix,
  allowDecimal = true,
  locale = 'en-US',
  onChange,
  onDiscard,
  onRevert,
  className,
  style,
  numDigitsFixedDisplayWidth,
  numDigitsFixedInputWidth = Math.max(
    10, // default number of digits
    (value || 0).toString().length, 
  ),
}: EditableNumberFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [displayValue, setDisplayValue] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);

  const localeFormatter = new Intl.NumberFormat(locale);
  const formatter = (v?: number, addingDecimal: boolean = false) =>
    `${prefix || ''}${!isFinite(v as number) && !addingDecimal ? '-' : localeFormatter.format(v || 0)}${addingDecimal ? '.' : ''}${suffix || ''}`;

  const getMinCursorPosition = () => (prefix ? prefix.length : 0);
  const getMaxCursorPosition = () => (inputRef.current?.value.length || 0) - (suffix ? suffix.length : 0);

  useEffect(() => {
    if (!isEditing) setDisplayValue(formatter(value));
  }, [value]);

  // When we switch to edit mode, optionally focus the input
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      // move cursor to the end
      const end = getMaxCursorPosition();
      inputRef.current.setSelectionRange(end, end);
    }
  }, [isEditing]);

  /**
   * Parse the number from the display string
   */
  const parseValue = (v: string): number => parseFloat(v.replace(/[^\d.]/g, ''));

  /**
   * Handle user changes in edit mode.
   */
  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEditing) return;

    const number = parseValue(e.target.value);
    const isAddingDecimal = (suffix ? e.target.value.slice(0, -suffix.length) : e.target.value).endsWith('.');
    setDisplayValue(formatter(number, isAddingDecimal));

    // Maintain cursor position
    if (inputRef.current) {
      setTimeout(() => {
        const cursorPosition = e.target.selectionStart == null ? getMinCursorPosition() : Math.min(e.target.selectionStart, getMaxCursorPosition());
        inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  };

  const handleSave = () => {
    const parsed = parseValue(displayValue);
    onChange?.(parsed);
    setIsEditing(false);
  };

  const handleDiscard = () => {
    onDiscard?.();
    setDisplayValue(formatter(value));
    setIsEditing(false);
  };

  const handleRevert = () => {
    onRevert ? onRevert() : onChange?.(parseValue(defaultValue?.toString()??""));
    // if (defaultValue != null) onChange?.(defaultValue);
    setDisplayValue(formatter(defaultValue));
    console.log('------ reverted back to ' + formatter(defaultValue));
  };

  /**
   * Exits edit mode when user presses 'Escape'. Saves if 'Enter' is pressed.
   */
  const onInputKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      handleDiscard();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (isFinite(parseValue(displayValue))) handleSave();
    } else if (!allowDecimal && e.key === '.') {
      e.preventDefault();
    }
  };

  const FONT_WIDTH_PER_HEIGHT = 0.6;
  return (
    <div className={`inline-flex items-center justify-start ${className || ''}`} style={style}>
      {/* Field */}
      <div className="mr-[0.5em] flex h-[32px] items-center">
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={displayValue}
            onChange={onInputChanged}
            onKeyDown={onInputKeyPressed}
            className={`m-0 w-auto min-w-[1px] border-none bg-transparent p-0 text-right focus:outline-none focus:ring-0`}
            style={{
              fontSize: "1em",
              width: `${
                FONT_WIDTH_PER_HEIGHT * ((prefix?.length || 0) + (suffix?.length || 0) + Math.max(numDigitsFixedInputWidth, (parseValue(displayValue) || 0).toString().length))
              }em`,
            }}
          />
        ) : (
          <span
            className={`m-0 p-0 text-right`}
            style={{
              fontSize: "1em",
              minWidth: numDigitsFixedDisplayWidth == null ? 'auto' : `${FONT_WIDTH_PER_HEIGHT * ((prefix?.length || 0) + (suffix?.length || 0) + numDigitsFixedDisplayWidth)}em`,
            }}
          >
            {displayValue}
          </span>
        )}
      </div>

      {/* Control Buttons */}
      <div className="mr-[0.5em] flex items-center">
        {isEditing ? (
          <>
            <div title={!isFinite(parseValue(displayValue)) ? 'Invalid' : 'OK'}>
              <Check
                className={`button-${isFinite(parseValue(displayValue)) ? 'enabled' : 'disabled'} mr-[0.5em]`}
                onClick={!isFinite(parseValue(displayValue)) ? undefined : handleSave}
                size="1em"
              />
            </div>
            <div title="Cancel">
              <X className="button-enabled" onClick={handleDiscard} size="1em" />
            </div>
          </>
        ) : (
          <>
            <div title="Edit">
              <Pencil
                className="button-enabled mr-[0.7em]"
                onClick={() => setIsEditing(true)}
                // The pencil is diagonal, thus larger than the other icons.
                // The size is adjusted to match it with others, and the difference (0.2em)
                // is added to right margin to fill up with whitespace.
                size="0.8em"
              />
            </div>
            <div title={defaultValue != null && formatter(defaultValue) !== displayValue ? 'Revert to ' + formatter(defaultValue) : 'Nothing to revert'}>
              <Undo
                className={`button-${defaultValue != null && formatter(defaultValue) !== displayValue ? 'enabled' : 'disabled'}`}
                onClick={defaultValue != null && formatter(defaultValue) !== displayValue ? handleRevert : undefined}
                size="1em"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditableNumberField;

/**
 * Multiplies by 100 with String operations (avoiding floating point complications)
 */
const multiply100 = (number?: number): number | undefined => {
  if (number == null || !isFinite(number)) return number;

  const str = number.toString();
  const currentDecimalPosition = str.indexOf('.');
  if (currentDecimalPosition < 0 || currentDecimalPosition === str.length - 1) {
    return parseInt(`${number}00`);
  }
  const allDigits = str.replace(/\./, '');
  if (currentDecimalPosition === str.length - 2) {
    return parseInt(`${allDigits}0`);
  }
  return parseFloat(allDigits.slice(0, currentDecimalPosition + 2) + '.' + allDigits.slice(currentDecimalPosition + 2));
};
/**
 * Divides by 100 with String operations (avoiding floating point complications)
 */
const divide100 = (number?: number): number | undefined => {
  if (number == null || !isFinite(number)) return number;

  const str = number.toString();
  const allDigits = str.replace(/\./, '');
  const currentDecimalPosition = str.indexOf('.');
  if (currentDecimalPosition < 0 || currentDecimalPosition === str.length - 1) {
    if (allDigits.length === 1) return parseFloat(`0.0${allDigits}`);
    if (allDigits.length === 2) return parseFloat(`0.${allDigits}`);
    return parseFloat(allDigits.slice(0, allDigits.length - 2) + '.' + allDigits.slice(allDigits.length - 2));
  }
  if (currentDecimalPosition === 0) {
    return parseFloat(`0.00${allDigits}`);
  }
  if (currentDecimalPosition === 1) {
    return parseFloat(`0.0${allDigits}`);
  }
  if (currentDecimalPosition === 2) {
    return parseFloat(`0.${allDigits}`);
  }
  return parseFloat(allDigits.slice(0, currentDecimalPosition - 2) + '.' + allDigits.slice(currentDecimalPosition - 2));
};

/**
 * Helper alias for Percentage fields (0 - 100)
 */
export const EditablePercentageField: React.FC<Omit<EditableNumberFieldProps, 'prefix' | 'suffix'>> = (props) => {
  return (
    <EditableNumberField
      {...props}
      suffix="%"
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={(v) => props.onChange?.(v)}
      numDigitsFixedDisplayWidth={3}
      numDigitsFixedInputWidth={3}
    />
  );
};

/**
 * Helper alias for Percentage fields for fractional values (0 to 1)
 */
export const EditableFractionPercentageField: React.FC<Omit<EditableNumberFieldProps, 'prefix' | 'suffix'>> = (props) => {
  return (
    <EditableNumberField
      {...props}
      suffix="%"
      defaultValue={multiply100(props.defaultValue)}
      value={multiply100(props.value)}
      onChange={(v) => props.onChange?.(divide100(v) as number)}
      numDigitsFixedDisplayWidth={3}
      numDigitsFixedInputWidth={3}
    />
  );
};

/**
 * Helper alias for 'No. of Days' fields
 */
export const EditableNumDaysField: React.FC<Omit<EditableNumberFieldProps, 'prefix' | 'suffix'>> = (props) => {
  return <EditableNumberField {...props} suffix=" Days" numDigitsFixedDisplayWidth={2} numDigitsFixedInputWidth={2} />;
};

/**
 * Helper alias for USD fields
 */
export const EditableUSDField: React.FC<Omit<EditableNumberFieldProps, 'prefix' | 'suffix' | 'locale'>> = (props) => {
  return <EditableNumberField {...props} prefix="$" numDigitsFixedInputWidth={8} />;
};
