import React, { useEffect, useState } from "react";
import Panel from "./Panel";
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import Title from "./Title";
import BulkUploadPopup from "./BulkUploadPopup";
import ManualUploadPopup from "./ManualUploadPopup";
import { motion } from "framer-motion";
import Dialog from "./Dialog";
import {
  Field,
  getLoanTapeFields,
  ThirdPartyLoanTape,
  UploadDetails,
} from "./ThirdPartyLoanTape";
import SmallValue from "./SmallValue";
import SmallLabel from "./SmallLabel";
import Loader from "./Loader";
import { toast } from "react-toastify";
import moment from "moment";

export const uploadTypes: { [key: string]: { key: string; label: string } } = {
  bulk: { key: "bulk", label: "Bulk" },
  manual: { key: "manual", label: "Manual" },
};

interface UploadsProps {
  steps: string[];
  className?: string;
  handleFinalStep?: () => void; // handler to do things after the upload is done, as we reach the 3rd step
  handleParsingForBulk?: (data: ThirdPartyLoanTape[]) => void; //handler to see the data changes happening while uploads
  step_3?: React.ReactNode; //step 3 ui different for different purposes
  uploadPopupPrimaryBtnTxt?: string;
  bulkUploadBtnTxt?: string;
  manualUploadBtnTxt?: string;
  onCancel?: () => void;
  buttonTextToCloseInLastStep?: string; //rhe submit for reprice button and close button
  onClickForCloseInLastStep?: () => void;
  handleBulkFileUpload?: (file: File, description?: string) => Promise<unknown>;
  handleManualFileUpload?: (
    data: ThirdPartyLoanTape[],
    name: string,
    description?: string
  ) => Promise<unknown>;
}

const Uploads: React.FC<UploadsProps> = ({
  steps,
  className,
  handleFinalStep,
  step_3,
  uploadPopupPrimaryBtnTxt,
  bulkUploadBtnTxt,
  manualUploadBtnTxt,
  onCancel,
  onClickForCloseInLastStep,
  buttonTextToCloseInLastStep,
  handleBulkFileUpload,
  handleManualFileUpload,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [uploadType, setUploadType] = useState<string | null>(null);
  const [file, setFile] = useState<File>();
  const [loanData, setLoanData] = useState<ThirdPartyLoanTape[]>([]);
  const [response, setResponse] = useState<UploadDetails | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [showFileDescriptionDialog, setShowFileDescriptionDialog] =
    useState(false);
  const [fileNameForManual, setFileNameForManual] = useState(`Manual_${moment().format("YYYYMMDDHHmm")}`);
  const [descriptionForManual, setDescriptionForManual] = useState("");
  const [descriptionForBulk, setDescriptionForBulk] = useState("");

  useEffect(() => {
    const fetch = async() => {
      const res = await getLoanTapeFields();
      localStorage.setItem("loanTapeFields", JSON.stringify(res))
    }
    fetch();
  },[])

  useEffect(() => {
    if (activeStep === 2 && handleFinalStep) {
      handleFinalStep();
    }
    if (activeStep === 1) {
      setUploadType("bulk");
    }
  }, [activeStep]);



  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % steps.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBulkUpload = async () => {
    // handleNext();
    if (file && handleBulkFileUpload) {
      setLoadingMessage("Uploading...");
      try {
        const response = (await handleBulkFileUpload(
          file,
          descriptionForBulk
        )) as UploadDetails;
        setResponse(response);
        setLoadingMessage("");
      } catch (error: any) {
        toast.error("Bulk file Upload Failed: ", error)
        console.error("Bulk file upload failed:", error);
        setLoadingMessage("");
      }
      setUploadType(null);
    }
  };

  const handleManualUpload = async () => {
    // handleNext();
    setLoadingMessage("Uploading...");
    if (loanData.length != 0 && handleManualFileUpload) {
      try {
        const response = (await handleManualFileUpload(
          loanData,
          fileNameForManual,
          descriptionForManual
        )) as UploadDetails;
        setResponse(response);
        setLoadingMessage("");
      } catch (error: any) {
        toast.error("Manual file Upload Failed: ", error)
        console.error("manual file upload failed: ", error);
        setLoadingMessage("");
      }
      setUploadType(null);
    }
  };

  return (
    <>
      <InstructionsDialog
        className={className}
        steps={steps}
        step_3={step_3}
        activeStep={activeStep}
        bulkUploadBtnTxt={bulkUploadBtnTxt}
        manualUploadBtnTxt={manualUploadBtnTxt}
        buttonTextToCloseInLastStep={buttonTextToCloseInLastStep}
        onClickForCloseInLastStep={
          onClickForCloseInLastStep ?? onCancel ?? (() => {})
        }
        handleUploadType={(uploadType: string) => setUploadType(uploadType)}
        handleNext={handleNext}
        handleBack={handleBack}
      />

      {/* Upload Dialogs */}
      {uploadType && (
        <UploadDialog
          uploadType={uploadType}
          file={file}
          loanData={loanData}
          uploadPopupPrimaryBtnTxt={uploadPopupPrimaryBtnTxt}
          handleUploadType={(uploadType: string | null) =>
            setUploadType(uploadType)
          }
          handleLoanDataChange={(data: ThirdPartyLoanTape[]) =>
            setLoanData(data)
          }
          handleFileChange={(file: File) => setFile(file)}
          handleDescriptionForBulk={(desc: string) =>
            setDescriptionForBulk(desc)
          }
          handleBulkUpload={handleBulkUpload}
          handleManualUpload={() => setShowFileDescriptionDialog(true)}
        />
      )}

      {loadingMessage.length > 0 && (
        <Loader message={loadingMessage} />
      )}

      {/* report dialog */}
      {response && (file || fileNameForManual) && (
        <Report
          response={response}
          file={file}
          fileNameForManual={fileNameForManual}
          handleCloseDialog={() => {
            if (onClickForCloseInLastStep) {
              onClickForCloseInLastStep();
            }
            handleNext();
            setLoadingMessage("");
            setResponse(null);
            setFile(undefined);
          }}
        />
      )}

      {showFileDescriptionDialog && (
        <Dialog
          open={true}
          title="Please Confirm"
          primaryButtonText="Upload"
          onPrimaryButtonClick={() => {
            handleManualUpload();
            setShowFileDescriptionDialog(false);
          }}
          primaryButtonDisabled={fileNameForManual.length === 0}
          onCancel={() => setShowFileDescriptionDialog(false)}
        >
          <div className="p-4">
            {/* Task Name Input */}
            <SmallLabel className="my-3">
              Name:<span className="text-red-600">*</span>
            </SmallLabel>
            <input
              type="text"
              value={fileNameForManual}
              onChange={(e) => setFileNameForManual(e.target.value)}
              className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 w-[30vw]"
              required
            />
            <SmallLabel className="my-3">Description</SmallLabel>
            <textarea
              placeholder="Optional description for future reference"
              value={descriptionForManual}
              onChange={(e) => setDescriptionForManual(e.target.value)}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Uploads;

interface CustomStepperProps {
  steps: string[];
  activeStep: number;
}

export const CustomStepper: React.FC<CustomStepperProps> = ({
  steps,
  activeStep,
}) => {
  return (
    <Stepper activeStep={activeStep} className="my-6 mb-16">
      {steps.map((label) => {
        return (
          <Step key={label}>
            <StepLabel
              sx={{
                "& .MuiStepLabel-label": { fontSize: "1.2rem" }, 
                "& .MuiSvgIcon-root": { fontSize: "1.7rem" },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

interface InstructionsDialogProps {
  className?: string;
  steps: string[]; //steps like ["Prepare loan tape", "upload loan tape", "submit for reprice"]
  step_3?: React.ReactNode; //step 3 custom UI
  activeStep: number;
  bulkUploadBtnTxt?: string; //upload button text (default is "Bulk Upload")
  manualUploadBtnTxt?: string; //manual button text (default is "Manual Upload")
  buttonTextToCloseInLastStep?: string; //Primary button name to close in the 3rd step (default is "Close")
  onClickForCloseInLastStep: () => void; //on Click for closing in 3rd step
  handleUploadType: (uploadType: string) => void; //handler to know which upload type is selected (bulk/manual)
  handleNext: () => void; //activeStep + 1 (if exceed the length of step reset to 0)
  handleBack: () => void; //activeStep - 1
}

export const InstructionsDialog: React.FC<InstructionsDialogProps> = ({
  className,
  steps,
  step_3,
  activeStep,
  bulkUploadBtnTxt,
  manualUploadBtnTxt,
  buttonTextToCloseInLastStep,
  onClickForCloseInLastStep,
  handleUploadType,
  handleNext,
  handleBack,
}) => {
  const handleDownload = () => {
    const fileUrl = "/Dummy_LoanTape.csv";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const loanTapeField: Field[] = JSON.parse(localStorage.getItem("loanTapeFields") ?? "");

  return (
    <Panel title="" className={`${className} w-full relative`}>
      <CustomStepper steps={steps} activeStep={activeStep} />

      {/* Instructions */}
      {activeStep === 0 && (
        <div className="px-4 m-2 text-base">
          <Title>{steps[0]}</Title>

          <div className="text-gray-600 mt-5 space-y-1">
            Please follow these guidelines to prepare your Third-Party Loan
            Tape:
          </div>
          <ul className="list-disc pl-4 text-gray-600 mt-2 space-y-1">
            <li>
              <span className="font-bold">
                {loanTapeField
                  .filter((field: Field) => field.mandatory === "Yes")
                  .map((field: Field) => field.key)
                  .join(", ")}{" "}
              </span>{" "}
              are mandatory fields.
            </li>
            <li className="font-medium">
              <span
                onClick={handleDownload}
                className="text-blue-600 hover:underline cursor-pointer"
              >
                {" "}
                Download this sample file
              </span>{" "}
              for reference or use it as a template to create CSV files. Please
              note that the first line must contain the mandatory header.
            </li>
          </ul>
          <div className="text-right mt-4">
            <Button color="secondary" onClick={handleNext}>Continue</Button>
          </div>
        </div>
      )}

      {/* Upload buttons */}
      {activeStep === 1 && (
        <div className="mx-4">
          <Title>{steps[1]}</Title>
          <div className="text-gray-600 mt-2 space-y-1">
            You can add more properties to this offer by uploading your
            Third-Party Loan Tapes.
          </div>
          <ul className="list-disc pl-4 text-gray-600 mt-2 space-y-1">
            <li>
              <span className="font-bold">Bulk Upload: </span> Use this option
              to directly upload a Third-Party Loan Tape CSV file from your
              machine.
            </li>
            <li>
              <span className="font-bold">Manual Upload: </span> Use this option
              to create a Third-Party Loan Tape using an on-screen editor, with
              abilities to enter properties manually and/or import and modify
              CSV files.
            </li>
          </ul>
          <div className="flex flex-row justify-between items-center my-4 w-full">
            <div>
              <Button color="secondary" onClick={handleBack} >Back</Button>
            </div>
            <div className="flex flex-row items-center justify-center gap-6">
              <Button
              color="primary"
                onClick={() => handleUploadType(uploadTypes.bulk.key)}
              >{bulkUploadBtnTxt || "Bulk Upload"}</Button>
              <Button
              color="primary"
                onClick={() => handleUploadType(uploadTypes.manual.key)}
              >{manualUploadBtnTxt || "Manual Entries"}</Button>
            </div>
          </div>
        </div>
      )}

      {/* last step */}
      {activeStep === 2 && (
        <div className="mx-4">
          <Title>{steps[2]}</Title>
          <div className="my-4">{step_3}</div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <Button color="secondary" onClick={handleBack} >Back</Button>
            </div>
            <div>
              <Button
              color="primary"
                onClick={onClickForCloseInLastStep}
              >{buttonTextToCloseInLastStep || "Close"}</Button>
            </div>
          </div>
        </div>
      )}
    </Panel>
  );
};

interface UploadDialogProps {
  uploadType: string; //upload type "bulk/manual"
  uploadPopupPrimaryBtnTxt?: string; //upload PopUp Primary Button Text like "Save/ Upload" (default is "Upload")
  file: File | undefined; //file if any is selected
  loanData: ThirdPartyLoanTape[]; //loan data if something is entered
  handleUploadType: (uploadType: string | null) => void; //handler for upload type change
  handleLoanDataChange: (loanData: ThirdPartyLoanTape[]) => void;
  handleFileChange: (file: File) => void;
  handleDescriptionForBulk?: (description: string) => void;
  handleBulkUpload: () => void; //final handler when upload/primary button is clicked in the dialog box when upload type is bulk
  handleManualUpload: () => void; //final handler when upload/primary button is clicked in the dialog box when upload type is manual
  handleParsingBulkData?: (loanData: ThirdPartyLoanTape[]) => void; //when you need the parsed data from the file upload
}

export const UploadDialog: React.FC<UploadDialogProps> = ({
  uploadType,
  uploadPopupPrimaryBtnTxt,
  file,
  loanData,
  handleUploadType,
  handleLoanDataChange,
  handleFileChange,
  handleDescriptionForBulk,
  handleBulkUpload,
  handleManualUpload,
  handleParsingBulkData,
}) => {
  return (
    <Dialog
      open={true}
      title="Add Properties"
      onCancel={() => handleUploadType(null)}
      primaryButtonText={uploadPopupPrimaryBtnTxt || "Upload"}
      onPrimaryButtonClick={() => {
        uploadType === "bulk" ? handleBulkUpload() : handleManualUpload();
      }}
      primaryButtonDisabled={
        uploadType === "bulk"
          ? !file
          : uploadType === "manual"
          ? loanData.length === 0
          : true
      }
      toolbar={
        <>
          <nav className="text-black flex mx-2">
            <span className="bg-white rounded-full flex flex-row border border-blue-700 cursor-pointer">
              <div
                key={uploadTypes.bulk.key}
                onClick={() => {
                  handleUploadType(uploadTypes.bulk.key);
                }}
                className={`px-4 py-3 text-sm rounded-full transition duration-300 ease-in-out ${
                  uploadType === "bulk"
                    ? "bg-blue-500 text-white shadow-custom-dark"
                    : "bg-transparent text-black"
                }`}
              >
                {uploadTypes.bulk.label}
              </div>
              <div
                key={uploadTypes.manual.key}
                onClick={() => {
                  handleUploadType(uploadTypes.manual.key);
                }}
                className={`px-4 py-3 text-sm rounded-full transition duration-300 ease-in-out ${
                  uploadType === "manual"
                    ? "bg-blue-500 text-white shadow-custom-dark" 
                    : "bg-transparent text-black"
                }`}
              >
                {uploadTypes.manual.label}
              </div>
            </span>
          </nav>
        </>
      }
    >
      <motion.div
        layout
        transition={{ duration: 0.1, ease: "easeInOut" }}
        className="max-w-[90vw] max-h-[70vh] min-w-[60vw]"
      >
        <ManualUploadPopup
          show={uploadType === uploadTypes.manual.key}
          handleDataChange={(data: ThirdPartyLoanTape[]) =>
            handleLoanDataChange(data)
          }
        />

        <BulkUploadPopup
          onClose={() => handleUploadType(null)}
          title={"Add Properties"}
          show={uploadType === uploadTypes.bulk.key}
          handleFileUpload={(file: File) => handleFileChange(file)}
          handleDescriptionChange={(description: string) =>
            handleDescriptionForBulk && handleDescriptionForBulk(description)
          }
          handleDataChange={handleParsingBulkData}
        />
      </motion.div>
    </Dialog>
  );
};

interface ReportProps {
  response: UploadDetails;
  file: File | undefined;
  fileNameForManual: string;
  handleCloseDialog: () => void;
}

export const Report: React.FC<ReportProps> = ({
  response,
  file,
  fileNameForManual,
  handleCloseDialog,
}) => {
  return (
    <Dialog
      open={true}
      title={`Add Properties: ${
        file ? file.name : fileNameForManual ? `${fileNameForManual}.csv` : ""
      }`}
      onCancel={() => {
        // handleNext();
        // setUploading(false);
        // setResponse(null);
        // setFile(undefined);
        handleCloseDialog();
      }}
      primaryButtonText={"OK"}
      onPrimaryButtonClick={() => {
        // handleNext();
        // setUploading(false);
        // setResponse(null);
        // setFile(undefined);
        handleCloseDialog();
      }}
      cancelButtonText={null}
    >
      <div className="flex-col gap-4 w-full p-4 max-w-[80vw]">
        <div className="flex flex-col">
          <div className="p-2 mb-6">
            {response.description ? (
              <SmallValue>
                <span className="text-black font-bold">Description: </span>
                {response.description}
              </SmallValue>
            ) : (
              <SmallValue className="text-gray-400">
                No Description Provided
              </SmallValue>
            )}
          </div>

          <div className="flex flex-row gap-4">
            {response.batchID?
              <>
            <table className="">
              <tbody>
                <tr className="">
                  <td className="px-2 py-1 w-full">
                    <SmallValue className="text-black font-bold">
                      Total Records in the CSV{" "}
                    </SmallValue>{" "}
                  </td>
                  <td className="px-2 py-1">
                    <SmallValue>{response?.numRecords}</SmallValue>
                  </td>
                </tr>
                <tr className="">
                  <td className="px-2 py-1 whitespace-nowrap">
                    <SmallValue className="text-black font-bold">
                      Time taken to process
                    </SmallValue>
                  </td>
                  <td className="px-2 py-1 w-full whitespace-nowrap">
                    <SmallValue className="block">
                      {response.durationMS.toFixed(2)} ms
                    </SmallValue>
                  </td>
                </tr>
                <tr className="">
                  <td className="px-2 py-1 whitespace-nowrap">
                    <SmallValue className="text-black font-bold">
                      Records successfully processed
                    </SmallValue>
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap">
                    <SmallValue>
                      {response.numRecords - response.numFailedRecords} (
                      {(
                        (100 *
                          (response.numRecords - response.numFailedRecords)) /
                        response.numRecords
                      ).toFixed(0)}
                      %)
                    </SmallValue>
                  </td>
                </tr>
                {response.numFailedRecords ? (
                  <tr className="">
                    <td className="px-2 py-1 whitespace-nowrap">
                      <SmallValue className="text-black font-bold">
                        Records failed
                      </SmallValue>
                    </td>
                    <td className="px-2 py-1 text-red-600 whitespace-nowrap">
                      <SmallValue>
                        {response.numFailedRecords} (
                        {(
                          (100 * response.numFailedRecords) /
                          response.numRecords
                        ).toFixed(0)}
                        %)
                      </SmallValue>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            <div className="flex flex-col justify-end">
              <a
                href={response.originalFileURL}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-sm font-medium  text-blue-500 hover:underline px-2 py-1"
              >
                Download Original CSV
              </a>
              {response.numFailedRecords !== undefined &&
                response.numFailedRecords > 0 && (
                  <a
                    href={response.failedFileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block text-sm font-medium text-blue-500 hover:underline px-2 py-1"
                  >
                    Download Failed Records CSV
                  </a>
                )}
              <a
                href={response.reportFileURL}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-sm font-medium text-blue-500 hover:underline px-2 py-1"
              >
                Download Report
              </a>
            </div>
            </> : 
            <SmallValue className="text-red-400">
            Some Error occurred, Please check the file which you uploaded.
          </SmallValue>}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const OfferPropertiesUpload: React.FC<
  Omit<
    UploadsProps,
    "bulkUploadBtnTxt" | "manualUploadBtnTxt" | "uploadPopupPrimaryBtnTxt"
  >
> = (props) => (
  <Uploads
    {...props}
    bulkUploadBtnTxt="Bulk Upload"
    manualUploadBtnTxt="Manual Entries"
    uploadPopupPrimaryBtnTxt="Upload"
  />
);
