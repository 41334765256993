// config.ts
export const AWS_CONFIG = {
  accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
  secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  region: import.meta.env.VITE_AWS_REGION,
  rootBucketName: import.meta.env.VITE_S3_ROOT_BUCKET_NAME,
};

export const CHECK_USER_PERMISSION_API_URL = import.meta.env.VITE_AUTH_PERMISSION_API_URL;

export const LENDER_PROFILE_QUERY_API_URL = import.meta.env.VITE_LENDER_PROFILE_QUERY_API_URL;

export const PROPERTIES_QUERY_API_URL = import.meta.env.VITE_PROPERTIES_QUERY_API_URL;

export const PROPERTY_DETAILS_API_URL = import.meta.env.VITE_PROPERTY_DETAILS_API_URL;

export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export const DEALS_API_URL = import.meta.env.VITE_DEALS_API_URL;

export const DEALS_API_URL_NEW = import.meta.env.VITE_DEALS_API_URL_NEW;

export const PROPERTY_DETAILS_API_URL_NEW = import.meta.env.VITE_PROPERTY_DETAILS_API_URL_NEW;

export const DEALS_BANNER_API_URL = import.meta.env.VITE_DEALS_BANNER_API_URL

export const GET_OPPORTUNITIES_API_URL = import.meta.env.VITE_GET_OPPORTUNITIES_API_URL

export const GET_ERROR_CODES_API_URL = import.meta.env.VITE_GET_PROPERTY_ERROR_CODES_URL

export const UPDATE_DEAL_STATUS_API_URL = import.meta.env.VITE_UPDATE_DEAL_STATUS_API_URL;

export const DEALS_FILTER_API_URL = import.meta.env.VITE_DEALS_FILTER_API_URL;

export const SAVE_DEALS_FILTER_API_URL = import.meta.env.VITE_SAVE_DEALS_FILTER_API_URL;

export const FILTER_PREVIEW_API_URL = import.meta.env.VITE_FILTER_PREVIEW_API_URL;

export const FETCH_SAVED_QUERIES_API_URL = import.meta.env.VITE_FETCH_SAVED_QUERIES_API_URL;

export const UPDATE_OPPORTUNITY_PRICING_API_URL = import.meta.env.VITE_UPDATE_OPPORTUNITY_PRICING_API_URL;

export const DEALS_BLUE_BUTTOM_OFFER_MADE_API_URL = import.meta.env.VITE_DEALS_BLUE_BUTTOM_OFFER_MADE_API_URL;

export const CREATE_ORGANIZATIONS_API_URL = import.meta.env.VITE_CREATE_ORGANIZATIONS_API_URL;

export const GET_AIV_VALUES_API_URL = import.meta.env.VITE_GET_AIV_VALUES_API_URL;
export const SAVE_AIV_VALUES_API_URL = import.meta.env.VITE_SAVE_AIV_VALUES_API_URL;
export const DELETE_AIV_VALUES_API_URL = import.meta.env.VITE_SAVE_AIV_VALUES_API_URL;
export const GET_PROP_DOC_API_URL = import.meta.env.VITE_GET_PROP_DOC_API_URL;
export const GET_ACTIVITY_LOGS = import.meta.env.VITE_GET_ACTIVITY_LOGS;


export const MODIFY_ORGANIZATIONS_API_URL = import.meta.env.VITE_MODIFY_ORGANIZATIONS_API_URL;
export const CREATE_MODIFY_THIRD_PARTY_API_URL = import.meta.env.VITE_CREATE_MODIFY_THIRD_PARTY_API_URL;
export const GET_THIRD_PARTY_API_URL = import.meta.env.VITE_GET_THIRD_PARTY_API_URL;
export const PRICING_RULE = import.meta.env.VITE_PRICING_RULE;
export const GET_ACTIVE_VERSION = import.meta.env.VITE_GET_ACTIVE_VERSION;
export const ADD_BORROWER_DETAILS_API_URL = import.meta.env.VITE_ADD_BORROWER_DETAILS_API_URL;

export const SEND_EMAIL = import.meta.env.VITE_SEND_EMAIL
export const GET_LOAN_TAPE_FIELDS_URL = import.meta.env.VITE_GET_LOAN_TAPE_FIELDS_URL
export const LOAN_TAPE_URL = import.meta.env.VITE_LOAN_TAPE_URL;

export const PROPERTY_DOCUMENTS_API_URL =  import.meta.env.VITE_PROPERTY_DOCUMENTS_API_URL
export const OPPORTUNITY_DOCUMENTS_API_URL =  import.meta.env.VITE_OPPORTUNITY_DOCUMENTS_API_URL
export const GET_LIST_OPPORTUNITY_DOCUMENTS_API_URL =  import.meta.env.VITE_LIST_OPPORTUNITY_DOCUMENTS_API_URL
export const GET_LIST_PROPERTY_DOCUMENTS_API_URL =  import.meta.env.VITE_LIST_PROPERTY_DOCUMENTS_API_URL
