import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface InformationModalProps {
    open: boolean;
    header: string;
    message: string;
    toggleModal: () => void;
}

const InformationModal: React.FC<InformationModalProps> = ({ toggleModal, header, message, open }) => {

    return (
        <Dialog
            open={open}
            onClose={toggleModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="text-sm font-bold text-center">
                {header}
            </DialogTitle>
            <DialogContent className="flex justify-center">
                <DialogContentText id="alert-dialog-description" className="text-sm font-normal text-center">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="flex !justify-center">
                <Button onClick={toggleModal} className="text-center">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default InformationModal;