import React, { useState } from "react";
import { ActivityEvent } from "./models/Timeline";

interface TabViewProps {
    onActivityChanged(activity:ActivityEvent):void;
}
const TabView: React.FC<TabViewProps> = ({}) => {
    const [activeTab, setActiveTab] = useState("comments");
    const [commentText, setCommentText] = useState("");
    const [files, setFiles] = useState<File[]>([]);

    const handleAction = () => {
        if (activeTab === "comments") {
        //     onActivityChanged({
        //         user: displayName,
        //         actionText: "added comment",
        //         actionDetails: [commentText],
        //         type: "Comment",
        //         time: new Date().toISOString(),
        //     } as OpportunitiesActivityItem);
        //     setCommentText(""); // Clear the textarea after sharing
        // } else if (activeTab === "share") {
        //     onActivityChanged({
        //         user: "Zohaib",
        //         actionText: "shared files",
        //         actionDetails: files.map((file) => file.name), // List file names
        //         type: "Uploaded Document",
        //         time: new Date().toISOString(),
        //     } as OpportunitiesActivityItem);
            setFiles([]); // Clear the file input after sharing
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFiles(Array.from(event.target.files));
        }
    };
    return (
        <div className="w-full">
            <div className="flex items-center justify-center">
                <button
                    className={`py-2 px-4 ${activeTab === "comments" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-600"
                        }`}
                    onClick={() => setActiveTab("comments")}
                >
                    Comments
                </button>
                <button
                    className={`py-2 px-4 ${activeTab === "share" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-600"
                        }`}
                    onClick={() => setActiveTab("share")}
                >
                    File
                </button>
            </div>
            <div className="flex w-full justify-center">
                <div className="p-4 pl-0 w-1/2">
                    {activeTab === "comments" ? <textarea
                        className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        rows={1}
                        placeholder="Write your comment here..."
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                    ></textarea> : <div className="flex p-2.5 flex-col space-y-2">
                        <input
                                type="file"
                                multiple
                                className="file-input w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                onChange={handleFileChange}
                            />
                            {files.length > 0 && (
                                <ul className="list-disc list-inside text-gray-600">
                                    {files.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                    ))}
                                </ul>
                            )}
                    </div>}
                </div>
                <div className="flex items-start justify-start h-full m-6 w-1/3">
                    <button className="apply-new-query-button" onClick={handleAction}  disabled={files.length != 0 || commentText != "" ? false : true} >Share</button>
                </div>

            </div>
        </div>
    );
};
export default TabView;