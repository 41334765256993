import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Title from "./Title";
import { Button } from "@mui/material";

interface DialogProps {
  open: boolean;
  title?: string;
  children: React.ReactNode;
  toolbar?: React.ReactNode;
  /**
   * Default: "OK". Set to `null` to remove button.
   */
  primaryButtonText?: string | null;
  /**
   * Default: "Cancel". Set to `null` to remove button.
   */
  cancelButtonText?: string | null;
  specialButtonText ?: string | null;
  primaryButtonDisabled?: boolean;
  onCancel?: (fromX: boolean) => void;
  onPrimaryButtonClick?: () => void;
  onSpecialButtonClick?: () => void;
  buttonVisible?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
  open,
  title,
  children,
  toolbar,
  primaryButtonText = "OK",
  cancelButtonText = "Cancel",
  specialButtonText,
  primaryButtonDisabled: primaryButtonEnabled = false,
  onSpecialButtonClick,
  onCancel,
  onPrimaryButtonClick,
  buttonVisible = true,
}) => {
  // Local state to control whether the dialog remains rendered.
  const [shouldRender, setShouldRender] = useState(open);
  // Keeps track of whether the dialog was closed via the "X" (true) or the Cancel button (false)
  const [closeSource, setCloseSource] = useState<boolean | null>(null);

  useEffect(() => {
    if (open) {
      setShouldRender(true);
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
      setShouldRender(false);
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [open]);

  const handleClose = (fromX: boolean) => {
    setCloseSource(fromX);
    // Immediately trigger the exit animation by removing the dialog from rendering.
    setShouldRender(false);
  };

  return (
    <AnimatePresence
      onExitComplete={() => {
        if (closeSource !== null) {
          onCancel?.(closeSource);
          setCloseSource(null);
        }
      }}
    >
      {shouldRender && (
        <motion.div
          layout
          key="dialog-overlay"
          className="fixed inset-0 z-[999999] flex items-center justify-center bg-black bg-opacity-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
          <motion.div
            key="dialog"
            className="bg-white rounded-xl shadow-lg p-6 w-auto max-w-full h-auto max-h-full flex flex-col overflow-hidden"
            initial={{ opacity: 0, scale: 0.8, y: -20 }}
            animate={{
              opacity: 1,
              scale: 1,
              y: 0,
              transition: { duration: 0.3 },
            }}
            exit={{
              opacity: 0,
              scale: 0.8,
              y: 20,
              transition: { duration: 0.3 },
            }}
          >
            {/* Header */}
            <div className="flex justify-between items-center pb-2 border-b-2">
              {title && (
                <Title className="text-xl font-semibold">{title}</Title>
              )}
              <div className="flex flex-row justify-center items-center gap-2">
                <div>{toolbar ?? null}</div>
                <div
                  className="cursor-pointer"
                  onClick={() => handleClose(true)}
                >
                  <IoClose className="w-6 h-6" />
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="flex-1 overflow-auto">{children}</div>

            {/* Footer */}
            <div
              className={`flex justify-center my-3 gap-6 ${
                buttonVisible ? "" : "hidden !my-0"
              }`}
            >
              {specialButtonText && (
                <Button
                color="secondary"
                  onClick={onSpecialButtonClick}
                  disabled={primaryButtonEnabled}
                >{specialButtonText}</Button>
              )}
              {primaryButtonText && (
                <Button
                color="primary"
                  onClick={onPrimaryButtonClick}
                  disabled={primaryButtonEnabled}
                >{primaryButtonText}</Button>
              )}
              {cancelButtonText && (
                <Button
                color="secondary"
                  onClick={() => handleClose(false)}
                >{cancelButtonText}</Button>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Dialog;
