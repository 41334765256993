import TabView from "./TabView";
import ActivityLog from "./ActivityLog";
import { ActivityEvent } from"./models/Timeline";
interface TimelineProps{
    activities: ActivityEvent[];
    onRefresh:()=>void;
}
const TimelineCard: React.FC<TimelineProps> = ({activities, onRefresh}) => {

    const onAddActivity = (activity: ActivityEvent) => {
        console.log(activity);
        // Create a new array with the new activity at the beginning
        //setActivities((prevActivities) => [activity, ...prevActivities]);
    };

    return (
        <div className="flex justify-center h-full w-full flex-col bg-slate-10 gap-custom-4">
            <div className="bg-white px-4 md:px-16 rounded-xl w-full hidden">
                <TabView onActivityChanged={onAddActivity} />
            </div>
            <div className="flex-1 h-auto overflow-auto my-4 bg-white px-4 p-4 md:px-16 rounded-xl w-full flex flex-col">
                <ActivityLog onRefresh={onRefresh} activities={activities} isLoading={false} />
            </div>
        </div>
    );
};

export default TimelineCard;