import React from "react";
import "./ComponentsGrid.css";

/**
 * Represents the data for a single FlowGridNode within a cell.
 * It can optionally include a custom renderer function
 * that overrides the default rendering.
 */
export interface FlowGridNode {
  data: any;
  /**
   * Returns a JSX for this node and overrides the default rendering defined by `FlowGridProps.renderer`.
   * 
   * @param rowIndex 
   * @param colIndex 
   * @param cellSplitIndex 
   * @param data 
   * @param rowData 
   * @returns 
   */
  renderer?: (
    data: any, // FlowGridNode data
    rowIndex: number,
    colIndex: number,
    cellXY: [x: number, y: number], // FlowGridNode position in the cell as a pair of zero based indices (x, y). e.g.: [0, 0] is top-left, [1, 0] is top-right, [0, 1] is bottom-left, [1, 1] is bottom-right.
    rowData: RowData
  ) => JSX.Element;
}

/**
 * A row is an array of CellData.
 */
export type RowData = CellData[];

/**
 * A CellData can be a single FlowGridNode or an array of Nodes.
 * An array can itself contain Nodes or arrays of Nodes
 * (nested), representing a “split” that can alternate
 * between vertical and horizontal.
 */
export type CellData = FlowGridNode | CellData[];

/**
 * Props for the FlowGrid.
 */
interface FlowGridProps {
  /**
   * The column names of the grid.
   * Ideally, `columns.length === data[i].length`
   */
  columns?: string[];
  /**
   * The data of the grid, where `data.length` is the number of rows,
   * and each `data[i]` is an array representing the columns in that row.
   * 
   * Note: `max(data[i].length)` gives the number of columns.
   * 
   * Each element in a row can be:
   * - a `FlowGridNode` (simple)
   * - a nested array of `FlowGridNode`s or arrays (complex, enabling alternating vertical and horizontal splits).
   * 
   * Example:
   * 
   * [ a:FlowGridNode, [bTop:FlowGridNode, [bBottomLeft:FlowGridNode, bBottomRight:FlowGridNode]], c:FlowGridNode, [dTop:FlowGridNode, dMiddle:FlowGridNode, d:Bottom:FlowGridNode], e:FlowGridNode ]
   * The above array represents a row with 5 cells, split as follows:
   * |---|--------------------------|---|---------|---|
   * | 1 |            2             | 3 |    4    | 5 |
   * |---|--------------------------|---|---------|---|
   * |   |           bTop           |   |  dTop   |   |
   * | a |                          | c | dMiddle | e |
   * |   | bBottomLeft bBottomRight |   | dBottom |   |
   * |---|--------------------------|---|---------|---|
   */
  data?: RowData[];
  /**
   * The horizontal gap between columns (in px).
   */
  columnGap?: number;

  /**
   * The vertical gap between rows (in px).
   */
  rowGap?: number;

  /**
   * The gap between nested splits in a single cell (in px).
   */
  cellSplitGap?: number;

  /**
   * Global fallback renderer for a FlowGridNode, if the FlowGridNode itself doesn’t
   * provide a custom renderer.
   */
  renderer?: (
    data: any, // FlowGridNode data
    rowIndex: number,
    colIndex: number,
    cellXY: [x: number, y: number], // FlowGridNode position in the cell as a pair of zero based indices (x, y). e.g.: [0, 0] is top-left, [1, 0] is top-right, [0, 1] is bottom-left, [1, 1] is bottom-right.
    rowData: RowData
  ) => JSX.Element;

  noDataRenderer?: () => JSX.Element;
}

/**
 * FlowGrid is a generic “grid” component that:
 * - Renders a header row (if columns are provided).
 * - Renders each row in `data` as a flow of nodes.
 * - Each cell might contain a single FlowGridNode or a nested array of Nodes
 *   to be displayed with alternating vertical/horizontal splits.
 */
const FlowGrid: React.FC<FlowGridProps> = ({
  columns,
  data,
  // columnGap = 2,
  // rowGap = 2,
  // cellSplitGap = 2,
  renderer,
  noDataRenderer = () => <div className="p-4">No data.</div>
}) => {

  // if (!data || !data.length) {
  //   return noDataRenderer();
  // }

  const numColumns = Math.max(
    columns?.length || 0,
    data?.reduce((max, row) => Math.max(max, row.length), 0) || 0
  );

  // Fill column names if not available
  columns = columns || [];
  for (let i = 0; i < numColumns; ++i) {
    columns[i] = columns[i] != null ? columns[i] : "";
  }

  // Recursively render a Cell (FlowGridNode or array)
  // We'll track "level" to decide row-vs-column orientation in nested tables
  const renderCell = (
    cell: CellData,
    rowIndex: number,
    colIndex: number,
    rowData: RowData,
    depth: number // for tracking vertical/horizontal orientation
  ): JSX.Element => {

    // Nested arrays represent a split, alternating between vertical and horizontal orientation.
    // e.g. [dTop:FlowGridNode, dMiddle:FlowGridNode, d:Bottom:FlowGridNode] or [bTop:FlowGridNode, [bBottomLeft:FlowGridNode, bBottomRight:FlowGridNode]]
    if (Array.isArray(cell)) {

      // Array depth: even => vertical, odd => horizontal
      if (depth % 2 === 0) { // Vertical: single column (<td>), multiple rows (<tr>)
        return (
          <table className="w-full border-collapse border-0 table-auto w-auto">
            <tbody>
              {cell.map((subCell, i) => (
                <tr key={i}>
                  <td className="border align-middle border-0">
                    {renderCell(subCell, rowIndex, colIndex, rowData, depth + 1)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }

      else { // Horizontal: single row (<tr>), multiple columns (<td>)
        return (
          <table className="w-full border-collapse border-0 table-auto w-auto">
            <tbody>
              <tr>
                {cell.map((subCell, i) => (
                  <td key={i} className="border align-middle border-0">
                    {renderCell(subCell, rowIndex, colIndex, rowData, depth + 1)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        );
      }
    }

    // If it's a single FlowGridNode
    else {
      const node = cell as FlowGridNode;
      return node.renderer ? node.renderer(node.data, rowIndex, colIndex, [0, 0], rowData)
        : renderer ? renderer(node.data, rowIndex, colIndex, [0, 0], rowData)
          : <>{String(node.data)}</>; // default
    }
  };

  return (
    <table className="comp-grid border-collapse border-gray-200 table-auto w-full">
      {/* Thead */}
      <thead className="sticky top-0 opacity-75 z-50">
        <tr className="text-sm">
          {columns.map((colName, colIndex) => (
            <th key={`header-${colIndex}`} className={`comp-header-col-${colIndex % 2 ? "even" : "odd"} comp-header-col-${colIndex} p-0.5 align-middle`}>
              {colName && colName.length && <div className="shadow-xl p-1 border border-gray-400 bg-gray-100 text-center whitespace-nowrap">{colName}</div>}
            </th>
          ))}
        </tr>
      </thead>

      {/* Tbody */}
      <tbody>
        {!data || !data.length ? <tr><td colSpan={numColumns}>{noDataRenderer()}</td></tr>
          : data.map((row, rowIndex) => (
            <tr className={`comp-body-row-${rowIndex % 2 ? "odd" : "even"} comp-body-row-${rowIndex} border-b last:border-0`} key={rowIndex}>
              {row.map((cell, colIndex) => (
                <td key={colIndex} className={`comp-body-col-${colIndex % 2 ? "even" : "odd"} comp-body-col-${colIndex} px-2 py-1 align-middle`}>
                  {renderCell(cell, rowIndex, colIndex, row, 0)}
                </td>
              ))}
            </tr>
          ))}
      </tbody>

      {/* Tfoot */}
      {/* <tfoot className="sticky bottom-0 opacity-75">
        <tr className="text-sm">
          {columns.map((colName, colIndex) => (
            <th key={`footer-${colIndex}`} className={`comp-footer-col-${colIndex % 2 ? "even" : "odd"} comp-footer-col-${colIndex} p-0.5 align-middle`}>
              {colName && colName.length && <div className="shadow-xl p-1 border border-gray-400 bg-gray-100 text-center whitespace-nowrap">{colName}</div>}
            </th>
          ))}
        </tr>
      </tfoot> */}

    </table>
  );
};

export default FlowGrid;
