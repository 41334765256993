import { useEffect, useState } from "react";
import TimelineCard from "./TimelineCard"
import { ActivityEvent } from "./models/Timeline";
import { fetchActivities } from "./Activities.Service";
import DialogLoading from "@/components/ui/dialog-loading";
import { toast } from "react-toastify";
import { useAuth } from "@/components/AuthProvider";
const TimelineScreen: React.FC<{ opportunityId: string }> = ({ opportunityId }) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [activities, setActivities] = useState<ActivityEvent[]>([]);
  const auth = useAuth();
  
  useEffect(() => {
    loadActivities();
  }, []);

  const loadActivities = ()=>{
    setLoadingState(true);
    fetchActivities(opportunityId, auth!.sub).then((activities) => {
      setActivities(activities);
    }).catch((e)=>{
      toast.error(e.toString());
    }).finally(()=>{
      setLoadingState(false);
    });
  }

  return (
    <div className='flex flex-col gap-custom-4 items-center h-[86.5vh] w-full'>
      {/* <div className="absolute top-7 text-xl font-semibold">{lenderName}</div> */}
      <DialogLoading loadingMessage="Loading Activities ...." isLoading={loadingState}></DialogLoading>
      {!loadingState && (<TimelineCard activities={activities} onRefresh={loadActivities}></TimelineCard>)}
    </div>
  );
};

export default TimelineScreen;