import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { useEffect, useState } from 'react';
import './Properties.css';
import { fetchPropertiesTableData } from './Properties.service';
// import { Loader } from "lucide-react";
import PaginationComponent from '@/components/pagination-component/PaginationComponent';
import { Button } from '@/components/ui/button';
import useDebounce from '@/hooks/useDebounce';
import { formatCurrency } from '@/lib/utils';
import { ChevronRight, SlidersHorizontal } from 'lucide-react';
import { useQueryClient } from 'react-query';
import FilterButtonSort from '../../common/FilterButtonSort';
import FilterProfiles from '../../common/FilterProfiles';
import Searchbar from '../../common/Searchbar';
import { PropertyDealDetailsQueryKeys, useDealDetails } from '../deals/hooks';
import PropertyDetailsModal from '../deals/PropertyDetailsModal';
import LoadingModal from '../../common/LoadingModal';
// import { GetFilterPreviewResponse } from '../deals/types';
// import { format } from 'date-fns/format';

interface PropertiesProps {
  // tableData: GetFilterPreviewResponse[];
  tableData?: any[];
  length?: number;
  // map: (item: any) => any;
  // find: (predicate: (item: any) => boolean) => any;
  showProgressBar?: () => void;
}

const Properties: React.FC<PropertiesProps> = () => {
  const LIMIT: number = 5;
  const queryClient = useQueryClient();
  // const [property, setProperty] = useState<ApiBodyResponse>();
  // const [properties, setProperties] = useState<ApiBodyResponse[]>();
  const [propertiesSearch, setPropertiesSearch] = useState<string>('');
  const [tableData, setTableData] = useState<any>();
  const initialIsOpenDetailsModal = false;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<any>(0);

  // const [loading, setLoading] = useState(true);
  //   const totalPages = properties?.length || 0;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(initialIsOpenDetailsModal);
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
  //const [startProgressBar, stopProgressBar] = useProgressBar();
  const [loadingMessage, setLoadingMessage] = useState<string|null>(null);

  const debouncedSearchTerm = useDebounce(propertiesSearch, 300); // 300 ms debounce delay
  const { data: propertyDetails } = useDealDetails(selectedPropertyId);
  const [filters, setFilters] = useState("")
  // Preview or Save Query

  const fetchData = async () => {
    try {
      //startProgressBar();
      setLoadingMessage("Fetching properties....");
      const data = await fetchPropertiesTableData(propertiesSearch, page-1, LIMIT, filters); // Update page and limit values as needed
      if (!data?.tableData) {
        // setLoading(false);
        // alert('Failed to fetch properties');
        return;
      }
      // const newProperties = properties ? [...properties, data?.tableData] : data?.tableData;
      const newProperties = data?.tableData;
      const tempData = JSON.parse(JSON.stringify(newProperties));

      const mergedData = tempData.map((item: any) => ({
        _index: item._index,
        _id: item._id,
        _score: item._score,
        ...item._source,
      }));
      setTotalPages(data.totalRecord ? Math.round(Number(data.totalRecord)/LIMIT)+1 : 0);
      setTableData(mergedData);
      // setProperty(data);
      // setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      // setLoading(false);
    } finally {
      setLoadingMessage(null);

      //stopProgressBar();
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm || filters) {
      fetchData();
    }
  }, [debouncedSearchTerm, page, filters]);

  const FilterButton = () => {
    return (
      <Button className="rounded bg-card" onClick={() => setIsFilterOpen(!isFilterOpen)}>
        <SlidersHorizontal />
      </Button>
    );
  };

  const renderTableCell = (prefix: string, value: string) => (
    <TableCell className="whitespace-nowrap text-base">
      <span className="text-customGray929DAD">{prefix}</span>
      <span className="text-black dark:text-white">{value}</span>
    </TableCell>
  );

  const onDetailsClick = (propertyId: string) => {
    setSelectedPropertyId(propertyId);

    const cachedPropertyDetails = queryClient.getQueryData([PropertyDealDetailsQueryKeys, propertyId]);
    if (cachedPropertyDetails === undefined) {
      setLoadingMessage("Fetching properties....");
      //startProgressBar();
    } else {
      setIsOpenDetailsModal((prevState) => !prevState);
    }
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <Searchbar setPropertiesSearch={setPropertiesSearch} backgroundColor="bg-card" buttonOne={<FilterButtonSort />} buttonTwo={<FilterButton />} />
      <FilterProfiles setFilters={setFilters} isOpen={isFilterOpen} onClose={() => setIsFilterOpen(false)} side="right" />
      <div className="card-container mt-3 w-full pb-5 pl-5 pt-5">
        <h3 className="mb-5 text-xl font-semibold">Properties</h3>
        {/* {renderProgressBarUI()} */}
        {/* <PaginationComponent totalPages={totalPages} setPage={setPage} page={page} isDisabledNext={(property?.rows.length || 0) <= 0} /> */}
        {/* <p className="">{totalPages}</p> */}
        {totalPages > 1 && <PaginationComponent totalPages={totalPages} setPage={setPage} page={page} isDisabledNext={page == totalPages} numVisibleLinks={10} />}

        <div className="flex flex-col">
          <div className="overflow-x-auto ">
            <div className="inline-block min-w-full py-4 pr-5">
              <div className="overflow-hidden">
                <Table>
                  <TableBody className="[&_tr:nth-child(even)]:bg-stone-100">
                    <TableRow className="border-t-[1px]">
                      <TableCell className="hidden"></TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Zip</TableCell>
                      <TableCell>APN</TableCell>
                      <TableCell>Assett Class</TableCell>
                      <TableCell>Current UPB</TableCell>
                      <TableCell>Current AIV</TableCell>
                      <TableCell>AAO</TableCell>
                      <TableCell>Tax Assessed Value</TableCell>
                      <TableCell>Loan Type</TableCell>
                      <TableCell>Mortgage Term</TableCell>
                      <TableCell>Lender</TableCell>
                      <TableCell>Borrower</TableCell>
                      <TableCell>Corporate Owned</TableCell>
                      <TableCell>Preforeclosure</TableCell>
                      <TableCell>Property Use</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>Open Mortgage</TableCell>
                      <TableCell>Tax Lien</TableCell>
                      <TableCell>Property ID</TableCell>
                      <TableCell></TableCell>
                    </TableRow>

                    {!!tableData?.length &&
                      tableData.map((property: any, index: number) => {
                        return (
                          <TableRow key={index} className="border-t-[1px]">
                            <TableCell className="hidden">{index + 1}</TableCell>
                            {renderTableCell('', property.address)}
                            {renderTableCell('', property.zip5)}
                            {renderTableCell('', String(property.apn))}
                            {renderTableCell('', property.assetClassGroup)}
                            {renderTableCell('', formatCurrency(Number(property.estimated_mortgage_balance)))}
                            {renderTableCell('', formatCurrency(Number(property.estimated_value)))}
                            {renderTableCell('', formatCurrency(Number(property.firstLienPostionMortgageOrginationAmount)))}
                            {renderTableCell('', formatCurrency(Number(property.assessedValue)))}
                            {renderTableCell('', property.firstLienPostionMortgageLoanType || '--')}
                            {renderTableCell('', property.firstLienPositionMortgageLoanTerm !== null ? String(property.firstLienPositionMortgageLoanTerm) || '' : '--')}
                            {renderTableCell('', property.lenderName || '--')}
                            {renderTableCell('', property.borrowerCalc)}
                            {renderTableCell('', property.is_corporate_owned === true ? 'YES' : 'NO')}
                            {renderTableCell('', property.is_preforeclosure === true ? 'YES' : 'NO')}
                            {renderTableCell('', property.property_use)}
                            {renderTableCell('', property.state)}
                            {renderTableCell('', String(property.has_mortgage) === 'true' ? 'YES' : 'NO')}
                            {renderTableCell('', String(property.is_tax_delinquent === true ? 'YES' : 'NO'))}
                            {renderTableCell('', property.id)}
                            <TableCell onClick={() => onDetailsClick(property.id)}>
                              <button className="text-black dark:text-white">
                                <ChevronRight />
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal open={(loadingMessage != null )} message={loadingMessage || ""} />

      {!!tableData?.length && (
        <PropertyDetailsModal
          isOpen={isOpenDetailsModal}
          toggleModal={() => setIsOpenDetailsModal(!isOpenDetailsModal)}
          property={tableData.find((p: any) => p.id === selectedPropertyId)}
          details={propertyDetails}
        />
      )}
    </div>
  );
};

export default Properties;
