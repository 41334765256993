import React from 'react';

interface SmallLabelProps {
  children: React.ReactNode;
  className?: string;
}

const SmallLabel: React.FC<SmallLabelProps> = ({ children, className }) => {
  return (
    <p className={`text-sm ${className || ''}`}>
        {children}
    </p>
  );
};

export default SmallLabel;