import { useEffect, useState } from "react";
import { getThirdPartyProfile, ThirdPartyProfile } from "./services/dealService";

const BorrowerName = ({ borrowerThirdPartyId, isUpdated } : {borrowerThirdPartyId : string, isUpdated:boolean}) => {
    const [borrower, setBorrower] = useState<ThirdPartyProfile | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      let isMounted = true; 
  
      const fetchBorrowerDetail = async () => {
        setIsLoading(true); 
        try {
          const borrowerData = await getThirdPartyProfile(borrowerThirdPartyId);
          if (isMounted) setBorrower(borrowerData);
        } catch (error) {
          console.error("Error fetching borrower:", error);
        } finally {
          if (isMounted) setIsLoading(false);
        }
      };
  
      fetchBorrowerDetail();
  
      return () => {
        isMounted = false; 
      };
    }, [borrowerThirdPartyId, isUpdated]);
  
    return (
      <span className="text-black dark:text-white">
        {isLoading ? "• • •" : borrower?.party_name || "N/A"}
      </span>
    );
  };
  
  export default BorrowerName;