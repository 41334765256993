import { getAuthToken, getCognitoID } from "./authService";
import packageJson from '../../package.json';

type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

interface RestAPIParams {
    headers?: { [key: string]: string };
    query?: { [key: string]: unknown }; // For appending query parameters to the URL
    raw?: unknown; // For raw body content
    json?: { [key: string]: unknown }; // For JSON body content
    form?: { [key: string]: unknown }; // For form-data
}

export class HTTPError extends Error {
    public status: number;

    constructor(status: number, message: string) {
        super(message);
        this.name = "HTTPError";
        this.status = status;
    }

    toString(): string {
        return `${this.name} (${this.status}): ${this.message}`;
    }
}

export const onHTTPError = (status: number, message?: string | { message: string }): HTTPError => {
    try {
        // If message is { message: string }, use message.message
        const json = typeof message === 'string' ? JSON.parse(message) : message?.message;
        if (json.message) message = json.message;
    } catch {
        // Ignore. We'll use the original message
    }
    message = typeof message === 'string' ? message.trim() : JSON.stringify(message);

    // if (status === 401) {
    //     useStore.getState().setSessionError('Your session has expired. Please sign in again to continue.');
    // }
    // else if (status === 403) {
    //     useStore.getState().setSessionError('You do not have the rights to view this application. Please contact the administrator or sign in with a different account.');
    // }
    return new HTTPError(status, message);
}

const getUserIP = async () => {
    try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error("Error fetching IP:", error);
        return "Unknown IP";
    }
}

const getErrorMessageFromJSONReponse = (errResponseJSON: { error?: string; errorMessage?: string; message?: string; }, defaultMessage?: string): string =>
    errResponseJSON.error || errResponseJSON.errorMessage || errResponseJSON.message || defaultMessage || JSON.stringify(errResponseJSON);

export const restAPIForJSON = async <T>(
    method: HttpMethod,
    url: string,
    params?: RestAPIParams,
    authRequired: boolean = true
): Promise<T> => {
    const authToken = await getAuthToken();
    // bubble up the error
    if (!authToken && authRequired) throw onHTTPError(401, "Session unavailable.");

    params = params || {};

    const location = await new Promise<GeolocationPosition | null>((resolve) => navigator.geolocation.getCurrentPosition(resolve, () => resolve(null)));
    params.headers = {
        ...(params?.headers || {}),
        // Icon Event headers
        "X-Application-Name": packageJson.name,
        "X-Application-Version": packageJson.version,
        "X-Application-Address": window.location.host,
        "X-User-IP": await getUserIP(),
        "X-User-Location": `${location?.coords?.latitude || ""},${location?.coords?.longitude || ""}`,
        "X-Authorization": await getCognitoID() || "",
        // Add Authorization header
        "Authorization": `Bearer ${authToken?.toString() || ""}`,
    };

    const { headers, query, raw, json, form } = params;

    // Extract the possible body types
    if ([raw, json, form].filter(value => value !== undefined).length > 1) {
        throw new Error("DEV Error: A request cannot have a combination of JSON, Form-Data or Raw content!");
    }

    // Add Content-Type header if JSON body is provided
    if (json) headers['Content-Type'] = 'application/json';

    // Append query parameters to the URL
    if (query) url += "?" + new URLSearchParams(query as { [key: string]: string }).toString();

    let response;
    try {
        response = await fetch(url, {
            method,
            headers: params.headers,
            // Set the body content
            body: json ? JSON.stringify(params.json)
                : form ? Object.entries(form).reduce((fd, [key, value]) => (
                    fd.append(key,
                        value instanceof Blob || value instanceof File ? value
                            : typeof value === "object" && value !== null ? JSON.stringify(value)
                                : String(value)
                    ), fd
                ), new FormData())
                    : raw as BodyInit | null | undefined,
        });

        // Handle HTTP errors
        if (!response.ok) {
            let errResponseJSON;
            try {
                errResponseJSON = await response.json();
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (_err) {
                // ignore
            }
            throw onHTTPError(response.status,
                errResponseJSON ? getErrorMessageFromJSONReponse(errResponseJSON)
                    : await response.text() || response.statusText);
        }
    } catch (error) {
        if (error instanceof TypeError) {
            console.error("Network error or CORS issue:", error);
            throw new Error("Network error: Unable to reach the server. Please check your internet connection or contact support.");
        }
        throw error; // Re-throw for other types of errors
    }
    // Parse JSON response, or handle non-JSON responses gracefully
    let responseJSON;
    try {
        responseJSON = await response.json();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
        throw new Error("Internal Server Error! Received malformed data - not a JSON.");
    }

    // As of now, Icon's API Gateway returns Lambda Responses as:
    //  HTTP Status: 200 OK
    //  JSON-Content:
    //  {
    //      "statusCode": 404, // actual Lambda HTTP Status Code
    //      "headers": { ... }, // actual Lambda Response Headers
    //      "body": <whatever> // actual Lambda Response content
    //  }
    //
    // Certain APIs return embedded { statusCode, headers, body: {statusCode, headers, body: ... and so on } }
    // Also, in future, the response might get mapped to a standard respone, without statusCode, headers or body
    //  
    // JSON-Content for Lambda Errors:
    // {
    //     "errorType": "Sandbox.Timedout",
    //     "errorMessage": "RequestId: 9fa35490-dada-417e-976e-8e7196dac6ea Error: Task timed out after 3.00 seconds"
    // }
    //
    // JSON-Content for certain Icon API Errors:
    // {
    //     "statusCode": 401,
    //     "body": {
    //         "error": "Verification failed"
    //     }
    // }
    if (!responseJSON) {
        throw new Error("Internal Server Error - did not receive any JSON.");
    }
    if (responseJSON.errorType) {
        console.warn("Lambda error", responseJSON);
        throw new Error("Internal Server Error - Please try again later.");
    }
    // Flatten all response.body.body.body ...
    let data = { ...responseJSON };
    let nested = responseJSON.body;
    while (nested != null) {
        data = Array.isArray(nested) ? { ...data, list: [...nested] } : { ...data, ...nested };
        delete data.body;
        nested = nested.body;
    }
    // Actual Lambda status
    if (data.statusCode != null && data.statusCode !== 200) {
        throw onHTTPError(data.status, getErrorMessageFromJSONReponse(data, `Lambda-${data.statusCode}`));
    }
    delete data.statusCode; // remove non-data field from Icon response
    delete data.headers; // remove non-data field from Icon response
    return data;
};