import WhiteDollarIcon from '@/components/icons/WhiteDollarIcon';
import WhiteChartIcon from '@/components/icons/WhiteChartIcon';
import WhiteHashtagIcon from '@/components/icons/WhiteHashtagIcon';
// import WhitePercentageIcon from '@/components/icons/WhitePercentageIcon';
import { formatCurrency } from '@/lib/utils';
import { BannerDetailsViewModel } from './view-models/BannerDetailsViewModel';
// import { formatCurrency } from "@/lib/utils";

interface DealsBannerProps {
  details?: BannerDetailsViewModel;
  showDate?: boolean;
}

export default function DealsBanner({ details }: DealsBannerProps) {
  if (!details) return null;

  return (
    <div>
      <div className="flex w-full place-content-between rounded-xl bg-white bg-gradient-to-t from-sky-300 via-sky-200 to-sky-100 px-5 py-1.5 2xl:px-16 ">
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteChartIcon />
          </div>
          <div className="flex flex-col">
            <div className="xl:text-xl 2xl:text-2xl font-bold">{details.totalOpportunities ? details.totalOpportunities : '--'}</div>
            <div className="lg:text-xs 2xl:text-sm font-light uppercase">total opportunities</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteDollarIcon />
          </div>
          <div className="flex flex-col">
            <div className="font-bold xl:text-xl 2xl:text-2xl">{details.totalProperties}</div>
            <div className="font-light uppercase lg:text-xs 2xl:text-sm">Total Properties</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteChartIcon />
          </div>
          <div className="flex flex-col">
            <div className="xl:text-xl 2xl:text-2xl font-bold">{details.totalAIV ? formatCurrency(details.totalAIV) : '--'}</div>
            <div className="font-light lg:text-xs 2xl:text-sm uppercase">Total AIV</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteHashtagIcon />
          </div>
          <div className="flex flex-col">
            <div className="xl:text-xl 2xl:text-2xl font-bold">{details.totalUPB ? formatCurrency(details.totalUPB) : '--'}</div>
            <div className="lg:text-xs 2xl:text-sm font-light uppercase">total upb</div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4 mt-3">
            <WhiteChartIcon />
          </div>
          <div className="flex flex-col">
            <div className="xl:text-xl 2xl:text-2xl font-bold">{details.averageAIV ? formatCurrency(details.averageAIV) : '--'}</div>
            <div className="lg:text-xs 2xl:text-sm font-light uppercase">average aiv</div>
          </div>
        </div>
        {/* <div className="flex">
          <div className="mr-4 mt-3">
            <WhitePercentageIcon />
          </div>
          <div className="flex flex-col">
            <div className="font-bold xl:text-xl 2xl:text-2xl">--</div>
            <div className="font-light uppercase lg:text-xs 2xl:text-sm">profit percentage</div> */}
            {/* {details.profitPercentage && details.averagePropertyAsIsValue+"%"} */}
          {/* </div>
        </div> */}
      </div>
    </div>
  );
}
