export enum DEAL_STATUS {
  Open = "Open",
  InReview = "InReview",
  Expired = "Expired",
  Rejected = "Rejected",
  OfferMade = "OfferMade",
  OfferAccepted = "OfferAccepted",
  OfferRejected = "OfferRejected",
  BuyerOfferMade = "BuyerOfferMade",
  BuyerOfferAccepted = "BuyerOfferAccepted",
  BuyerOfferRejected = "BuyerOfferRejected",
  OfferPendingReview = "OfferPendingReview",
  OfferCountered = "OfferCountered"
}