import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Paper, IconButton, Button } from "@mui/material";
import { X } from "lucide-react";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import DialogLoading from "@/components/ui/dialog-loading";
import { toast } from "react-toastify";
import { GET_LIST_PROPERTY_DOCUMENTS_API_URL, PROPERTY_DOCUMENTS_API_URL } from "@/config/config";
import { restAPIForJSON } from "@/services/RestCommon";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import UploadFileDialog from "../pages/deals/UploadFileDialog";
import LoadingModal from "./LoadingModal";

interface DocumentViewerProps {
  open: boolean;
  property: { id: string, name: string }
  opportunity: { id: string, name: string }
  address: string;
  onClose: () => void;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ open, property, address, opportunity, onClose }) => {
  const [treeData, setTreeData] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [fileURL, setFileURL] = useState<string>("");
  const [size, setSize] = useState({ width: 800, height: 600 });
  // Start with the root expanded
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  useEffect(() => {
    if (property?.id && opportunity?.id) {
      fetchFileTree();
    }
  }, [property, opportunity]);

  const fetchFileTree = async () => {
    try {
      setLoadingMessage("Fetching documents...")
      let url = GET_LIST_PROPERTY_DOCUMENTS_API_URL.replace("{opportunityID}", opportunity.id).replace("{propertyID}", property.id);
      const data = await restAPIForJSON<{ S3objects: string[] }>("GET", url);
      const s3Objects = data.S3objects || [];
      const structuredData = buildFileTree(s3Objects, opportunity, property);
      setTreeData(structuredData);
      
      // Auto-expand the root and first level nodes
      if (structuredData) {
        const rootId = structuredData.id;
        const firstLevelIds = structuredData.children?.map((child: any) => child.id) || [];
        let firstFolderId = ""
        structuredData.children?.forEach((p: any) => firstFolderId = p.children[0].id)
        setExpandedItems([rootId, ...firstLevelIds, ...firstFolderId]);
      }
    } catch (error) {
      console.error("Error fetching file tree:", error);
      toast.error("Failed to load documents");
    }
    finally{
      setLoadingMessage(null)
    }
  };

  const handleFileClick = async (filePath: string) => {
    if (!filePath) return;
    
    setSelectedFile(filePath);
    setLoadingState(true);

    try {
      const fileName = filePath.split("/").pop()
      let url = PROPERTY_DOCUMENTS_API_URL.replace("{opportunityID}", opportunity.id)
        .replace("{propertyID}", property.id)
        .replace("{s3Key}", fileName);

      const data = await restAPIForJSON<{ url: string }>("GET", url);
      setFileURL(data.url);
    } catch (error) {
      console.error("Error fetching file:", error);
      toast.error("Failed to load the document");
    } finally {
      setLoadingState(false);
    }
  };

  const buildFileTree = (data: string[], opportunity: { id: string; name: string }, property: { id: string; name: string }) => {
    if (!data.length) return null;
  
    const tree: any = {
      id: `root-${opportunity.id}`,
      name: opportunity.name,
      children: [],
      isFile: false,
      fullPath: null
    };
  
    data.forEach((key) => {
      let cleanKey = key.replace(`opportunities/${opportunity.id}/`, "");
      const parts = cleanKey.split("/");
  
      let currentLevel = tree;
  
      parts.forEach((part, index) => {
        if (!part) return;
  
        if (part === property.id) part = property.name;
  
        // Decode URL-encoded characters in the name
        const decodedName = decodeURIComponent(part);
  
        const isFile = index === parts.length - 1 && decodedName.includes(".");
        const nodeId = `node-${opportunity.id}-${property.id}-${parts.slice(0, index + 1).join("_")}`;
  
        let existingNode = currentLevel.children.find((child: any) => child.id === nodeId);
  
        if (!existingNode) {
          existingNode = {
            id: nodeId, 
            name: decodedName,
            isFile,
            children: [],
            fullPath: key, // Store the complete S3 path
          };
  
          currentLevel.children.push(existingNode);
        }
  
        currentLevel = existingNode;
      });
    });
    
    return tree;
  };

  // Custom node click handler separate from the TreeView expansion handling
  const handleNodeClick = (node: any, event: React.MouseEvent) => {
    event.stopPropagation();
    
    if (node.isFile) {
      // If it's a file, load it
      handleFileClick(node.fullPath);
    } else {
      // If it's a directory, toggle expansion manually
      const nodeId = node.id;
      setExpandedItems((prevExpanded) => {
        if (prevExpanded.includes(nodeId)) {
          return prevExpanded.filter(id => id !== nodeId);
        } else {
          return [...prevExpanded, nodeId];
        }
      });
    }
  };

  // Recursive function to render the tree
  const renderTree = (node: any) => {
    if (!node) return null;
    
    return (
      <TreeItem 
        key={node.id} 
        itemId={node.id}
        label={
          <div 
            onClick={(e) => handleNodeClick(node, e)}
            className={`py-1 px-1 rounded hover:bg-gray-100 ${node.isFile ? 'cursor-pointer' : 'font-medium'}`}
            title={node.name} // Add tooltip for full name on hover
          >
            <div className="truncate max-w-xs">
              {node.name}
            </div>
          </div>
        }
      >
        {Array.isArray(node.children) && node.children.length > 0
          ? node.children.map((child: any) => renderTree(child))
          : null}
      </TreeItem>
    );
  };

  const handleUpload = async (file: File) => {
    setLoadingMessage("Uploading file...")
    let url = PROPERTY_DOCUMENTS_API_URL;
    url = url.replace("{opportunityID}", opportunity.id).replace("{propertyID}", property.id).replace("{s3Key}", file.name);
    try {
      await restAPIForJSON("PUT", url, {
        headers: {
          "Content-Type": "application/octet-stream"
        },
        raw: file
      })
      toast.success("Document uploaded successfully");
    } catch (error) {
      toast.error("Failed to upload document");
    } finally {
      fetchFileTree();
      setUploadDialogOpen(false)
      setLoadingMessage(null)
      setSelectedFile("")
    }

  };

  return (
    <>
      <LoadingModal open={(loadingMessage != null )} message={loadingMessage || ""} />
      <UploadFileDialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} onUpload={handleUpload} />
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop
      maxWidth={false}
      PaperComponent={(props) => <DraggableResizablePaper {...props} size={size} setSize={setSize} />}
    >
      <DialogTitle className="cursor-move flex justify-between items-center bg-[#3B4F72] text-white px-4 py-2 rounded-t-lg">
        <div className="truncate max-w-lg" title={address}>
          {address}
        </div>
        <IconButton onClick={onClose} size="small">
          <X className="w-5 h-5 text-white" />
        </IconButton>
      </DialogTitle>

      <DialogContent className="flex w-full h-full p-4">
        <div className="flex flex-col justify-between w-1/3 h-[90%] overflow-hidden border-r border-gray-300 p-2">
          {treeData ? (
            <SimpleTreeView
              expandedItems={expandedItems}
              onExpandedItemsChange={(__event, ids) => {
                setExpandedItems(ids);
              }}
            >
              {renderTree(treeData)}
            </SimpleTreeView>
          ) : (
            <p>No files found</p>
          )}
          <Button className="mb-3" variant="contained" onClick={()=>setUploadDialogOpen(true) }>Add document</Button>
        </div>

        <div className="w-2/3 h-full flex justify-center items-center">
            {loadingState ? (
              <DialogLoading isLoading={loadingState} className="h-[85%]" loadingMessage="Loading ..." />
            ) : selectedFile ? (
              selectedFile.endsWith(".pdf") ? (
                <object data={fileURL} type="application/pdf" className="w-full h-full">
                  <p>
                    This browser may not support PDFs.{" "}
                    <a href={fileURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                      Download the PDF
                    </a>{" "}
                    instead.
                  </p>
                </object>
              ) : (
                <img src={fileURL} className="w-full h-full object-contain" alt="Preview" />
              )
            ) : (
              <div className="text-gray-500 text-center">
                <p>Select a file from the tree to view</p>
              </div>
            )}
          </div>
      </DialogContent>
    </Dialog>
  </>
  );
};

const DraggableResizablePaper = ({ size, setSize, ...props }: any) => {
  const [tempSize, setTempSize] = useState(size);

  return (
    <Draggable handle=".MuiDialogTitle-root" cancel=".react-resizable-handle">
      <ResizableBox
        width={tempSize.width}
        height={tempSize.height}
        axis="both"
        resizeHandles={["e", "s", "se", "sw", "w"]}
        minConstraints={[400, 300]} // Prevent getting stuck when resizing smaller
        maxConstraints={[window.innerWidth - 50, window.innerHeight - 50]} // Prevent overflow
        onResize={(_e, data) => {
          setTempSize({ width: data.size.width, height: data.size.height });
        }}
        onResizeStop={(_e, data) => {
          setSize({ width: data.size.width, height: data.size.height });
        }}
        className="relative rounded-lg shadow-lg bg-white overflow-hidden"
      >
        <Paper
          {...props}
          className="pointer-events-auto"
          style={{
            width: `${tempSize.width}px`,
            height: `${tempSize.height}px`,
            transition: "width 0.1s ease-out, height 0.1s ease-out",
          }}
        />
      </ResizableBox>
    </Draggable>
  );
};

export default DocumentViewer;