import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import TimelineScreen from "./TimelineScreen/TimelineScreen";
interface TimelineDialogProps {
    opportunityId: string;
    lenderName: string;
    isOpen: boolean;
    toggleModal: () => void;
}

const TimelineDialog: React.FC<TimelineDialogProps> = ({ isOpen, opportunityId, lenderName, toggleModal }) => {
    const modalToggle = () => {
        toggleModal();
    }
    return (
        <>
            <Dialog open={isOpen} onOpenChange={modalToggle}>
                <DialogContent className="w-[70%] max-w-[70%] overflow-x-hidden border-0 p-0 outline-none">
                    <DialogHeader>
                        <div className="flex w-full bg-[#3B4F72] text-white min-h-20 text-xl items-center">
                            <div className="ps-7">
                                <DialogTitle className="font-bold text-xl space-y-14">Activities Timeline: <b>{lenderName}</b></DialogTitle>
                            </div>
                        </div>
                    </DialogHeader>
                    <>
                        <TimelineScreen opportunityId={opportunityId}></TimelineScreen>
                    </>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TimelineDialog;