import { GET_LOAN_TAPE_FIELDS_URL, LOAN_TAPE_URL } from "@/config/config";
import { restAPIForJSON } from "@/services/RestCommon";
import { OpportunityViewModel } from "../dashboard/pages/deals/view-models/OpportunityViewModel";
import { parseOpportunity } from "../dashboard/pages/deals/services/dealService";
import moment from "moment";

export interface Field {
  key: string;
  type:
  | "Text"
  | "Number"
  | "Currency"
  | "Date"
  | "Percentage"
  | "Memo/Text";
  mandatory: "Yes" | "No";
  description: string;
}

const loanTapeField: Field[] = JSON.parse(localStorage.getItem("loanTapeFields") ?? "[]");

type FieldFormatTypeMap = {
  Text: string;
  Number: number;
  Currency: number;
  Date: Date;
  Percentage: number;
  "Memo/Text": string;
};

// type LoanTapeFieldNames = typeof loanTapeField[number]["fieldName"];

export type ThirdPartyLoanTape = {
  [F in (typeof loanTapeField)[number]as F["key"]]: FieldFormatTypeMap[F["type"]];
};

export type UploadDetails = {
  batchID: string;
  name: string;
  description?: string;
  numRecords: number;
  numFailedRecords: number;
  startedAt: Date;
  durationMS: number;
  originalFileURL: string;
  originalFileName: string;
  failedFileURL: string;
  failedFileName: string;
  reportFileURL: string;
  reportFileName: string;
  newPropertyIDs: string[];
  existingPropertyIDs: string[];
  opportunityID?: string;
  updatedOpportunity?: OpportunityViewModel;
};

const escapeCellForCSV = (v: unknown) =>
  v == null || v === ""
    ? ""
    : typeof v === "number"
      ? v
      : `"${String(v).replace(/"/g, '""')}"`;

export const upload = async (
  lenderID: string,
  file?: File,
  manualEntries?: ThirdPartyLoanTape[],
  name?: string,
  description?: string,
  opportunityID?: string,
  propertiesLength?: number,
): Promise<UploadDetails> => {
  if (!file && (!manualEntries || !manualEntries.length)) {
    throw new Error("DEV Error: neither file nor manual entries were supplied");
  }

  const form: { [key: string]: unknown } = {};
  form["lenderID"] = lenderID;
  if (file) form["file"] = file;
  if (manualEntries && manualEntries.length) {
    form["manual"] = [
      Object.keys(manualEntries[0]).map(escapeCellForCSV).join(","),
      ...manualEntries.map((row) =>
        Object.values(row).map(escapeCellForCSV).join(",")
      ),
    ].join("\n");
    if (!name) name = `Manual_${moment().format("YYYYMMDDHHmm")}`;
  }

  if (description) form["description"] = description;
  if (name) form["name"] = name;
  if (opportunityID && propertiesLength) {
    form["opportunityID"] = opportunityID;
    form["totalProperties"] = propertiesLength;
  }
  const { opportunity, ...uploadDetails } = await restAPIForJSON<Omit<UploadDetails, "updatedOpportunity"> & { opportunity: any }>("POST", LOAN_TAPE_URL, { form });
  return { ...uploadDetails, updatedOpportunity: parseOpportunity(opportunity) };
};

export const getLoanTapeFields = async (): Promise<Field[]> => {
  const response = await restAPIForJSON<{ fields: Field[] }>("GET", GET_LOAN_TAPE_FIELDS_URL);
  return response.fields;
};
