// src/components/dashboard/pages/deals/PropertyDealsTable.tsx

import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { useFloating, arrow, shift, offset, Placement } from "@floating-ui/react";
import { ChevronRight, AlertCircle, Pencil } from "lucide-react";
import { formatCurrency, getTimeInDefault } from "@/lib/utils";
import * as Checkbox from "@radix-ui/react-checkbox";
import { PropertyProps } from "./Property.interface";
import { ErrorCode } from "./view-models/ErrorCode";
import DocumentIcon from "@/components/icons/DocumentIcon";
import EditableField, { UpdatedOpportunityField } from "../../common/EditableField";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { OpportunityData } from "./view-models/CarouselCardViewModel";
import { UpdatePropertyDealAttributeInput } from "./types";
import { useUpdatePropertyDealAttributes } from "./hooks";
import { toast } from "react-toastify";
import PropertiesValueChangeDialog, { PropertyValuationType, PropertyUpdate } from "../../common/PropertyValuationTypeChangeDialog";
import DocumentViewer from "../../common/DocumentViewer";
import { CountBadge } from "@/components/ui/CountBadge";
import InformationModal from "../../common/InformationModal";
import { CheckIcon } from "@radix-ui/react-icons";
import EditBorrowerDialog from "./EditBorrowerDialog";
import { OpportunityViewModel } from "./view-models/OpportunityViewModel";
import BorrowerName from "./BorrowerName";

interface PropertyDealsTableProps {
    properties: PropertyProps[];
    propertyChanges?: PropertyUpdate[];
    opportunity?: OpportunityData;
    errorCodes: ErrorCode[];
    onDetailsClick?: (propertyId: string) => void;
    onUpdateValue?: (opportunityId: string, value: OpportunityData) => void;
    hideProgressBar?: () => void;
    showProgressBar?: () => void;
    lenderName?: string;
    onImmediatePropertyUpdates?: (updatedProperties:PropertyUpdate[]) => Promise<OpportunityData>;
    onPropertyFieldChange?: (propertyID: string, field: string, fromValue: number | string, toValue: number | string) => void;
    setOpenBorrowDialog?:(e:boolean)=>void
    openBorrowDialog? : boolean
    opportunities? : OpportunityViewModel[]
    updateBorrowerDetails? : (property : PropertyProps, borrowerThirdPartyID  :string)=>void
}
export interface PropertyDealsTableRef {
    divRef: HTMLDivElement | null;
    handleSaveClick:()=>void;
    handleDiscrdClick:()=>void;
  }


interface AlertWithPopupProps {
    children: React.ReactNode; // Custom message to display in the popup
    placement?: Placement; // Optional: Allow placement customization
    alertCount?: number;
}

const AlertWithPopup: React.FC<AlertWithPopupProps> = ({ children, placement = 'right', alertCount = 0 }) => {
    const [isHovered, setIsHovered] = useState(false);
    const arrowRef = useRef(null);
    const { refs, floatingStyles } = useFloating({
        placement: placement,
        middleware: [
            offset(8), // Space between tooltip and target
            shift(), // Ensures tooltip remains in viewport
            arrow({ element: arrowRef }), // Enables arrow
        ],
    });

    return (
        <div className="relative">
            <div
                ref={refs.setReference}
                onMouseEnter={() => {
                    setIsHovered(true);
                }}
                onMouseLeave={() => setIsHovered(false)}
                className="inline-flex items-center cursor-pointer"
                aria-label="Alert Icon">
                <AlertCircle color="red" />
                <CountBadge count={alertCount} className='rounded-xl px-1.5'/>
            </div>

            {isHovered && (
                <div
                    ref={refs.setFloating}
                    style={{ ...floatingStyles, opacity: 1, zIndex: 10 }}
                    className="bg-white border border-gray-300 rounded-md shadow-lg p-3 text-base w-80" >
                    {children}
                </div>
            )}
        </div>
    );
};

// const renderBorrowerName : React.FC<string>  = (borrowerThirdPartyId: string) => {
//     const [borrower, setBorrower] = useState<ThirdPartyProfile | null>(null)
//     const [isLoading, setIsLoading] = useState(false)
//     const fetchBorrowerDetail = async () => {
//         setIsLoading(true)
//         let borrower = await getThirdPartyProfile(borrowerThirdPartyId);
//         setBorrower(borrower)
//         setIsLoading(false)
//     }
//     useEffect(() => { fetchBorrowerDetail() }, [])

//     return <span className="text-black dark:text-white">{true ? "• • •" : "borrower?.party_name"}</span>
// }

const PropertyDealsTable = forwardRef<
PropertyDealsTableRef, // 👈 Type of the ref (adjust based on usage)
  PropertyDealsTableProps
>((props, ref) => {
  const {
    properties,
    propertyChanges,
    onDetailsClick,
    onUpdateValue,
    opportunity,
    errorCodes,
    hideProgressBar,
    showProgressBar,
    onImmediatePropertyUpdates,
    onPropertyFieldChange,
    openBorrowDialog,
    setOpenBorrowDialog,
    updateBorrowerDetails
  } = props;
  const localDivRef = useRef<HTMLDivElement | null>(null);
    const [showDialog, setShowDialog] = useState(false)
    const [showInformationDialog, setShowInformationDialog] = useState(false)
    const [editedPropertyFields, setPropertyFields] = useState<UpdatedOpportunityField[]>([]);
    const [isAIVChangeDialogOpen, setIsAIVChangeDialogOpen] = useState<boolean>(false);
    const [editFieldType, setEditFieldType] = useState<PropertyValuationType>(PropertyValuationType.AIV);
    const [editedAivPropertyId, setEditedAivPropertyId] = useState<string>("");
    const [docProperty, setDocProperty] = useState<{id:string, name:string} | null>();
    const [docOpportunity, setDocOpportunity] = useState<{id:string, name:string} | null>();
    const [docViewPropertyAddress, setDocViewPropertyAddress] = useState<string>("");
    const [editedAivOpportunityId, setEditedAivOpportunityId] = useState<string>("");
    const [savedPropertyDealAttributes, setSavedPropertyDealAttributes] = useState<UpdatePropertyDealAttributeInput[]>([]);
    const {
        data: updatedPropertyDealAttributesResponse,
        error: updatePropertyDealAttributesError,
        isLoading: isUpdatingPropertyDealAttributes } = useUpdatePropertyDealAttributes();
    const [borrowerEditingId, setBorrowerEditingId] = useState("")
    const [isUpdated, setIsUpdated] = useState(false)

    



    useEffect(() => {
        if (isUpdatingPropertyDealAttributes) {
            showProgressBar && showProgressBar();
        } else if (properties.length) {
            hideProgressBar && hideProgressBar();
        }
    }, [isUpdatingPropertyDealAttributes]);

    useEffect(() => {
        const error = updatePropertyDealAttributesError;

        if (error) {
            hideProgressBar && hideProgressBar();
        }
    }, [updatePropertyDealAttributesError]);

    useEffect(() => {
        if (updatedPropertyDealAttributesResponse) {
            onUpdateValue && onUpdateValue(opportunity!.opportunityID, updatedPropertyDealAttributesResponse.body);
            toast.success("Property Deal attributes updated successfully.");
        }
    }, [updatedPropertyDealAttributesResponse]);

    useImperativeHandle(ref, () => ({
        divRef: localDivRef.current,
        handleSaveClick: () => {
            onSaveAttributesClickHandler();
        },
        handleDiscrdClick: () => {
            onDiscardAllChanges();
        },
      }));
    const onPropertySourceValueEdit = (propertyId: string, opportunityId: string, field: string | undefined) => {
        setEditedAivPropertyId(propertyId);
        setIsAIVChangeDialogOpen(true);
        setEditedAivOpportunityId(opportunityId);

        switch (field) {
            case "AIV":
                setEditFieldType(PropertyValuationType.AIV);
                break;
            case "UPB":
                setEditFieldType(PropertyValuationType.UPB);
                break;
            case "OA":
                setEditFieldType(PropertyValuationType.OA);
                break;
        }
    }

    // const onDocView = (propertyId: string, address: string, houseCanaryReportStatus: string) => {
    //     if (houseCanaryReportStatus === HouseCanaryReportStatus.NOT_AVAILABLE) {
    //         setShowInformationDialog(true);
    //         setDocViewPropertyAddress(address);
    //     }
    //     else {
    //         setShowDialog(true);
    //         setDocViewPropertyAddress(address);
    //         //XHR request
    //         setDocViewPropertyId(propertyId);
    //     }
    // }
    const handleEditClick = (currentValue: string, propertyId?: string, field?: string) => {
        const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
        updatedFields.push(new UpdatedOpportunityField(propertyId || '', field || '', Number(currentValue.replace(/[$,]/g, '')), currentValue));
        setPropertyFields(updatedFields);
    };

    // const handleSaveFieldClick = (updatedField: UpdatedOpportunityField, propertyId?: string, field?: string) => {
    //     if (!propertyId || !field) return;

    //     // Save the updated field value to savedPropertyDealAttributes
    //     const updatedAttribute: PropertyUpdate = {
    //         propertyID: propertyId,
    //         operation: 'update',
    //         attribute: field,
    //         value: updatedField.value,
    //     };

    //     const existingAttributeIndex = savedPropertyDealAttributes.findIndex(attribute => attribute.propertyID === propertyId && attribute.attribute === field);

    //     if (existingAttributeIndex !== -1) {
    //         const updatedAttributes = [...savedPropertyDealAttributes];
    //         updatedAttributes[existingAttributeIndex] = updatedAttribute;
    //         setSavedPropertyDealAttributes(updatedAttributes);
    //     } else {
    //         setSavedPropertyDealAttributes([...savedPropertyDealAttributes, updatedAttribute]);
    //     }
    //     // onUpdateValue && onUpdateValue(updatedField.id, { [updatedField.field]: updatedField.value });
    //     const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
    //     setPropertyFields(updatedFields);

    //     onPropertyFieldChange?.(propertyId, field, updatedField.currentValue, updatedField.value);
    // };

    const handleDiscardFieldClick = (propertyId?: string, field?: string) => {
        const updatedFields = editedPropertyFields.filter((editedField) => editedField.id !== propertyId || editedField.field !== field);
        setPropertyFields(updatedFields);
    };

    const handleEditBorrower = (propertyId : string)=>{
        setOpenBorrowDialog?.(true);
        setBorrowerEditingId(propertyId);
    } 
    const goToDocumentViewer = async (propertyID: string, opportunityID: string, address: string, lenderName: string) => {
        setDocOpportunity({ id: opportunityID, name: lenderName })
        setDocProperty({ id: propertyID, name: address })
        setDocViewPropertyAddress(address)
        setShowDialog(true)
    };

    const onClose = () => {
        setDocOpportunity(null)
        setDocProperty(null)
        setShowDialog(!showDialog);
    }
    const renderTableCell = (isReadOnly: boolean, prefix: string, value: string, propertyId?: string, field?: string, isEditable: boolean = false) => {
        const editedField = editedPropertyFields.find((editedField) => editedField.id === propertyId && editedField.field === field);
        const savedAttribute = savedPropertyDealAttributes.find(attribute => attribute.propertyID === propertyId && attribute.attribute === field);
        if (savedAttribute) {
            value = formatCurrency(savedAttribute.value);
        }

        return (
            <TableCell className={`text-base whitespace-nowrap ${isReadOnly ? "opacity-30 pointer-events-none" : ""}`}>
                <span className="text-customGray929DAD">{prefix}</span>
                {
                    field === 'AIV' || field === 'UPB' || field === 'OA' ?
                        <div className="flex items-center">
                            <span className="text-black dark:text-white">{value}</span>
                            <Pencil className="ml-2 cursor-pointer w-4 h-4"
                                onClick={() => onPropertySourceValueEdit(propertyId ? propertyId : "", opportunity!.opportunityID, field)}
                            />
                        </div>
                        : isEditable ?
                            <>
                                {editedField ?
                                    <EditableField
                                        initialValue={editedField}
                                        onSave={(newValue) => propertyId && field && onPropertyFieldChange?.(propertyId, field, value, newValue.value)}
                                        onDiscard={() => handleDiscardFieldClick(propertyId, field)}
                                    />
                                    :
                                    <div className="flex items-center">
                                        <span className="text-black dark:text-white">{value}</span>
                                        <Pencil onClick={() => handleEditClick(value, propertyId, field)} className="ml-2 cursor-pointer w-4 h-4" />
                                    </div>
                                }
                            </>
                            : <span className="text-black dark:text-white">{value}</span>
                }
            </TableCell>
        );
    };

    const onDiscardAllChanges = () => {
        setPropertyFields([]);
        setSavedPropertyDealAttributes([]);
    };

    const onSaveAttributesClickHandler = async () => {
        if (!opportunity) return;
        await onImmediatePropertyUpdates?.(savedPropertyDealAttributes);
        setSavedPropertyDealAttributes([]);
    };

    const formatErrorPopupMessage = (errors: string[]) => {
        return (
            <div>
                {errors.map((errorCode) => {
                    const error = errorCodes.find((e) => e.errorCode === errorCode);
                    return error ? (
                        <p key={error.errorCode}>
                            <b>{error.errorCode}</b>: {error.description}
                        </p>
                    ) : null;
                })}
            </div>
        )
    }
    // const onDocumentLoading = (id: string, success: boolean) => {
    //     const property = properties.find(p => p.property_identifier === id);
    //     if (property) {
    //         property['house_canary_report_status'] = success? HouseCanaryReportStatus.AVAILABLE : HouseCanaryReportStatus.NOT_AVAILABLE
    //     }
    // }



    return (
        <>
            <div>
            {/* <UploadFileDialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} onUpload={handleUpload} /> */}
                <Table className="[&_tr:nth-child(even)]:bg-muted/50">
                    <TableBody>
                        <TableRow className="border-t-[1px]">
                            <TableCell></TableCell>
                            <TableCell className="pr-0"></TableCell>
                            <TableCell className="pl-1">#</TableCell>
                            <TableCell>Docs</TableCell>
                            <TableCell>Address</TableCell>
                            {/* <TableCell>Zip</TableCell> */}

                            <TableCell>Est Offer</TableCell>
                            <TableCell>As Is Value (AIV)</TableCell>
                            <TableCell>Unpaid Principal Balance (UPB)</TableCell>

                            <TableCell>Origination Amount (OA)</TableCell>
                            <TableCell>Tax Assessment Value</TableCell>
                            {/* <TableCell>Total Payoff Balance</TableCell> */}


                            <TableCell>Loan Type</TableCell>
                            <TableCell>Loan Term</TableCell>
                            <TableCell>Borrower</TableCell>
                            <TableCell>Origination Date</TableCell>

                            <TableCell>Lien Position of Lender</TableCell>
                            <TableCell>Tax Lien</TableCell>
                            <TableCell>Tax Lien Amount</TableCell>
                            <TableCell>HOA Lien</TableCell>
                            <TableCell>HOA Lien Amount</TableCell>

                            <TableCell>Default Type</TableCell>
                            <TableCell>Time in Default</TableCell>
                            <TableCell>Tax Assessment Year</TableCell>
                            {/* <TableCell>Assessed Value</TableCell>
                                <TableCell>Est Profit Amount</TableCell>
                                <TableCell>Estimate Sale Price</TableCell>
                                <TableCell>Document Type</TableCell> */}
                            {/* <TableCell>Estimated Purchase Price</TableCell> */}
                            {/* <TableCell>PB</TableCell> */}
                            <TableCell>APN</TableCell>
                            <TableCell>Asset Class</TableCell>
                        </TableRow>
                        {
                            properties.map((property, index) => {
                                const modifiedStatus = propertyChanges?.find(pu => pu.propertyID === property.id && pu.attribute === "status")?.value;
                                const isChecked = (modifiedStatus || property.status) === "Active";
                                const isReadOnly = property.status === "Removed";
                                
                                return <TableRow key={property.id} className="border-t-[1px]">
                                    {borrowerEditingId === property.id && <EditBorrowerDialog isUpdated={isUpdated} setIsUpdated={setIsUpdated} updateBorrowerDetails={updateBorrowerDetails} borrowerThirdPartyId={property.borrowerThirdPartyID ?? ""} opportunityID={opportunity?.opportunityID ?? ""} totalProperty={opportunity?.totalProperties ?? 0} property={property} setBorrowingDialog={setOpenBorrowDialog} openBorrowDialog={openBorrowDialog ?? false} borrowerName={property.borrowerCalc}></EditBorrowerDialog>}
                                    <TableCell>

                                        <Checkbox.Root onCheckedChange={check => onPropertyFieldChange?.(property.property_identifier, "status", property.status, check === true ? "Active" : "Removed")}
                                            checked={isChecked}
                                            className="bg-transparent border-black w-5 h-5 border-2 mr-3 flex items-center justify-center ">
                                            <Checkbox.Indicator>
                                                <CheckIcon className="text-black w-6 h-6" />
                                            </Checkbox.Indicator>
                                        </Checkbox.Root>
                                    </TableCell>
                                    <TableCell className={`pr-10 ${isReadOnly ? "opacity-30 pointer-events-none" : ""}`}>
                                        {property.error_codes?.length ?
                                            <span>
                                                <AlertWithPopup placement="right" alertCount={property.error_codes?.length ?? 0}>
                                                    {formatErrorPopupMessage(property.error_codes)}
                                                </AlertWithPopup>
                                            </span> : undefined}
                                    </TableCell>
                                    <TableCell className={`pl-1 ${isReadOnly ? "opacity-30 pointer-events-none" : ""}`}>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell className={`flex mb-2 gap-4 ${isReadOnly ? "opacity-30 pointer-events-none" : ""}`}>
                                        <div className="relative cursor-pointer" onClick={() => goToDocumentViewer(property.id, opportunity?.opportunityID ?? "",property.address, opportunity?.lenderName ?? "")}>
                                            <DocumentIcon />
                                        </div>
                                        {/* <input type="file" id="file-input" onChange={handleFileChange} /> */}
                                        {/* <FilePlus onClick={()=>{}} className="" color="#08A1E2"/> */}
                                    </TableCell>
                                    {renderTableCell(isReadOnly, "", property.address)}
                                    {/* {renderTableCell(isReadOnly, "", property.zip)} */}
                                    {renderTableCell(isReadOnly, "", formatCurrency(Number(opportunity?.opportunityProperties.find((s)=>s.propertyID === property.id)?.MAOB)), property.id, "adjustedMAOB", true)} {/* Est Offer */}
                                    {renderTableCell(isReadOnly, "", formatCurrency(Number(opportunity?.opportunityProperties.find((s)=>s.propertyID === property.id)?.AIV)), property.id, "AIV", true)} {/* As Is Value */}
                                    {renderTableCell(isReadOnly, "", formatCurrency(Number(opportunity?.opportunityProperties.find((s)=>s.propertyID === property.id)?.UPB)), property.id, "UPB", true)} {/* Unpaid Principle Balance */}
                                    {renderTableCell(isReadOnly, "", formatCurrency(Number(opportunity?.opportunityProperties.find((s)=>s.propertyID === property.id)?.AAO)), property.id, "OA", true)} {/* Origination Amount */}
                                    {renderTableCell(isReadOnly, "", formatCurrency(property.assessedValue))}
                                    {/* {renderTableCell(isReadOnly, "", formatCurrency(Number(property.totalfirstLienPositionPayoffBalance)))} */}


                                    {renderTableCell(isReadOnly, "", property.firstLienPostionMortgageLoanType || '--')}
                                    {renderTableCell(isReadOnly, "", property.firstLienPositionMortgageLoanTerm !== null ? `${property.firstLienPositionMortgageLoanTerm} ${property.firstLienPositionLoanTermType}` : '--')}
                                    <TableCell className={`flex items-center text-base whitespace-nowrap ${isReadOnly ? "opacity-30 pointer-events-none" : ""}`}>
                                        {
                                            property.borrowerThirdPartyID ? <BorrowerName isUpdated={isUpdated} borrowerThirdPartyId={property.borrowerThirdPartyID}></BorrowerName> :
                                                <span className="text-black dark:text-white">{property.borrower_calc}</span>
                                        }
                                        <Pencil className="ml-2 cursor-pointer w-4 h-4" onClick={() => handleEditBorrower(property.id)}></Pencil>
                                    </TableCell>
                                    {renderTableCell(isReadOnly, "", property.firstLienPostionMortgageRecordingDate)}




                                    {renderTableCell(isReadOnly, "", 'FIRST')}
                                    {renderTableCell(isReadOnly, "", property.taxLien === '1' ? "YES" : "NO")}
                                    {renderTableCell(isReadOnly, "", '--')}
                                    {renderTableCell(isReadOnly, "", '--')}
                                    {renderTableCell(isReadOnly, "", '--')}

                                    {renderTableCell(isReadOnly, "", property.foreclosure_document_type)}
                                    {renderTableCell(isReadOnly, "", property.defaultRecordingDate != 'null' ? getTimeInDefault(property.defaultRecordingDate || '') : '--')}
                                    {renderTableCell(isReadOnly, "", property.assessedYear)}
                                    {/* {renderTableCell(isReadOnly, "", formatCurrency(property.assessedValue))} */}
                                    {/* {renderTableCell(isReadOnly, "LTV - ", property.totalOriginationAmount + '%')} */}
                                    {/* {renderTableCell(isReadOnly, "", formatCurrency(deal.summary.averagePropertyValue))}
                                {renderTableCell(isReadOnly, "", formatCurrency(property.marketValue))}
                                {renderTableCell(isReadOnly, "", property.documentType)} */}
                                    {/* {renderTableCell(isReadOnly, "", '??')} */}
                                    {/* {renderTableCell(isReadOnly, "", '??')} */}
                                    {renderTableCell(isReadOnly, "", property.apn)}
                                    <TableCell className={`text-customGray929DAD text-base ${isReadOnly ? "opacity-30 pointer-events-none" : ""}`}>
                                        {property.assetClass}
                                    </TableCell>
                                    <TableCell className={`${isReadOnly ? "opacity-30 pointer-events-none" : ""}`}>
                                        <button className="text-black dark:text-white" onClick={() => onDetailsClick && onDetailsClick(property.id)}>
                                            <ChevronRight />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </div>
            <PropertiesValueChangeDialog
                type={editFieldType}
                totalProperties={properties.length}
                opportunityId={editedAivOpportunityId}
                property={opportunity?.opportunityProperties?.find(
                    prop => prop.propertyID === editedAivPropertyId
                )}
                propertyId={editedAivPropertyId}
                toggleModal={() => setIsAIVChangeDialogOpen(!isAIVChangeDialogOpen)}
                isOpen={isAIVChangeDialogOpen}
                onChange={(_id, _o)  => {
                    if(onUpdateValue){
                        onUpdateValue(_id, _o);
                    }
                    setIsAIVChangeDialogOpen(false)
                }}
            />
            {docOpportunity && docProperty && <DocumentViewer
                open={showDialog}
                address={docViewPropertyAddress}
                onClose={onClose}
                opportunity={docOpportunity }
                property={docProperty }
                 />}
            <InformationModal
                open={showInformationDialog}
                header={docViewPropertyAddress}
                message="No House Canary Mapping document"
                toggleModal={() => {setShowInformationDialog(!showInformationDialog)}}
                />
        </>
    );
});

export default PropertyDealsTable;