import { MAOBCalculation, Scope, SellerOffer } from "./PricingRules";

// interface MaobCalculation {
//     ltvThreshold: number;
//     whenLess: ComparatorValue;
//     whenGreaterOrEqual: ComparatorValue;
// }

// interface ComparatorValue {
//     aaoPerc: number;
//     aivPerc: number;
//     upbPerc: number;
//     selectLesserOf: boolean;
// }

// interface Rule {
//     assetClasses: string[];
//     maobCalculation: MaobCalculation;
//     maosCalculation: {
//         profitMargin: number;
//     };
//     offers: SellerOffer[];
//     status: string;
// }

// interface SellerOffer {
//     daysToClose: number;
//     discountPerc: number;
//     daysForDueDiligence: number;
// }

interface RuleBase {
    globalDefault: boolean,
    cashDownPercent: number,
    overridesIDS: string[],
    name: string,
    description: string,
    profitMargin: number,
    scopes: Scope[];
    status: string;
}

const mapScopes = (scopes: Scope[]) => {

    const mapConditions = (
        ltvThreshold: number, // Loan to Value Threshold Percentage
        whenLessOrEqual: MAOBCalculation, // When LTV is less than or equal to the threshold
        whenGreater: MAOBCalculation, // When LTV is greater than the threshold
      ) => {
        // const { ltvThreshold } = ltvThreshold
    
        const createCondition = (
            comparator: "whenLess" | "whenGreaterOrEqual",
            comparatorValue: MAOBCalculation,
            ltvThreshold: number
        ) => {
            const comparatorKey = comparator === "whenLess" ? "lt" : "gte";
            const condition = {
                conditionID: comparatorValue.id && comparatorValue.id.length ? comparatorValue.id: undefined,
                status: "Active",
                pricingFactors: [
                    {
                        attribute: "AAO",
                        value: comparatorValue.aaoPerc,
                    },
                    {
                        attribute: "AIV",
                        value: comparatorValue.aivPerc,
                    },
                    {
                        attribute: "UPB",
                        value: comparatorValue.upbPerc,
                    },
                ],
                pricingStrategyID: comparatorValue.selectLesserOf ? "MIN" : "MAX",
                qualificationCriteria: JSON.stringify({
                    bool: {
                        must: [
                            {
                                range: {
                                    firstLienPositionMortgageLTV: {
                                        [comparatorKey]: ltvThreshold,
                                    },
                                },
                            },
                        ],
                    },
                })
            };
            return condition;
        };
    
        // const { whenLess, whenGreaterOrEqual } = maobCalculation;
    
        const conditions = [
            createCondition("whenLess", whenLessOrEqual, ltvThreshold),
            createCondition("whenGreaterOrEqual", whenGreater, ltvThreshold),
        ];
    
        return conditions;
    };

    const mapWindows = (offers: SellerOffer[]) => {
        const createWindow = (window: SellerOffer) => {
            return {
                windowID: window.id.length? window.id: undefined,
                closingWindowDays: window.daysToClose,
                discountPercent: window.discountPerc,
                dueDiligenceDays: window.daysForDueDiligence
            };
        };
    
        return offers.map((offer) => createWindow(offer));
    };

    const createScope = (scope: Scope) => {
        const { assetClasses, maobCalculation, offers } = scope;
        const qualificationCriteria = {
            bool: {
                should: assetClasses?.map((assetClass) => {
                    return {
                        term: {
                            'assetClassGroup.keyword': assetClass
                        }
                    } 
                }) 
            },
        };
        return {
            scopeID: scope.id,
            conditions: mapConditions(maobCalculation.ltvThreshold, maobCalculation.whenLessOrEqual, maobCalculation.whenGreater),
            qualificationCriteria: JSON.stringify(qualificationCriteria),
            windows: mapWindows(offers),
            status:"Active",
        };
    };

    return scopes.map((scope) => createScope(scope));
};

export const mapUiToApi = (ruleBase: RuleBase) => {
    return {
        status:ruleBase.status,
        cashDownPercent: ruleBase.cashDownPercent,
        name: ruleBase.name,
        overridesIDS: ruleBase.overridesIDS,
        globalDefault: true,
        description: ruleBase.description,
        netProfitMargin: Number(ruleBase.profitMargin),
        scopes: mapScopes(ruleBase.scopes)
    };
};
