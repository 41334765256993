import React from "react";
import { useEffect, useState } from "react";
import { SellerOffer } from "../../models/PricingRules";
import { EditableNumDaysField, EditableFractionPercentageField } from "../EditableNumberField";

const offerKeys = {
  daysToClose: "Days to Closing",
  discountPerc: "Discount",
  daysForDueDiligence: "Due Diligence Days",
}

interface SellerOffersStageProps {
  defaultValue?: SellerOffer[];
  value?: SellerOffer[];
  onChange: (value: SellerOffer[]) => void;
  className?: string;
}

export const SellerOffersStage: React.FC<SellerOffersStageProps> = ({
  defaultValue,
  value: originalValue,
  onChange: onChange,
  className,
}) => {
  const [sellerOffers, setSellerOffers] = useState<SellerOffer[] | undefined>(originalValue);

  useEffect(() => {
    setSellerOffers(originalValue);
  }, [originalValue]);

  const onInputChanged = (key: "daysToClose" | "discountPerc" | "daysForDueDiligence", offerIndex: number, value: number) => {
    const newSellerOffers = sellerOffers?.map((prevOffer, i) =>
      i === offerIndex
        ? ({ ...prevOffer, [key]: value })
        : ({ ...prevOffer })
    );
    setSellerOffers(newSellerOffers);
    if (newSellerOffers) onChange?.(newSellerOffers);
  };

  return (
    <div
      className={`p-4 bg-white shadow-lg border border-gray-400 rounded-sm ${className || ""}`}
    >
      <div className="font-base text-sm text-center mb-5">Offers to Sellers</div>
      <table className="border-collapse table-auto w-auto">
        <thead className="font-bold">
          <td></td>
          <td className="text-xs text-center pl-2">Offer 1</td>
          <td className="text-xs text-center pl-2">Offer 2</td>
          <td className="text-xs text-center pl-2">Offer 3</td>
        </thead>
        <tbody>
          {Object.entries<string>(offerKeys).map(([key, label]) => (
            <tr key={key}>
              {/* Non-editable first column */}
              <td className="border border-transparent bg-white text-xs font-bold pl-2 whitespace-nowrap">
                {label}
              </td>

              {/* Editable and formatted offer columns */}
              {[0, 1, 2].map(i =>
                <td className="border border-transparent bg-white text-center text-xs">
                  {
                    key === "discountPerc" ? <EditableFractionPercentageField
                      defaultValue={defaultValue?.[i]?.[key]}
                      value={sellerOffers?.[i]?.[key]}
                      onChange={v => onInputChanged(key, i, v)} />

                      : key === "daysToClose" || key === "daysForDueDiligence" ? <EditableNumDaysField
                        defaultValue={defaultValue?.[i]?.[key]}
                        value={sellerOffers?.[i]?.[key]}
                        onChange={v => onInputChanged(key, i, v)} />

                        : undefined
                  }
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};