import React, { ReactNode, useState } from 'react';
import { Input } from '@/components/ui/input';
import { SearchIcon } from 'lucide-react';

interface Props {
  buttonOne: ReactNode; // First button component
  buttonTwo: ReactNode; // Second button component
  backgroundColor: string; // Background color class from TailwindCSS
  setPropertiesSearch?: any; // Background color class from TailwindCSS
}

const Searchbar: React.FC<Props> = ({ buttonOne, buttonTwo, backgroundColor, setPropertiesSearch }) => {
  const [search, setSearch] = useState<string>('');
  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let value = e.target.value.replace(/^\s+/g, '')
  //   setSearch(value);
  //   setPropertiesSearch(value)
  // };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const trimmedValue = search.trim();
      setPropertiesSearch(trimmedValue);
    }
  };
  return (
    <div className={`flex items-center ${backgroundColor} pl-4`}>
      <SearchIcon className="h-7 pt-2" />
      <Input className={`flex-1 p-2 ${backgroundColor} border-none outline-none`} 
      value={search} 
      placeholder="Search Profiles" 
      onChange={(e) => setSearch(e.target.value.replace(/^\s+/g, ''))} 
      onKeyDown={handleKeyPress}
      />
      <div className="flex items-center space-x-2">
        {buttonOne}
        <div className="mx-2 h-8 border-r border-gray-400"></div>
        {buttonTwo}
      </div>
    </div>
  );
};

export default Searchbar;
