import { PRICING_RULE } from "@/config/config";
import { restAPIForJSON } from "@/services/RestCommon";
import { mapRuleBase, Rule, RuleBase, Rules } from "./PricingRuleApiToUiMapper";
import { mapUiToApi } from "./PricingRuleUiToApiMapper";
import { toast } from "react-toastify";

/**
 * Global Pricing Rule
 * 
 * Each rule caters to one or more asset classes, and every asset class is catered to by only one rule.
 * 
 * The rule is used to calculate:
 * 1. The MAOB (Maximum Allowable Outstanding Balance) as lesser or greater of:
 *      - AAO (Available Asset Value)
 *      - AIV (Asset’s Indexed Value)
 *      - UPB (Unpaid Principal Balance)
 *    based on a threshold of the LTV (Loan-to-Value) ratio
 * 2. Offers to the Lenders at various discounts on the MAOB, and timeframes
 * 3. MAOS (Maximum Allowable Outstanding Share) based on a predefined rate of Profit Margin
 */
export interface Scope {
  id: string;
  assetClasses?: string[]; // Unique set of asset classes (Empty array or `undefined` implies "Default Rule")
  maobCalculation: {
    ltvThreshold: number; // Loan to Value Threshold Percentage
    whenLessOrEqual: MAOBCalculation; // When LTV is less than or equal to the threshold
    whenGreater: MAOBCalculation; // When LTV is greater than the threshold
  };
  offers: SellerOffer[];
  // maosCalculation: MAOSCalculation;
}

export interface MAOBCalculation {
  id?: string; //condition id
  selectLesserOf: boolean; // Select the lesser (when `true`) or greater (when `false`) of the AAO, AIV, and UPB values
  aaoPerc: number; // AAO Percentage
  aivPerc: number; // AIV Percentage
  upbPerc: number; // UPB Percentage
}

export interface SellerOffer {
  id: string; // window ID
  daysToClose: number; // Days to Close
  discountPerc: number; // Discount Percentage
  daysForDueDiligence: number; // Days for Due Diligence
}

export interface GlobalParameters {
  profitMargin: number; // Profit Margin Percentage
  cashDownPercent: number;
}

export interface PricingRuleDropDownData {
  id: string;
  name: string;
  description: string;
  dateUpdated: string;
  status: string;
  dateCreated: string;
};

export interface PricingRule {
  id: string;
  name: string;
  description: string;
  dateUpdated: string;
  status: string;
  dateCreated: string;
  profitMargin: number;
  cashDownPercent: number;
  globalDefault: boolean;
  createdCognitoID: string;
  dateExpired: string;
  lastUpdatedCognitoID: string;
  overridesIDS: string[];
  scopes: Scope[]
};

export const getAllPricingRule = async (): Promise<PricingRuleDropDownData[]> => {

  try{
    // let data: RuleBase[] = [];
  const response = (await restAPIForJSON<{rules: Rule[]}>('GET', PRICING_RULE));
  if (!response || !response.rules || !Array.isArray(response.rules)) {
    console.warn("No pricing rules found.");
    return [];
  }
  
  const data: RuleBase[] = response.rules.map((rule: Rule) => mapRuleBase(rule));
  return data.map((r: RuleBase) => {
    return {
      id: r.id,
      name: r.name ? r.name : r.id,
      description: r.description,
      dateUpdated: r.lastUpdated,
      status: r.status,
      dateCreated: r.dateCreated
    }
  });

  }catch(e: any){
    toast.error(`Error while fetching pricing rule ${e.message}`);
    console.error("Error while fetching pricing rule",e);
    return [];
  }
  
};

export const getActivePricingRule = async (): Promise<PricingRuleDropDownData | undefined> => { //Rule[]
  try {
    const response = await restAPIForJSON<{rules: Rule[]}>('GET', `${PRICING_RULE}?status=Active`);

    if (!response || !response.rules || response.rules.length === 0) {
      console.warn("No active pricing rule found.");
      return undefined; 
    }

    const rule = response.rules[0];

    return {
      id: rule.pricingRuleID, 
      name: rule.name || rule.pricingRuleID, 
      description: rule.description,
      dateUpdated: rule.lastUpdated,
      status: rule.status,
      dateCreated: rule.dateCreated
    };
  } catch (e:any) {
    toast.error(`Error while fetching pricing rule ${e.message}`);
    console.error("Error while fetching pricing rule", e);
    return undefined; // Handle errors by returning null
  }
};


export const getPricingRulesWithID = async (ruleID?: string): Promise<{rule: PricingRule}> => { //1 Rule
  let data: RuleBase;
  try{
  const response = await restAPIForJSON<{rule: Rule}>('GET', `${PRICING_RULE}/${ruleID}`);
  data =  mapRuleBase(response.rule);
  // return {profitMargin: data.profitMargin, cashDownPercent:data.cashDownPercent, scopes: mapDataUtility(data)};
  return {
    rule: {
      id: data.id,
      name: data.name,
      description: data.description,
      dateUpdated: data.lastUpdated,
      status: data.status,
      dateCreated: data.dateCreated,
      profitMargin: data.profitMargin,
      cashDownPercent: data.cashDownPercent,
      globalDefault: data.globalDefault,
      createdCognitoID: data.createdCognitoID,
      dateExpired: data.dateExpired,
      lastUpdatedCognitoID: data.lastUpdated,
      overridesIDS: data.overridesIDS,
      scopes: mapDataUtility(data),
    },
  };
  } catch(e: any){
    toast.error(`Error while fetching pricing rule ${e.message}`);
    throw new Error("Error while fetching pricing rule");
  }
};

export const addOrUpdatePricingRules = async (rule: PricingRule): Promise<Scope[]> => {
  const existingScopes = rule.scopes;

  // Asset Classes should be unique
  const assetClassesToIdMap = new Map<string, string>();
  existingScopes.forEach(({ id, assetClasses }) => {
    assetClasses?.forEach(assetClass => {
      if (assetClassesToIdMap.has(assetClass)) {
        throw new Error(`Asset Classes should be unique! Rules with IDs '${id}' and '${assetClassesToIdMap.get(assetClass)}' share the same '${assetClass}' Asset Class`); // 400 Bad Request
      }
      assetClassesToIdMap.set(assetClass, id);
    });
  });
  // check with other existing rules
  existingScopes.filter(existingRule => !rule.scopes.find(updatingRule => updatingRule.id === existingRule.id))
    .forEach(({ id, assetClasses }) => {
      assetClasses?.forEach(assetClass => {
        if (assetClassesToIdMap.has(assetClass)) {
          throw new Error(`Asset Classes should be unique! Rules with ID '${assetClassesToIdMap.get(assetClass)}' uses '${assetClass}' Asset Class already used by existing rule with ID '${id}'`); // 400 Bad Request
        }
        assetClassesToIdMap.set(assetClass, id);
      });
    });

  // Rule ID should exist
  // const nonExistentRule = rules.find(updatingRule => !existingRules.find(existingRule => existingRule.id === updatingRule.id));
  // if (nonExistentRule) {
  //   throw new Error(`Rule with id ${nonExistentRule.id} not found`); // 400 Bad Request
  // }

  /* API call 
    1st parse the data using UI to API mapper
    2nd call the API with the data
  */
  const data = mapUiToApi(
    {
      globalDefault: true,
      cashDownPercent: rule.cashDownPercent, 
      overridesIDS: [],
      name: rule.name,
      description: rule.description,
      profitMargin: rule.profitMargin,
      scopes: rule.scopes,
      status: rule.status,
    }
  );
  try{
    if(rule.id.length === 0){
      const response = await restAPIForJSON<{rule: Rule}>('POST', PRICING_RULE, {json: data});
      if(rule.status === "Active"){
        await restAPIForJSON('PATCH', `${PRICING_RULE}/${response.rule.pricingRuleID}`, {json: {
          status: "Active"
        }});
      }  
    }
    else{
      const response = await restAPIForJSON('PATCH', `${PRICING_RULE}/${rule.id}`, {json: data});
      console.log(response);
    }
  } catch(e: any){
    toast.error(`Error while updating pricing rule: ${e.message}`);
    throw new Error("Error while updating pricing rule");
  }
  return rule.scopes;
};

//Hold this for now as deleting one rule is not a good idea rather we can update the rule with new values
export const deletePricingRule = async (id: string): Promise<string | undefined> => {
  if(id.length === 0) return undefined;
  try{
    const deletedRule = await restAPIForJSON<{rule: Rule}>('DELETE', `${PRICING_RULE}/${id}`);
    console.log(deletedRule)
    if (!deletedRule) {
      throw new Error(`Rule with id ${id} not found`); // 400 Bad Request
    }
    return deletedRule.rule.pricingRuleID;
  } catch(e: any) {
    toast.error(`Error while Deleting Pricing Rule ${e.message}`);
    throw new Error("Error while Deleting Pricing Rule");
  }
};

const mapDataUtility = (data: any) => {
  return [...data.rules.map((r: Rules) => {
    return {
      id: r.id,
      assetClasses: r.assetClasses,
      maobCalculation: {
        ltvThreshold: r.maobCalculation.ltvThreshold ?? 0,
        whenLessOrEqual: {
          id: r.maobCalculation.whenLess?.id?.toString() ?? '',
          selectLesserOf: r.maobCalculation.whenLess?.selectLesserOf ?? false,
          aaoPerc: r.maobCalculation.whenLess?.aaoPerc ?? 0,
          aivPerc: r.maobCalculation.whenLess?.aivPerc ?? 0,
          upbPerc: r.maobCalculation.whenLess?.upbPerc ?? 0,
        },
        whenGreater: {
          id: r.maobCalculation.whenGreaterOrEqual?.id?.toString() ?? '',
          selectLesserOf: r.maobCalculation.whenGreaterOrEqual?.selectLesserOf ?? false,
          aaoPerc: r.maobCalculation.whenGreaterOrEqual?.aaoPerc ?? 0,
          aivPerc: r.maobCalculation.whenGreaterOrEqual?.aivPerc ?? 0,
          upbPerc: r.maobCalculation.whenGreaterOrEqual?.upbPerc ?? 0,
        },
      },
      offers: r.offers.map((offer) => {
        return {
          id: offer.id,
          daysToClose: offer.daysToClose,
          discountPerc: offer.discountPerc,
          daysForDueDiligence: offer.daysForDueDiligence
        }
      }
    )
}})];
}