/** @type {import('tailwindcss').Config} */
export const darkMode = ['class'];
export const content = ['./pages/**/*.{ts,tsx}', './components/**/*.{ts,tsx}', './app/**/*.{ts,tsx}', './src/**/*.{ts,tsx}'];
import { Config } from 'tailwindcss';
import tailwindcssAnimate from 'tailwindcss-animate';
import defaultTheme from 'tailwindcss/defaultTheme';

import flowbitePlugin from 'flowbite/plugin';

export const prefix = '';
export const theme = {
  container: {
    center: true,
    padding: '2rem',
    screens: {
      '2xl': '1400px',
    },
  },
  extend: {
    boxShadow: {
      hover: '1px 3px 24px -10px hsl(var(--card))',
    },
    colors: {
      selectBlue: 'hsl(var(--filter-select))',
      filterContainerFooter: 'hsl(var(--filter-container-footer))',
      selectedChip: 'hsl(var(--selected-chips))',
      filterSelect: 'hsl(var(--filter-select))',
      filterContainer: 'hsl(var(--filter-container))',
      filterBox: 'hsl(var(--filter-box))',
      gradientPink: 'hsl(var(--button-gradient-pink))',
      gradientPurple: 'hsl(var(--button-gradient-purple))',
      gradientBlue: 'hsl(var(--button-gradient-blue))',
      border: 'hsl(var(--border))',
      input: 'hsl(var(--input))',
      ring: 'hsl(var(--ring))',
      background: 'hsl(var(--background))',
      dash: {
        DEFAULT: 'hsl(var(--dash))',
        foreground: 'hsl(var(--foreground))',
      },
      foreground: 'hsl(var(--foreground))',
      primary: {
        DEFAULT: 'hsl(var(--primary))',
        foreground: 'hsl(var(--primary-foreground))',
      },
      secondary: {
        DEFAULT: 'hsl(var(--secondary))',
        foreground: 'hsl(var(--secondary-foreground))',
      },
      destructive: {
        DEFAULT: 'hsl(var(--destructive))',
        foreground: 'hsl(var(--destructive-foreground))',
      },
      muted: {
        DEFAULT: 'hsl(var(--muted))',
        foreground: 'hsl(var(--muted-foreground))',
      },
      accent: {
        DEFAULT: 'hsl(var(--accent))',
        foreground: 'hsl(var(--accent-foreground))',
      },
      popover: {
        DEFAULT: 'hsl(var(--popover))',
        foreground: 'hsl(var(--popover-foreground))',
      },
      card: {
        DEFAULT: 'hsl(var(--card))',
        secondary: 'hsl(var(--card-secondary))',
        foreground: 'hsl(var(--card-foreground))',
        lender: 'hsl(var(--card-lender))',
      },
      textbox: {
        DEFAULT: 'hsl(var(--textbox))',
        foreground: 'hsl(var(--foreground))',
      },
      'hot-deals': {
        DEFAULT: 'hsl(var(--hot-deals))',
        foreground: 'hsl(var(--hot-deals-foreground))',
      },
      'active-deals': {
        DEFAULT: 'hsl(var(--active-deals))',
      },
      'completed-deals': {
        DEFAULT: 'hsl(var(--completed-deals))',
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      customGrayEAEAEA: '#EAEAEA',
      customGrayA9A9A9: "#A9A9A9",
      customGray656A72: "#656A72",
      customGrayA1A3A7: "#A1A3A7",
      customGrayB9B9B9: "#B9B9B9",
      customGray929DAD: "#929DAD",
      customGrayF8F6F6: "#F8F6F6",
      customGray686D75: "#686D75",
      customDark131923: '#131923',
      customBlack1D2634: "#1D2634",
      customYellowD7E71F: '#D7E71F',
      customBlue7B9ACE: '#7B9ACE',
      customBlue08A1E2: "#08A1E2",
    },
    fontFamily: {
      sans: 'Space Grotesk, sans-serif',
      headings: 'Work Sans, sans-serif',
      nav: 'Inter, sans-serif',
    },
    borderRadius: {
      lg: 'var(--radius)',
      md: 'calc(var(--radius) - 2px)',
      sm: 'calc(var(--radius) - 4px)',
    },
    keyframes: {
      'accordion-down': {
        from: { height: '0' },
        to: { height: 'var(--radix-accordion-content-height)' },
      },
      'accordion-up': {
        from: { height: 'var(--radix-accordion-content-height)' },
        to: { height: '0' },
      },
      slideIn: {
        '0%': { transform: 'translateX(100%)', opacity: '0' },
        '100%': { transform: 'translateX(0)', opacity: '0.8' },
      },
    },
    animation: {
      'slide-in': 'slideIn 0.5s ease-out forwards',
      'accordion-down': 'accordion-down 0.2s ease-out',
      'accordion-up': 'accordion-up 0.2s ease-out',
    },
    height: {
      13.5: '3.375rem',
      17: '4.25rem',
      18: '4.5rem',
    },
    width: {
      17: '4.25rem',
    },
    padding: {
      18: '4.5rem',
    },
    fontSize: {
      // sm: '1.23rem',
      '6xl': '4rem',
      '4xl': '2rem',
    },
    screens: {
      xxs: '320px',
      xs: '375px',
      ...defaultTheme.screens,
      '3xl': '1750px',
      '4xl': '1850px',
    },
  },
};

export const plugins = [tailwindcssAnimate, flowbitePlugin];

export default {
  darkMode: 'selector',
  content: ['./src/**/*.{js,ts,jsx,tsx}', './node_modules/flowbite/**/*.js'],
  theme,
  plugins,
} satisfies Config;