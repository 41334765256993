import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


interface LoadingModalProps {
    open: boolean;
    message: string;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ message, open }) => {

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            
            <DialogContent className="flex justify-center items-center h-40">
                <DialogContentText id="alert-dialog-description" className="text-sm font-normal text-center">
                    {message}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default LoadingModal;