import { motion } from 'framer-motion';
import React from 'react';

interface LoaderProps {
  message: string;
}

const Loader: React.FC<LoaderProps> = ({ message }) =>
(
  <motion.div
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[999999]"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <motion.div
      className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center"
      initial={{ scale: 0.8 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", stiffness: 100 }}
    >
      <motion.div
        className="w-10 h-10 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
      />
      <p className="mt-3 text-lg font-medium text-gray-700">
        {message}
      </p>
    </motion.div>
  </motion.div>
);

export default Loader;