import React from 'react';
import Title from './Title';

export enum TitlePosition {
    LEFT = "left",
    CENTER = "center",
    RIGHT = "right",
}

interface PanelProps {
    id?: string;
    title: string;
    titlePosition?: TitlePosition;
    children: React.ReactNode;
    className?: string;
    toolbar?: React.ReactNode;
}

const Panel:React.FC<PanelProps> = ({id, title, titlePosition = TitlePosition.LEFT, children, className, toolbar }) => {
  return (
    <div id={`${id}`} className={`px-4 md:px-6 py-3 bg-white rounded-xl border border-gray-300 ${className}`}>
        <div className={`my-3 ${toolbar ? "flex justify-between" :""}`}>
            <Title className={`text-${titlePosition} `}>{title}</Title>
            {toolbar && toolbar}
        </div>
        {children}
    </div>
  )
}

export default Panel;
