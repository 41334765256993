// src/components/common/NumberDisplay.tsx
import React from 'react';

interface NumberDisplayProps {
  topNumber: any;
  bottomNumber: any;
  className?: string;
}

const NumberDisplay: React.FC<NumberDisplayProps> = ({ topNumber, bottomNumber, className }) => {
  return (
    <div className="text-center text-base font-medium">
      <div className={`${className}`}>{topNumber}</div>
      <div className={`border-t-[2px] border-black ${className}`}>{bottomNumber}</div>
    </div>
  );
};

export default NumberDisplay;