// @ts-ignore
import { point } from '@turf/turf';

export interface CarouselCardViewModel {
    assetClass: string;
    totalmarketvalue: number;
    totalUPB: number;
    estimatedAssetValue: number;
    estimatedPrincipalBalance: number;
    locations: DealLocationProps[];
    lenderName: string;
    offerRange: RangeValuesProps;
    assetValue: RangeValuesProps;
    lenderDefaultRatePercentage: number;
    lenderDefaultRateVolume: RangeValuesProps;
    lenderDefaultRateAmount: RangeValuesProps;
    taxLien: string;
    totalTaxLiens: number;
    totalHoaLiens: number;
    totalVolume: RangeValuesProps;
    totalAmount: RangeValuesProps;
    countOfAssetClassMix: Record<string, number>;
    countOfBorrower: Record<string, number>;
    estimatedPurchasePrice: number;
    firstLienPositionOriginationAmount: number;
    estimated_mortgage_balance: number;
    totalPropertiesMarketvalue: number;
    profitPercentage: number;
    totalOfferPrice: number;
    TotalDealFirstLienPositionOriginationAmount: number;
    totalProperties: number;
    opportunityData: OpportunityData;
}

export interface DealLocationProps {
    coordinates: point;
    address: string;
    estimatedReturn: number;
    status: 'hot' | 'active' | 'completed';
    type: 'Commercial' | 'Residential';
}

export class RangeValuesProps {
    min: number;
    max: number;
    percentage: number;

    constructor(minAmount: number, maxAmount: number) {
        this.min = minAmount;
        this.max = maxAmount;
        this.percentage = this.max > 0 ? (this.min / this.max) * 100 : 0;
    }
}

export interface OpportunityData {
    opportunityMAOS: number;
    adjustedOpportunityMAOS: number;
    lastUpdated: string;
    dateCreated: string;
    opportunityLeveragesMAOS: OpportunityLeveragesMAOS;
    opportunityCriteriaID: string;
    lenderID: string;
    opportunityProperties: OpportunityDataProperties[];
    status: string;
    lastUpdatedCognitoID: string;
    totalProperties: number;
    source: string;
    premiumPercentMAOS: number;
    opportunityMAOB: number;
    adjustedOpportunityMAOB: number;
    opportunityAAO: number;
    opportunityAIV: number;
    opportunityCOCR: number;
    opportunityLeveragesMAOB: OpportunityLeveragesMAOB;
    discountPercentMAOB: number;
    dateExpired: string;
    opportunityID: string;
    opportunityUPB: number;
    createdCognitoID: string;
    lenderName: string;
    notes: string;
}

export interface OpportunityLeveragesMAOS {
    AIV: number;
    UPB: number;
    AAO: number;
}

export interface OpportunityLeveragesMAOB {
    AIV: number;
    UPB: number;
    AAO: number;
}

export interface OpportunityDataProperties {
    netProfitMargin: number;
    COCR: number;
    MAOB: number;
    leveragesMAOB: OpportunityLeveragesMAOB;
    leveragesMAOS: OpportunityLeveragesMAOS;
    UPB: number;
    pricingRuleID: string;
    MAOS: number;
    propertyID: string;
    sourceDataIDs?: string[];
    AIV: number;
    AAO: number;
    adjustedMAOB: number;
}