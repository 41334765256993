import { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../ui/dialog';
import { Plus } from 'lucide-react';

interface RuleDialogProps {
    heading?: string;
    defaultRuleName?: string;
    defaultRuleDescription?: string;
    onOpenChange: () => void;
    onCreateClick: (name: string, description: string) => void;
}

const RuleDialog:React.FC<RuleDialogProps> = ({heading, defaultRuleName, defaultRuleDescription, onOpenChange, onCreateClick}) => {

    const [RuleName, setRuleName] = useState(defaultRuleName || "New Rule");
    const [ruleDescription, setRuleDescription] = useState(defaultRuleDescription || "");

    const handleSaveClick = () => {
        onCreateClick(RuleName, ruleDescription);
        onOpenChange();
    };
  return (
    <>
    <Dialog open={true} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen flex flex-col p-5"> {/* border-0 p-5 outline-none */}
        <DialogHeader>
          <DialogTitle>{heading}</DialogTitle>
        </DialogHeader>
        {/* <DialogDescription className='flex flex-row justify-between'>
          
        </DialogDescription> */}
        <div className="p-4">
            {/* Task Name Input */}
            <h2 className="my-3 text-base font-normal">Name:<span className="text-red-600">*</span></h2>
            <input
              type="text"
              value={RuleName}
              onChange={(e) => setRuleName(e.target.value)}
              className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 w-[30vw]"
              required
            />
            {/* <p className="italic text-xs mt-1 text-gray-500">
              File name is required*
            </p> */}
            <h2 className="my-3 text-base font-normal">Description</h2>
            <textarea
              placeholder="Optional description for future reference"
              value={ruleDescription}
              onChange={(e) => setRuleDescription(e.target.value)}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        <DialogFooter >
          <div className={`flex w-full flex-row justify-end`}>
        <button
            className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400"
            onClick={handleSaveClick}
          >
            <Plus className="h-4 w-4" />
            Create
          </button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    </>
  )
}

export default RuleDialog;
