import { Copy, Play, Trash2, WandSparkles } from 'lucide-react';
import FlowGrid, { CellData, FlowGridNode } from './ComponentsGrid';
import { GlobalParameters, Scope, SellerOffer } from './models/PricingRules';
import { AssetClassesStage } from './components/stages/AssetClassesStage';
import { useEffect, useState } from 'react';
import { MAOBCalculationStage } from './components/stages/MAOBCalculationStage';
import { SellerOffersStage } from './components/stages/SellerOffersStage';
import CheckRuleDialog from './components/CheckRuleDialog';
import ConfirmDialog from '../dashboard/pages/global-pricing-rule/components/ConfirmDialog';
import WizardDialog from './components/WizardDialog';
import { EditablePercentBox } from './components/stages/EditablePercentBox';

// interface PricingRuleChanges {
//   id?: string;
//   assetClasses?: string[];
//   'maobCalculation.ltvThreshold'?: number;
//   'maobCalculation.whenLessOrEqual.selectLesserOf'?: boolean;
//   'maobCalculation.whenLessOrEqual.aaoPerc'?: number;
//   'maobCalculation.whenLessOrEqual.aivPerc'?: number;
//   'maobCalculation.whenLessOrEqual.upbPerc'?: number;
//   'maobCalculation.whenGreater.selectLesserOf'?: boolean;
//   'maobCalculation.whenGreater.aaoPerc'?: number;
//   'maobCalculation.whenGreater.aivPerc'?: number;
//   'maobCalculation.whenGreater.upbPerc'?: number;
//   'offers.length'?: number;
//   'offers[0].daysToClose'?: number;
//   'offers[0].discountPerc'?: number;
//   'offers[0].daysForDueDiligence'?: number;
//   'offers[1].daysToClose'?: number;
//   'offers[1].discountPerc'?: number;
//   'offers[1].daysForDueDiligence'?: number;
//   'offers[2].daysToClose'?: number;
//   'offers[2].discountPerc'?: number;
//   'offers[2].daysForDueDiligence'?: number;
//   'maosCalculation.profitMargin'?: number;
// }

export const guessedMastAssetClassList: string[] = ['Residential', 'Commercial', 'Industrial', 'Multi-family'];

const columns: string[] = [
  'Asset Classes',
  'LTV Threshold',
  'MAOB Calculation',
  'Offers',
  // "MAOS Calculation",
];

export type ExistingOrNewScope = Omit<Scope, 'id'> & { id?: string };

interface PricingRuleFlowGridProps {
  scopes?: ExistingOrNewScope[];
  globalParameters?: GlobalParameters;
  /**
   * Provides an optional reference of the persisting rules,
   * to determine unsaved changes in `rules`.
   *
   * When skipped, the `rules` are considered to be the latest
   * and persisted set.
   */
  lastSavedScope?: Scope[];
  /**
   * Called when the Rule Base is modified by either:
   * 1. Adding new rules (present in `changes` with absent `id`s and all fields/properties)
   * 2. Changing rules (present in `changes` with valid `id`s and only changed fields/properties)
   * 3. Deleting rules (absent in `changes`)
   *
   * Handlers can identify the changes by comparing with
   * the source of `lastSavedRules`, if any.
   */
  onChange?: (changedValue: ExistingOrNewScope[]) => void;
  onGlobalParameterChange?: (profitMargin: GlobalParameters) => void;
  onDelete?: (...references: { id?: string; rowIndex: number }[]) => void;
  onAdd?: (...data: Omit<Scope, 'id'>[]) => void;
  /**
   * Called with the final changed rules:
   * 1. New rules will not have the `id` field
   * 2. Changed rules will have the `id` field
   * 3. Deleted rules will be absent
   *
   * Handlers can identify the changes and deletions by comparing
   * with the source of `lastSavedRules`, if any.
   *
   * Changes can be tracked by listening to the `onChange` handler.
   */
  onSave?: (scopes: (Scope | Omit<Scope, 'id'>)[]) => void;
  /**
   * Default message to show if there is no data to render in specific situation like if there is no active rule or there are no rules at all
   */
  defaultMessage?:string;
}

const PricingRuleFlowGrid = ({ scopes, globalParameters, lastSavedScope = scopes as Scope[] | undefined, onChange, onGlobalParameterChange, onAdd, defaultMessage }: PricingRuleFlowGridProps) => {
  const [modifiedScopes, setModifiedScopes] = useState<ExistingOrNewScope[] | undefined>();
  useEffect(() => {
    // if(modifiedRules)
    onChange?.(modifiedScopes || []);
  },[modifiedScopes])

  const [data, setData] = useState<(FlowGridNode | CellData[])[][] | undefined>();
  const [testScope, setTestScope] = useState<ExistingOrNewScope | undefined>();
  const [modifiedGlobalParameters, setModifiedGlobalParameters] = useState<GlobalParameters | undefined>();
  useEffect(() => {
    onGlobalParameterChange?.(modifiedGlobalParameters || globalParameters || {profitMargin: 0, cashDownPercent: 0});
  },[modifiedGlobalParameters]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [scopeToDelete, setScopeToDelete] = useState<{rowIndex: number, ruleId: string} | undefined>();
  const [openWizardSteps, setOpenWizardSteps] = useState<boolean>(false);
  const [scopeToEditUsingWizard, setScopeToEditUsingWizard] = useState<{rowIndex: number, ruleId: string} | undefined>();
  const [tempScopeForWizard, setTempScopeForWizard] = useState<ExistingOrNewScope | undefined>();

  useEffect(() => {
    // Update available asset classes
    const usedAssetClasses = new Set(modifiedScopes?.flatMap((rule) => rule.assetClasses || []));
    const allAssetClasses = new Set([
      ...guessedMastAssetClassList,
      ...usedAssetClasses, // make sure that all used Asset Classes are also in the list!
    ]);
    const unusedAssetClasses = [...allAssetClasses].filter((assetClass) => !usedAssetClasses.has(assetClass));

    // Grid Data
    setData(
      modifiedScopes?.map((scope, scopeIndex) => [
        // 1. Asset Classes
        {
          data: scope.assetClasses,
          renderer: () => (
            <AssetClassesStage
              values={scope.assetClasses || []}
              availableForUse={unusedAssetClasses || []}
              onValueChanged={(values) => {
                console.log(`Asset Class selection changed from ${scope.assetClasses} to ${values}`);

                setModifiedScopes((prevScopes) => {
                  prevScopes = prevScopes || scopes;
                  return prevScopes?.map((r, index) => {
                    console.log(r);
                    if (index === scopeIndex) {
                      // if (r.id)
                      //   onChange?.({
                      //     id: r.id,
                      //     assetClasses: values,
                      //   });
                      // Return a new rule object with assetClasses updated
                      return {
                        ...r,
                        assetClasses: values,
                      };
                    }
                    return r;
                  });
                });
              }}
            />
          ),
        } as FlowGridNode,

        // 2. LTV Threshold
        {
          data: scope.maobCalculation.ltvThreshold,
          renderer: () => (
            <EditablePercentBox
              title='LTV Threshold'
              defaultValue={lastSavedScope?.find((r) => r.id === scope.id)?.maobCalculation.ltvThreshold}
              value={scope.maobCalculation.ltvThreshold}
              onChange={(value) => {
                console.log(`LTV Threshold changed from ${scope.maobCalculation.ltvThreshold} to ${value}`);

                setModifiedScopes((prevScopes) => {
                  prevScopes = prevScopes || scopes;
                  return prevScopes?.map((r, index) => {
                    if (index === scopeIndex) {
                      // if (r.id)
                      //   onChange?.({
                      //     id: r.id,
                      //     'maobCalculation.ltvThreshold': value,
                      //   });
                      // Return a new rule object with maobCalculation.ltvThreshold updated
                      return {
                        ...r,
                        maobCalculation: {
                          ...r.maobCalculation,
                          ltvThreshold: value,
                        },
                      };
                    }
                    return r;
                  });
                });
              }}
            />
          ),
        } as FlowGridNode,

        // 3. MAOB Calculation
        [
          // 3.1 When Less Than Or Equal To LTV Threshold
          {
            data: 'whenLessOrEqual',
            renderer: () => (
              <MAOBCalculationStage
                className="bg-teal-50"
                ltvThreshold={scope.maobCalculation.ltvThreshold}
                lessThenOrEqualLTVThreshold={true}
                defaultValue={lastSavedScope?.find((r) => r.id === scope.id)?.maobCalculation.whenLessOrEqual}
                value={scope.maobCalculation.whenLessOrEqual}
                onValueChanged={(value) => {
                  console.log(
                    `Low Risk Properties MAOB operator changed from ${scope.maobCalculation.whenLessOrEqual ? 'Lesser Of' : 'Greater Of'} to ${value ? 'Lesser Of' : 'Greater Of'}`,
                  );

                  setModifiedScopes((prevScopes) => {
                    prevScopes = prevScopes || scopes;
                    return prevScopes?.map((r, index) => {
                      if (index === scopeIndex) {
                        // if (r.id) {
                        //   const changes: PricingRuleChanges = { id: r.id };
                        //   if (r.maobCalculation.whenLessOrEqual.selectLesserOf != value.selectLesserOf) {
                        //     changes['maobCalculation.whenLessOrEqual.selectLesserOf'] = value.selectLesserOf;
                        //   }
                        //   if (r.maobCalculation.whenLessOrEqual.aaoPerc != value.aaoPerc) {
                        //     changes['maobCalculation.whenLessOrEqual.aaoPerc'] = value.aaoPerc;
                        //   }
                        //   if (r.maobCalculation.whenLessOrEqual.aivPerc != value.aivPerc) {
                        //     changes['maobCalculation.whenLessOrEqual.aivPerc'] = value.aivPerc;
                        //   }
                        //   if (r.maobCalculation.whenLessOrEqual.upbPerc != value.upbPerc) {
                        //     changes['maobCalculation.whenLessOrEqual.upbPerc'] = value.upbPerc;
                        //   }
                        //   onChange?.(changes);
                        // }
                        // Return a new rule object with maobCalculation updated
                        return {
                          ...r,
                          maobCalculation: { ...r.maobCalculation, whenLessOrEqual: value },
                        };
                      }
                      return r;
                    });
                  });
                }}
              />
            ),
          } as FlowGridNode,
          // 3.2 When Greather Than LTV Threshold
          {
            data: 'whenGreater',
            renderer: () => (
              <MAOBCalculationStage
                ltvThreshold={scope.maobCalculation.ltvThreshold}
                lessThenOrEqualLTVThreshold={false}
                defaultValue={lastSavedScope?.find((r) => r.id === scope.id)?.maobCalculation.whenGreater}
                value={scope.maobCalculation.whenGreater}
                onValueChanged={(value) => {
                  console.log(
                    `High Risk Properties MAOB operator changed from ${scope.maobCalculation.whenGreater ? 'Lesser Of' : 'Greater Of'} to ${value ? 'Lesser Of' : 'Greater Of'}`,
                  );

                  setModifiedScopes((prevScopes) => {
                    prevScopes = prevScopes || scopes;
                    return prevScopes?.map((r, index) => {
                      if (index === scopeIndex) {
                        // const changes: PricingRuleChanges = { id: r.id };
                        // if (r.maobCalculation.whenGreater.selectLesserOf != value.selectLesserOf) {
                        //   changes['maobCalculation.whenGreater.selectLesserOf'] = value.selectLesserOf;
                        // }
                        // if (r.maobCalculation.whenGreater.aaoPerc != value.aaoPerc) {
                        //   changes['maobCalculation.whenGreater.aaoPerc'] = value.aaoPerc;
                        // }
                        // if (r.maobCalculation.whenGreater.aivPerc != value.aivPerc) {
                        //   changes['maobCalculation.whenGreater.aivPerc'] = value.aivPerc;
                        // }
                        // if (r.maobCalculation.whenGreater.upbPerc != value.upbPerc) {
                        //   changes['maobCalculation.whenGreater.upbPerc'] = value.upbPerc;
                        // }
                        // onChange?.(changes);
                        // Return a new rule object with maobCalculation updated
                        return {
                          ...r,
                          maobCalculation: { ...r.maobCalculation, whenGreater: value },
                        };
                      }
                      return r;
                    });
                  });
                }}
              />
            ),
          } as FlowGridNode,
        ],

        // 4. Offers: sub-table
        {
          data: scope.offers,

          renderer: () => (
            <SellerOffersStage
              defaultValue={lastSavedScope?.find((r) => r.id === scope.id)?.offers}
              value={scope.offers}
              onChange={(value) => {
                console.log('Seller Offers changed to ', value);

                setModifiedScopes((prevScopes) => {
                  prevScopes = prevScopes || scopes;
                  return prevScopes?.map((r, index) => {
                    if (index === scopeIndex) {
                      // const changes: PricingRuleChanges = { id: r.id };
                      // [0, 1, 2].forEach((i) => {
                      //   if (i !== 0 && i !== 1 && i !== 2) return;
                      //   if (r.offers[i]?.daysToClose != value[i]?.daysToClose) {
                      //     changes[`offers[${i}].daysToClose`] = value[i]?.daysToClose;
                      //   }
                      //   if (r.offers[i]?.discountPerc != value[i]?.discountPerc) {
                      //     changes[`offers[${i}].discountPerc`] = value[i]?.discountPerc;
                      //   }
                      //   if (r.offers[i]?.daysForDueDiligence != value[i]?.daysForDueDiligence) {
                      //     changes[`offers[${i}].daysForDueDiligence`] = value[i]?.daysForDueDiligence;
                      //   }
                      // });
                      // onChange?.(changes);
                      // Return a new rule object with maobCalculation updated
                      return {
                        ...r,
                        offers: [...value],
                      };
                    }
                    return r;
                  });
                });
              }}
            />
          ),
        } as FlowGridNode,

        /*5. MAOS Calculation
        {
          data: rule.maosCalculation,
        renderer: () => <MAOSCalculationStage
          defaultValue={lastSavedRules?.find(r => r.id === rule.id)?.maosCalculation}
          value={rule.maosCalculation}
          onChange={value => {
            console.log(`MAOS Calculation changed from ${rule.maosCalculation} to ${value}`);

            setModifiedRules(prevRules => {
              prevRules = prevRules || rules;
              return prevRules?.map(r => {
                if (r.id === rule.id) {
                  const changes: PricingRuleChanges = { id: r.id };
                  if (r.maosCalculation.profitMargin != value.profitMargin) {
                    changes["maosCalculation.profitMargin"] = value.profitMargin;
                  }
                  onChange?.(changes);
                  // Return a new rule object with maobCalculation.ltvThreshold updated
                  return {
                    ...r,
                    maosCalculation: { ...value },
                  };
                }
                return r;
              });
            });
          }}
        />,
        } as FlowGridNode,
*/
        // 6. Actions
        {
          data: scope.id,
          renderer: (ruleId: string, rowIndex: number) => (
            <>
              <div className="flex gap-1 pb-2">
                <button title="Remove this scope" className="text-red-600 hover:text-red-800" onClick={() => {
                  // handleDelete(rowIndex, ruleId)
                  setShowDeleteDialog(true);
                  setScopeToDelete({rowIndex: rowIndex, ruleId: ruleId});
                  }}>
                  <Trash2 size="1em" />
                </button>
                <button title="Check this scope with sample values" className="text-blue-600 hover:text-blue-800" onClick={() => handleRun(rowIndex, ruleId)}>
                  <Play size="1em" />
                </button>
              </div>
              <div className="flex gap-1">
                <button title="Duplicate this scope" className="text-blue-600 hover:text-blue-800" onClick={() => handleDuplicate(rowIndex, ruleId)}>
                  <Copy size="1em" />
                </button>
                <button title="Edit this scope with Wizard" className={`text-blue-600 hover:text-blue-800 `} onClick={() => {
                  setTempScopeForWizard({...scope});
                  setOpenWizardSteps(true);
                  setScopeToEditUsingWizard({rowIndex: rowIndex, ruleId: ruleId});
                  }}>
                  <WandSparkles size="1em" />
                </button>
              </div>
            </>
          ),
        } as FlowGridNode,
      ]),
    );
  }, [modifiedScopes]);

  useEffect(() => {
    const newModifiedScopes = scopes || lastSavedScope;
    // check and ensure that asset classes are unique and not reused (remove duplicates)
    const alreadyUsedAssetClasses: Set<string> = new Set();
    newModifiedScopes?.map((scope) => {
      if (!scope.assetClasses?.length) return scope;

      let updatedAssetClasses: string[] = [];

      // Automatically fix if rule has duplicate Asset Classes
      const uniqueAssetClasses: Set<string> = new Set(modifiedScopes?.flatMap((scope) => scope.assetClasses || []));
      if (scope.assetClasses.length !== uniqueAssetClasses.size) {
        updatedAssetClasses = Array.from(uniqueAssetClasses);
        console.warn(`Sanitizing rule ${scope.id} with duplicate Asset Classes from ${scope.assetClasses} to ${updatedAssetClasses}`);
      }

      // Automatically fix if rule uses Asset Classes already used
      if (scope.assetClasses.find((assetClass) => alreadyUsedAssetClasses.has(assetClass))) {
        updatedAssetClasses = scope.assetClasses.filter((assetClass) => !alreadyUsedAssetClasses.has(assetClass));
        console.warn(`Sanitizing rule ${scope.id} with reused Asset Classes from ${scope.assetClasses} to ${updatedAssetClasses}`);
      }

      // let changes: PricingRuleChanges | undefined = undefined;
      // const changedRule = { ...rule };

      // if (updatedAssetClasses.length != rule.assetClasses.length) {
      //   changes = {
      //     id: rule.id,
      //     assetClasses: updatedAssetClasses,
      //   };
      //   changedRule.assetClasses = updatedAssetClasses;
      // }

      // // Automatically restrict the maximum seller offers to 3 only
      // if (rule.offers.length > 3) {
      //   console.warn(`Sanitizing rule ${rule.id} with ${rule.offers.length} Seller Offers, keeping only first 3`);
      //   changes = changes || { id: rule.id };
      //   changes['offers.length'] = 3;
      //   changedRule.offers = [...changedRule.offers.slice(0, 3)];
      // }

      // if (changes) {
      //   // onChange?.(changes);
      //   return changedRule;
      // }

      return scope;
    });

    setModifiedScopes(newModifiedScopes);
  }, [scopes, lastSavedScope]);

  const wizardSteps = [
    {
      name: 'Asset Classes',
      component: (
        <div>
          <AssetClassesStage
            values={tempScopeForWizard?.assetClasses || []}
            //available asset classes will be guessed - tempRuleForWizard?.assetClasses
            availableForUse={guessedMastAssetClassList.filter((assetClass) => !tempScopeForWizard?.assetClasses?.includes(assetClass))}
            onValueChanged={(value) => setTempScopeForWizard((prev) => (prev ? { ...prev, assetClasses: value } : prev))}
          />
        </div>
      ),
    },
    {
      name: 'LTV Threshold',
      component: (
        <div>
          <EditablePercentBox
          title='LTV Threshold'
            value={tempScopeForWizard?.maobCalculation.ltvThreshold}
            onChange={(value) =>
              setTempScopeForWizard((prev) => (
                prev ? {
                ...prev,
                maobCalculation: {
                  ...prev?.maobCalculation,
                  ltvThreshold: value
                }
              }
              : prev))
            }
          />
        </div>
      ),
    },
    {
      name: 'MOAB Calculation',
      component: (
        <div className="flex flex-col justify-between gap-4 px-10">
          <MAOBCalculationStage
            ltvThreshold={tempScopeForWizard?.maobCalculation.ltvThreshold || 0}
            defaultValue={tempScopeForWizard?.maobCalculation.whenLessOrEqual}
            value={tempScopeForWizard?.maobCalculation.whenLessOrEqual}
            lessThenOrEqualLTVThreshold={true}
            onValueChanged={(value) => setTempScopeForWizard((prev) => (prev ? { ...prev, maobCalculation: { ...prev.maobCalculation, whenLessOrEqual: value } } : prev))}
          />
          <MAOBCalculationStage
            ltvThreshold={tempScopeForWizard?.maobCalculation.ltvThreshold || 0}
            defaultValue={tempScopeForWizard?.maobCalculation.whenGreater}
            value={tempScopeForWizard?.maobCalculation.whenGreater}
            lessThenOrEqualLTVThreshold={false}
            onValueChanged={(value) =>
              setTempScopeForWizard((prev) => (prev ? { ...prev, maobCalculation: { ...prev.maobCalculation, whenGreater: value }} : prev))
            }
          />
        </div>
      ),
    },
    {
      name: 'Seller Offers',
      component: (
        <div>
          <SellerOffersStage
            defaultValue={tempScopeForWizard?.offers}
            value={tempScopeForWizard?.offers}
            onChange={(value: SellerOffer[]) =>
              setTempScopeForWizard((prev) =>
                prev
                  ? {
                      ...prev,
                      offers: value,
                    }
                  : prev,
              )
            }
            className='m-2'
          />
        </div>
      ),
    },
  ];

  const handleDelete = (rowIndex: number, scopeId?: string) => {
    console.log(`Delete Scope[${scopeId || 'NEW'}] at row index ${rowIndex}`);
    // onDelete?.({ id: ruleId, rowIndex });
    setModifiedScopes((prevRules) => prevRules?.filter((_rule, i) => i !== rowIndex));
  };

  const handleRun = (rowIndex: number, scopeId?: string) => {
    console.log(`Run rule[${scopeId || 'NEW'}] at row index ${rowIndex}`);
    setTestScope(modifiedScopes?.[rowIndex]);
  };
  const handleDuplicate = (rowIndex: number, ScopeId?: string) => {
    console.log(`Duplicate rule[${ScopeId || 'NEW'}] at row index ${rowIndex}`);
    if (modifiedScopes) {
      const { id, assetClasses, ...duplicatePartial } = modifiedScopes[rowIndex];
      setModifiedScopes((prevRules) => [...(prevRules || []), duplicatePartial]);
      onAdd?.(duplicatePartial);
    }
  };
  const handleEditWizard = (rowIndex: number, scopeId?: string) => {
    console.log(`Edit with Wizard rule[${scopeId || 'NEW'}] at row index ${rowIndex}`);
    //edit the tempRuleForWizard in the modifiedRules
    setModifiedScopes((prevScopes) => {
      if (prevScopes) {
        return prevScopes.map((r, i) => {
          if (i === rowIndex) {
            return tempScopeForWizard || r;
          }
          return r;
        });
      }
      return prevScopes;
    });
    setTempScopeForWizard(undefined);
  };

  return (
    <div className="h-full w-full flex flex-row justify-between overflow-y-hidden">
      <div className="overflow-y-scroll w-full">
        <FlowGrid columns={columns} data={data} noDataRenderer={() => (
          <div className='p-4'>
            {defaultMessage?.length ? defaultMessage : "No Data"}
          </div>
        )}/>
      </div>
      <div className="flex flex-col pl-2">
        <thead className="sticky top-0 opacity-75 w-full flex">
          <tr className="text-sm w-full flex">
            <th className='w-full'> 
              <div className="whitespace-nowrap border w-full border-gray-400 bg-gray-100 p-1 text-center shadow-xl">Global Parameters</div>
            </th>
          </tr>
        </thead>

        <tbody className='flex h-full justify-center items-center'>
          <tr className={`border-b last:border-0`}>
            <td className={`flex gap-2 flex-col items-center justify-center h-full py-1`}>
              {
                <>
                <EditablePercentBox
                title='Cash Down Percent'
                  defaultValue={globalParameters?.cashDownPercent}
                  value={modifiedGlobalParameters?.cashDownPercent}
                  onChange={(value) => {
                    console.log(`Financial Matrix changed from ${globalParameters?.cashDownPercent} to ${value}`);
                    setModifiedGlobalParameters({profitMargin: modifiedGlobalParameters?.profitMargin || globalParameters?.profitMargin || 0, cashDownPercent: value});

                  }}
                />
                <EditablePercentBox
                title='Profit Margin'
                  defaultValue={globalParameters?.profitMargin}
                  value={modifiedGlobalParameters?.profitMargin}
                  onChange={(value) => {
                    console.log(`Financial Matrix changed from ${globalParameters?.profitMargin} to ${value}`);
                    setModifiedGlobalParameters({profitMargin: value, cashDownPercent: modifiedGlobalParameters?.cashDownPercent||globalParameters?.cashDownPercent||0});
                  }}
                />
                </>
              }
              {/* {data?.renderCell(cell, rowIndex, colIndex, row, 0)} */}
            </td>
          </tr>
        </tbody>
      </div>
      {testScope && <CheckRuleDialog rule={testScope} profitMargin={modifiedGlobalParameters || globalParameters || {profitMargin: 0, cashDownPercent: 0}} onClose={() => setTestScope(undefined)} />}
        {showDeleteDialog && scopeToDelete && (
          <ConfirmDialog 
          open={showDeleteDialog} 
          title='Please Confirm' 
          message='Do you want to delete the Scope?' 
          handleClose={() => {
            setShowDeleteDialog(false);
            setScopeToDelete(undefined);
          }} 
          handleConfirm={() => {
            handleDelete(scopeToDelete.rowIndex, scopeToDelete.ruleId);
            setShowDeleteDialog(false);
            setScopeToDelete(undefined);
          }}  />
        )}

      {openWizardSteps && (
        <WizardDialog 
        title='Add New Scope Using Wizard'
        steps={wizardSteps}
        onOpenChange={() => setOpenWizardSteps(false)} 
        onSaveClick={() => {
          setOpenWizardSteps(false);
          handleEditWizard(scopeToEditUsingWizard?.rowIndex || 0, scopeToEditUsingWizard?.ruleId);
        }}
        />
      )}
    </div>
  );
};

export default PricingRuleFlowGrid;

// const areScopesEqual = (scopes: Scope[]|undefined, modifiedScopes: ExistingOrNewScope[]|undefined): boolean => {
//   const removeId = (scope: Scope | ExistingOrNewScope) => {
//     const { id, ...rest } = scope;
//     return JSON.stringify(rest);
//   };

//   if(!scopes)return false;
//   if(!modifiedScopes) return false;

//   const sortedScopes = scopes.map(removeId).sort();
//   const sortedModifiedScopes = modifiedScopes.map(removeId).sort();

//   return JSON.stringify(sortedScopes) === JSON.stringify(sortedModifiedScopes);
// };