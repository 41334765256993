import React from "react";
import { ActivityEvent } from "./models/Timeline";
import ActivityItem from "./ActivityItem";

interface ActivityLogProps {
  activities: ActivityEvent[] | null; // `null` indicates loading state
  isLoading: boolean;
  onRefresh: () => void; // Callback to refresh the logs
}

const ActivityLog: React.FC<ActivityLogProps> = ({ activities, onRefresh }) => {

  return (
    <div className="relative">
      {/* Refresh Button */}
      <div className="flex justify-end items-center mb-4">
        <button
          onClick={onRefresh}
          className="apply-new-query-button"        >
            Refresh
        </button>
      </div>

      {/* Content */}
      { !activities || activities.length === 0 ? (
        <div className="flex justify-center items-center p-4">
          <p className="thin-title text-xs uppercase">
          No activities to display.
          </p>
        </div>
      ) : (
        <ol className="relative border-s border-gray-200">
          {activities.map((activity, index) => (
            <ActivityItem
              key={index}
              user={activity.user}
              changes={activity.changes}
              description={activity.description}
              timestamp={activity.timestamp}
              type={activity.type}
            />
          ))}
        </ol>
      )}
    </div>
  );
};

export default ActivityLog;