import UnCircleCheckIcon from '@/components/icons/UnCircleCheckIcon';
import { entryType, gender, partyType } from '@/constants/ThirdPartyConstants';
import { Checkbox } from '@radix-ui/react-checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import Select from 'react-select';
import { Mail, Phone, CalendarIcon } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';
import { OptionType } from './DealsDesktop';
import { addNewThirdPartyProfile, ContactInfo, getThirdPartyProfile, ThirdPartyProfile, updateThirdPartyProfile } from './services/dealService';
import { useAuth } from '@/components/AuthProvider';
import { restAPIForJSON } from '@/services/RestCommon';
import { ADD_BORROWER_DETAILS_API_URL } from '@/config/config';
import { toast } from 'react-toastify';
import LoadingModal from '../../common/LoadingModal';
import { PropertyProps } from './Property.interface';
import TimezoneSelect from 'react-timezone-select';
import "./DealsDesktop.css"

interface EditBorrowerDialogProps {
    openBorrowDialog: boolean
    setBorrowingDialog?: (e: boolean) => void
    borrowerName: string
    property: PropertyProps;
    opportunityID: string;
    totalProperty: number;
    borrowerThirdPartyId : string
    updateBorrowerDetails ?: (property : PropertyProps, borrowerThirdPartyID  :string)=>void
    setIsUpdated : (p: boolean)=>void
    isUpdated:boolean
};

function EditBorrowerDialog({isUpdated,setIsUpdated, updateBorrowerDetails, totalProperty, openBorrowDialog, setBorrowingDialog, property, opportunityID, borrowerThirdPartyId, borrowerName }: EditBorrowerDialogProps) {
    const auth = useAuth();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSubmitting] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [thirdParty, setThirdParty] = useState<ThirdPartyProfile>(
        {
            contactInfo: [
              {
                firstName: '',
                lastName: '',
                name: '',
                email: '',
                gender: '',
                phone: '',
                address1: '',
                city: '',
                state: '',
                postalCode: '',
                timezone: '',
                tags: [],
                primary: true,
                sync_error: []
              },
            ],
            dbas: [],
            enity_type: [],
            other_names: [],
            party_name: borrowerName,
            party_type: ["Borrower"],
            permissions: [],
            entity_registration_date: moment(new Date()).format('MM/DD/YYYY')
          }
    );

    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const addContact = () => {
        setThirdParty(prevThirdParty => ({
          ...prevThirdParty,
          contactInfo: [
            ...prevThirdParty.contactInfo,
            {
              firstName: '',
              lastName: '',
              name: '',
              email: '',
              gender: '',
              phone: '',
              address1: '',
              city: '',
              state: '',
              postalCode: '',
              timezone: '',
              tags: [],
              primary: prevThirdParty.contactInfo.length == 0,
              sync_error: []
            }
          ]
        }));
      };

    const removeContact = (index: number) => {
        if (thirdParty.contactInfo.length > 1) {
            setThirdParty(prevThirdParty => ({
                ...prevThirdParty,
                contactInfo: [
                    ...prevThirdParty.contactInfo.filter((_, i) => i !== index),
                ]
            }));
        }
    };

    const onSelectChange = (selectedOption: OptionType[], type: string) => {
        const options: string[] = selectedOption
            .filter(d => d.value !== undefined) // Ensures only defined values
            .map(d => d.value as string);
        if (type === 'dbas') {
            setThirdParty(prevThirdParty => ({
                ...prevThirdParty,
                dbas: options
            }));
        }
        if (type === 'enity_type') {
            setThirdParty(prevThirdParty => ({
                ...prevThirdParty,
                enity_type: options
            }));
        }
        if (type === 'party_type') {
            setThirdParty(prevThirdParty => ({
                ...prevThirdParty,
                party_type: options
            }));
        }
    };

    const handleContactChange = (index: number, field: keyof ContactInfo, value: string | boolean) => {
        const updatedContacts = [...thirdParty.contactInfo];
        if (field === 'primary' && value === true) {
            updatedContacts.forEach((contact) => (contact.primary = false));
        }
        updatedContacts[index][field] = value as string & boolean & object & any;
        setThirdParty(
            prevThirdParty => ({
                ...prevThirdParty,
                contactInfo: updatedContacts
            })
        );
    };

    const validateForm = () => {
        let newErrors: { [key: string]: string } = {};

        if (!thirdParty.party_name.trim()) {
            newErrors.party_name = "Third Party Name is required.";
        }

        thirdParty.contactInfo.forEach((contact, index) => {
            if (!contact.firstName.trim()) {
                newErrors[`contactInfo-${index}-firstName`] = "First Name is required.";
            }
            if (!contact.lastName.trim()) {
                newErrors[`contactInfo-${index}-lastName`] = "Last Name is required.";
            }
            if (!contact.email.trim() || !/\S+@\S+\.\S+/.test(contact.email)) {
                newErrors[`contactInfo-${index}-email`] = "Valid Email is required.";
            }
            if (!contact.phone) {
                newErrors[`contactInfo-${index}-phone`] = "Phone number is required";
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onThirdPartySave = async (opportunityId: string, totalProperties: number) => {
        if (!validateForm()) {
            return;
        }
        try {
            if (!auth?.sub) throw new Error("Invalid session! Please retry");
            let thirdPartyId = thirdParty.partyID 
            if(thirdParty.partyID){
                setLoadingMessage("Updating borrower details...");
                // ['lastUpdatedCognitoID', 'createdAt', 'updatedAt', 'createdCognitoID']
                const { partyID, lastUpdatedCognitoID, createdAt, updatedAt, createdCognitoID, ...data} = thirdParty;
                data.contactInfo.forEach(p => p.name = `${p.firstName} ${p.lastName}`)
                await updateThirdPartyProfile(thirdParty.partyID,"", totalProperties, data , false)
            }else{
                setLoadingMessage("Saving borrower details...");
                const newBorrower = await addNewThirdPartyProfile("", totalProperties, thirdParty, false)
                thirdPartyId = newBorrower.partyID
                const query = {
                    cognitoID: auth!.sub,
                    opportunity_id: opportunityId,
                    total_properties: totalProperties,
                    property_id: property.id,
                    third_party_id: thirdPartyId
                }
                
                
                await restAPIForJSON('POST', ADD_BORROWER_DETAILS_API_URL,
                    {
                        query
                    });
            }
            setBorrowingDialog?.(false)
            updateBorrowerDetails?.(property, thirdPartyId ?? "")
            setIsUpdated(!isUpdated)
            toast.success("Borrower details saved successfully");
        } catch (ex) {
            toast.error("Failed to save borrower details");
        }
        finally {
            setLoadingMessage(null);
        }
    }

    const fetchBorrowerDetail = async (borrowerThirdPartyId: string) => {
        setLoadingMessage("Loading borrower details ...")
        let borrower = await getThirdPartyProfile(borrowerThirdPartyId);
        setThirdParty(borrower)
        setLoadingMessage(null)
    }

    useEffect(()=>{
        if(borrowerThirdPartyId) fetchBorrowerDetail(borrowerThirdPartyId)
    }, [])

    useEffect(() => {
        validateForm();
    }, [thirdParty]);

    return (
        <>
        <LoadingModal open={(loadingMessage != null)} message={loadingMessage || ""} />
        <Dialog open={openBorrowDialog} onOpenChange={setBorrowingDialog}>
            <DialogContent className="max-w-screen h-[80%] w-[50%] overflow-x-hidden border-0 p-0 outline-none flex flex-col">
                <DialogHeader>
                    <div className="mb-5 flex min-h-[54px] w-full items-center bg-[#3B4F72] text-xl text-white">
                        <div className="ps-7">
                            <DialogTitle className="space-y-14 text-xl font-bold ">Edit Borrower Details</DialogTitle>
                        </div>
                    </div>
                </DialogHeader>
                <div className="flex-grow overflow-y-auto p-4">
                <div className="icon-element mb-3 flex items-center gap-2">
                  <label className="after:content-['*'] after:text-red-500 after:ml-1">Third Party Name:</label>
                  <input type="text" placeholder="Enter Third Party Name" id="party_name"
                    value={thirdParty.party_name}
                    style={{ border: errors[`party_name`] ? "1px solid red" : "" }}
                    onChange={(e) => setThirdParty(prevThirdParty => ({
                      ...prevThirdParty,
                      party_name: e.target.value
                    }))}
                    className="icon-input-box w-full" />
                </div>
                {/* Contanct Info */}
                <div className="mb-3">
                  <div className="flex">
                    <h2 className="mb-2 flex-1 text-lg font-semibold">
                      Contact Info
                    </h2>
                    <span onClick={addContact} className="block cursor-pointer text-sm font-medium text-blue-600">
                      + Add Contact
                    </span>
                  </div>
                  {thirdParty.contactInfo.map((contact, index) => {
                    return (
                      <div className="icon-element-sectoin relative mb-3" key={index}>
                        {thirdParty.contactInfo.length > 1 && (
                          <div className="absolute right-0 top-0 cursor-pointer" onClick={() => removeContact(index)}>
                            <UnCircleCheckIcon color="black" width={18} height={18} />
                          </div>
                        )}

                        <div className="grid grid-cols-2 gap-4">
                          {/* First Name */}
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label className="after:content-['*'] after:text-red-500 after:ml-1">First Name:</label>
                            <input
                              type="text"
                              placeholder="Enter First Name"
                              id={`first-name-${index}`}
                              value={contact.firstName || ""}
                              className="icon-input-box flex-1"
                              style={{ border: errors[`contactInfo-${index}-firstName`] ? "1px solid red" : "" }}
                              onChange={(e) => handleContactChange(index, 'firstName', e.target.value)}
                            />
                          </div>
                          {/* Last Name */}
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label className="after:content-['*'] after:text-red-500 after:ml-1">Last Name:</label>
                            <input
                              type="text"
                              placeholder="Enter Last Name"
                              id={`last-name-${index}`}
                              value={contact.lastName || ""}
                              className="icon-input-box flex-1"
                              style={{ border: errors[`contactInfo-${index}-lastName`] ? "1px solid red" : "" }}
                              onChange={(e) => handleContactChange(index, 'lastName', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {/* Email */}
                          <div className="grid mt-1 mb-1 grid-cols-[100px_auto] items-center gap-2 relative">
                            <label className="after:content-['*'] after:text-red-500 after:ml-1">Email:</label>
                            <input
                              type="email"
                              placeholder="Enter Email"
                              id={`email-${index}`}
                              value={contact.email || ""}
                              className="icon-input-box flex-1 w-full pr-10"
                              style={{ border: errors[`contactInfo-${index}-email`] ? "1px solid red" : "" }}
                              onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                            />
                            <button
                              type="button"
                              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-blue-500"
                            >
                              <Mail size={16} />
                            </button>
                          </div>

                          {/* Phone */}
                          <div className="grid mt-1 mb-1 grid-cols-[100px_auto] items-center gap-2 relative">
                            <label className="after:content-['*'] after:text-red-500 after:ml-1">Phone:</label>
                            <input
                              type="tel"
                              placeholder="Enter Phone Number"
                              id={`phone-${index}`}
                              value={contact.phone || ""}
                              className="icon-input-box flex-1 w-full pr-10"
                              style={{ border: errors[`contactInfo-${index}-phone`] ? "1px solid red" : "" }}
                              onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
                            />
                            <button
                              type="button"
                              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-blue-500"

                            >
                              <Phone size={16} />
                            </button>
                          </div>
                          {/* <div className="icon-element-sectoin relative mb-3" data-id={index} key={index}> */}
                          {/* {thirdParty.addresses.length > 1 && (
                          <div className="absolute right-0 top-0 cursor-pointer" onClick={() => removeAddress(index)}>
                            <UnCircleCheckIcon color="black" width={18} height={18} />
                          </div>
                        )} */}



                        </div>
                        <div className="grid grid-cols-2 gap-4">
                        <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label htmlFor="gender">Gender : </label>
                            <Select
                              id="gender"
                              isClearable
                              options={gender}
                              onChange={(selectedOption) => handleContactChange(index, 'gender', selectedOption ? selectedOption.value : '')}
                              value={gender.find((option: any) => option.value === contact.gender) || null}
                              className="icon-multi-select-box"
                              classNamePrefix="select"
                              // noOptionsMessage={() => 'No Party Type available'}
                              placeholder="Select Gender"
                            />
                          </div>
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label htmlFor="timeZone">Timezone : </label>
                            <TimezoneSelect
                              className='timezone-picker'
                              value={contact.timezone || currentTimezone}
                              onChange={(selected) => handleContactChange(index, 'timezone', selected.value)}
                            />
                          </div>
                        </div>
                       
                        <div className="grid grid-cols-2 gap-4">
                          <div className="grid mb-0 grid-cols-[100px_auto] items-center gap-2">
                            <label>Street Address:</label>
                            <input
                              type="text"
                              placeholder="Enter Address"
                              id={`address-${index}`}
                              value={contact.address1}
                              className="icon-input-box flex-1 mb-2"
                              onChange={(e) => handleContactChange(index, 'address1', e.target.value)}
                            />
                          </div>
                          <div className="grid mb-0 grid-cols-[100px_auto] items-center gap-2">
                            <label>City:</label>
                            <input
                              type="text"
                              placeholder="Enter City"
                              id={`city-${index}`}
                              value={contact.city}
                              className="icon-input-box flex-1 mb-2"
                              onChange={(e) => handleContactChange(index, 'city', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label>State:</label>
                            <input
                              type="text"
                              placeholder="Enter State"
                              id={`state-${index}`}
                              value={contact.state}
                              className="icon-input-box flex-1"
                              onChange={(e) => handleContactChange(index, 'state', e.target.value)}
                            />
                          </div>
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label>Zipcode:</label>
                            <input
                              type="text"
                              placeholder="Enter Zipcode"
                              id={`zipCode-${index}`}
                              value={contact.postalCode}
                              className="icon-input-box flex-1"
                              onChange={(e) => handleContactChange(index, 'postalCode', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="grid mt-1 col-span-4 grid-cols-[100px_auto] items-center gap-2">
                            <label>Tags:</label>
                            <CreatableSelect
                              id="tags"
                              isMulti
                              isClearable
                              options={[]}
                              value={contact.tags || []}
                              className="icon-multi-select-box"
                              isSearchable={true}
                              classNamePrefix="select"
                              onChange={(selectedOption: any) => handleContactChange(index, 'tags', selectedOption)}
                              placeholder="Add new tags"
                              noOptionsMessage={() => 'Add new tags'}
                            />
                          </div>
                        </div>
                        {thirdParty.contactInfo.length > 1 &&
                          (<div className="flex items-center space-x-2">
                            <Checkbox
                              checked={contact.primary}
                              id={`primary-${index}`}
                              className="h-[18px] w-[18px] border-2 border-black data-[state=checked]:bg-transparent"
                              onCheckedChange={(e) => handleContactChange(index, 'primary', e)}
                            />
                            <div className="grid gap-1.5 leading-none">
                              <label
                                htmlFor={`primary-${index}`}
                                className="text-sm leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-neutral-400"
                              >
                                Primary
                              </label>
                            </div>
                          </div>)
                        }
                      </div>
                    );
                  })}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                    <label htmlFor="dbas">DBAS:</label>
                    <CreatableSelect
                      id="dbas"
                      isMulti
                      isClearable
                      options={[]}
                      value={thirdParty.dbas.map((e: string) => ({ label: e, value: e })) || []}
                      className="icon-multi-select-box"
                      isSearchable={true}
                      classNamePrefix="select"
                      onChange={(value: any) => onSelectChange(value, 'dbas')}
                      placeholder="Create new DBAS"
                      noOptionsMessage={() => 'No DBAS available'}
                    />
                  </div>
                  <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                    <label htmlFor="entryType">Entry Type:</label>
                    <Select
                      id="entryType"
                      isMulti
                      isClearable
                      options={entryType}
                      onChange={(value: any) => onSelectChange(value, 'enity_type')}
                      value={entryType.filter((entry: { value: string }) => thirdParty.enity_type.includes(entry.value)) || []}
                      className="icon-multi-select-box"
                      isSearchable={true}
                      classNamePrefix="select"
                      placeholder="Select Entry Type"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                    <label htmlFor="partyType">Party Type:</label>
                    <Select
                      id="partyType"
                      isMulti
                      isClearable
                      options={partyType}
                      onChange={(value: any) => onSelectChange(value, 'party_type')}
                      value={partyType.filter((party: { value: string }) => thirdParty.party_type.includes(party.value)) || []}
                      className="icon-multi-select-box"
                      isSearchable={true}
                      classNamePrefix="select"
                      noOptionsMessage={() => 'No Party Type available'}
                      placeholder="Select Party Type"
                    />
                  </div>
                  <div className="grid grid-cols-[100px_auto] items-center gap-2">
                    <label htmlFor="registrationDate">Registration Date:</label>
                    <DatePicker
                      id="registrationDate"
                      showIcon
                      selected={thirdParty.entity_registration_date ? new Date(thirdParty.entity_registration_date) : new Date()}
                      onChange={(date: any) => setThirdParty(prevThirdParty => ({
                        ...prevThirdParty,
                        entity_registration_date: date
                      }))}
                      icon={<CalendarIcon />}
                      maxDate={new Date()}
                      className="icon-input-box icon-date-picker"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
              </div>
                <DialogFooter className="relative my-1 bottom-0 flex w-full flex-col items-center !justify-center py-1">
                    {/* Button Container */}
                    <div className="relative flex w-full justify-center">
                        {/* Error Message (Top-Right) */}
                        <button
                            type="submit"
                            onClick={() => onThirdPartySave(opportunityID, totalProperty)}
                            // onClick={() => setBorrowingDialog?.(false)}
                            disabled={isSubmitting || Object.keys(errors).length > 0}
                            data-tooltip-content={`Missing mandatory fields: ${Object.values(errors).join(", ")}`}
                            className={`${isSubmitting || Object.keys(errors).length > 0 ? 'disabled:cursor-not-allowed disabled:opacity-50' : ''} mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700`}
                        >
                            Submit
                        </button>
                        <button
                            disabled={isSubmitting}
                            type="button"
                            className="mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                            onClick={() => setBorrowingDialog?.(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
        </>
    )
}
// function EditBorrowerDialog({isUpdated,setIsUpdated, updateBorrowerDetails, totalProperty, openBorrowDialog, setBorrowingDialog, borrowerName, property, opportunityID, borrowerThirdPartyId }: EditBorrowerDialogProps){
//     return <></>
// }

export default EditBorrowerDialog

