export interface Rule {
  pricingRuleID: string;
  cashDownPercent: number;
  name: string;
  description: string;
  createdCognitoID: string;
  dateCreated: string; // ISO timestamp
  dateExpired: string; // ISO timestamp
  globalDefault: boolean;
  lastUpdated: string; // ISO timestamp
  lastUpdatedCognitoID: string;
  netProfitMargin: number;
  overridesIDS: string[];
  scopes: PricingScope[];
  status: string; // Example: "Active", "Inactive", etc.
}

interface PricingScope {
  scopeID: string;
  conditions: PricingCondition[];
  createdCognitoID: string;
  dateCreated: string; // ISO timestamp
  dateExpired: string; // ISO timestamp
  lastUpdated: string; // ISO timestamp
  lastUpdatedCognitoID: string;
  qualificationCriteria: string; // JSON string representing search criteria
  windows: PricingWindow[];
  status: string; // Example: "Approved", "Pending", etc.
}

interface PricingCondition {
  conditionID: string;
  createdCognitoID: string;
  dateCreated: string; // ISO timestamp
  dateExpired: string; // ISO timestamp
  lastUpdated: string; // ISO timestamp
  lastUpdatedCognitoID: string;
  pricingFactors: PricingFactor[];
  pricingStrategyID: string; // Example: "MIN", "MAX", etc.
  qualificationCriteria: string; // JSON string representing search criteria
  status: string; // Example: "Active", "Inactive", etc.
}

interface PricingFactor {
  attribute: string; // Example: "AIV", "UPB", "AAO"
  value: number; // Percentage or multiplier value
}

interface PricingWindow {
  windowID: string;
  closingWindowDays: number;
  createdCognitoID: string;
  dateCreated: string; // ISO timestamp
  dateExpired: string; // ISO timestamp
  discountPercent: number;
  dueDiligenceDays: number;
  lastUpdated: string; // ISO timestamp
  lastUpdatedCognitoID: string;
  status: string; // Example: "Active", "Inactive", etc.
}
interface Offer {
  id: string;
    daysForDueDiligence: number;
    daysToClose: number;
    discountPerc: number;
  }
  
  interface MaobCalculation {
    ltvThreshold?: number;
    whenGreaterOrEqual?: {
      id: string;
      aaoPerc: number;
      aivPerc: number;
      selectLesserOf: boolean;
      upbPerc: number;
    };
    whenLess?: {
      id: string;
      aaoPerc: number;
      aivPerc: number;
      selectLesserOf: boolean;
      upbPerc: number;
    };
  }
  
  interface MaosCalculation {
    profitMargin: number;
  }
  
  export interface Rules {
    assetClasses: string[];
    createdCognitoID: string;
    dateCreated: string;
    dateExpired: string;
    id: string;
    lastUpdated: string;
    lastUpdatedCognitoID: string;
    maobCalculation: MaobCalculation;
    maosCalculation: MaosCalculation;   //profit Margin
    offers: Offer[];
    status: string;
  }
  

export interface RuleBase {
  id: string;
  profitMargin: number;
  globalDefault: boolean;
  cashDownPercent: number;
  createdCognitoID: string;
  dateCreated: string;
  dateExpired: string;
  lastUpdated: string;
  lastUpdatedCognitoID: string;
  name: string;
  description: string;
  overridesIDS: string[];
  rules: Rules[];
  status: string; // Example: "Active", "Inactive", etc.
};

const mapRule = (scope: PricingScope, profitMargin: number) => {
  const getAssetClasses = (qualificationCriteria: string) => {
    try {
      // Parse the stringified JSON
      const parsedObject = JSON.parse(qualificationCriteria);
      // Extract the array of terms
      const shouldArray = parsedObject?.bool?.should || [];

      // Extract values associated with `assetClassGroup.keyword`
      const values = shouldArray.map((item: any) => item?.term?.['assetClassGroup.keyword']).filter((value: any) => value !== undefined); // Filter out undefined values
      // const values = shouldArray.map((item: any) => item?.['assetClassGroup.keyword']);
      return values;
    } catch (error) {
      console.error('Error parsing JSON or extracting values:', error);
    }
  };

  const mapMaobCalculation = (conditions: PricingCondition[]) => {
    const qualificationCriteria = conditions[0]?.qualificationCriteria;

    const jsonObject = JSON.parse(qualificationCriteria);

    function extractComparator(condition: PricingCondition): string {
      const qualificationCriteria = JSON.parse(condition.qualificationCriteria);
      const rangeObject = qualificationCriteria?.bool?.must?.[0]?.range?.firstLienPositionMortgageLTV;
      return Object.keys(rangeObject)[0];
    }

    function extractThresholdValue(jsonObject: any): number | null {
      try {
        const rangeObject = jsonObject?.bool?.must?.[0]?.range?.firstLienPositionMortgageLTV;
        if (!rangeObject) return null;
        const key = Object.keys(rangeObject).find((key) => ['lte', 'lt', 'gt', 'gte'].includes(key));

        return key ? rangeObject[key] : null;
      } catch (error) {
        console.error('Error extracting value:', error);
        return null;
      }
    }

    const thresholdValue = extractThresholdValue(jsonObject) ?? 0;

    function mapCondition(condition: PricingCondition) {
      const selectLesserOf = condition.pricingStrategyID == 'MIN';

      const aiv = condition.pricingFactors.find((a) => a.attribute == 'AIV')!.value;
      const aao = condition.pricingFactors.find((a) => a.attribute == 'AAO')!.value;
      const upb = condition.pricingFactors.find((a) => a.attribute == 'UPB')!.value;

      return {
        id: condition.conditionID,
        selectLesserOf: selectLesserOf,
        aaoPerc: aao,
        aivPerc: aiv,
        upbPerc: upb,
      };
    }

    let whenLess, whenGreaterOrEqual;

    whenLess = conditions.find((condition) => extractComparator(condition).includes('lt'));
    whenGreaterOrEqual = conditions.find((condition) => extractComparator(condition).includes('gt'));

    if (whenLess) whenLess = mapCondition(whenLess);

    if (whenGreaterOrEqual) whenGreaterOrEqual = mapCondition(whenGreaterOrEqual);

    return {
      ltvThreshold: thresholdValue,
      whenLess: whenLess,
      whenGreaterOrEqual: whenGreaterOrEqual,
    };
  };

  const mapOffers = (offers: PricingWindow[]): Offer[] => {
    const mapOffer = (offer: PricingWindow): Offer => {
      return {
        id: offer.windowID,
        daysToClose: offer.closingWindowDays,
        discountPerc: offer.discountPercent,
        daysForDueDiligence: offer.dueDiligenceDays,
      };
    };

    return offers.map((offer) => mapOffer(offer));
  };

  const mapMaosCalculation = (profitMargin: number) => {
    return {
      profitMargin: profitMargin,
    };
  };

  return {
    id: scope.scopeID,
    dateCreated: scope.dateCreated,
    dateExpired: scope.dateExpired,
    lastUpdated: scope.lastUpdated,
    lastUpdatedCognitoID: scope.lastUpdatedCognitoID,
    createdCognitoID: scope.createdCognitoID,
    assetClasses: getAssetClasses(scope.qualificationCriteria),
    maobCalculation: mapMaobCalculation(scope.conditions),
    offers: mapOffers(scope.windows),
    maosCalculation: mapMaosCalculation(profitMargin),
    status: scope.status,
  };
};

export const mapRuleBase = (ruleBase: Rule): RuleBase => {
  const scopes = ruleBase.scopes;

  return {
    id: ruleBase.pricingRuleID,
    profitMargin: ruleBase.netProfitMargin,
    globalDefault: ruleBase.globalDefault,
    cashDownPercent: ruleBase.cashDownPercent,
    createdCognitoID: ruleBase.createdCognitoID,
    dateCreated: ruleBase.dateCreated,
    dateExpired: ruleBase.dateExpired,
    lastUpdated: ruleBase.lastUpdated,
    lastUpdatedCognitoID: ruleBase.lastUpdatedCognitoID,
    name: ruleBase.name,
    description: ruleBase.description,
    overridesIDS: ruleBase.overridesIDS,
    rules: scopes?.map((scope) => mapRule(scope, ruleBase.netProfitMargin)),
    status: ruleBase.status,
  };
};
