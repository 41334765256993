import React from "react";
import { Edit, DollarSign, Upload, MessageSquareText, FileQuestion, ShieldCheck } from "lucide-react";
import { ActivityEvent, EventType } from "./models/Timeline";
import ActivityActionItems from "./ActivityActionItems";


const ActivityItem: React.FC<ActivityEvent> = ({
  user,
  description,
  changes,
  timestamp,
  type
}) => {
  // Icon mapping based on the activityType type
  const renderTypeIcon = () => {
    switch (type) {
      case EventType.ModifyProperty:
        return <Edit className="text-blue-600 w-4 h-4" />;
      case EventType.RePricing:
        return <DollarSign className="text-yellow-500 w-4 h-4" />;
      case EventType.ModifiedOpportunity:
        return <Edit className="text-green-500 w-4 h-4" />;
      case EventType.UploadedDocument:
          return <Upload className="text-gray-500 w-4 h-4" />;
      case EventType.Comment:
          return <MessageSquareText className="text-gray-500 w-4 h-4" />;
      case EventType.GetPermissions:
        return <ShieldCheck  className="text-gray-500 w-4 h-4"/>
      case EventType.Unkonwn:
      default:
        return <FileQuestion className="text-gray-500 w-4 h-4" />;
    }
  };

  return (
    <li className="mb-10 ms-6">
      <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white">
        {renderTypeIcon()}
      </span>
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
        <div className="items-center justify-between mb-3 sm:flex">
          <time className="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">
            {timestamp.toLocaleString()} {/* Formatted time */}
          </time>
          <div className="flex items-center">
            <span className="ml-2 flex text-sm">{user.name}</span> <p className="ml-2 flex text-sm font-semibold">{description}</p>
          </div>
        </div>
        {changes && changes.length > 0 && (
          <div className="p-3 italic text-gray-500 border border-gray-200 rounded-lg bg-gray-50">
            {changes.map((detail, index) => (
              <ActivityActionItems label={detail.type} newValue={detail.newvalue} oldValue={detail.oldValue} key={index}/>
            ))}
          </div>
        )}
      </div>
    </li>
  );
};

export default ActivityItem;