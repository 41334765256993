import { formatToPerc, formatToUSDCurrency } from "@/lib/utils";
import { ArrowRight } from "lucide-react";
import { ChangeType } from "./models/Timeline";

const formatToUSDCurrencyStrHandled = (v : string | number | null) => typeof v === "string" ? isNaN(parseFloat(v)) ? v : formatToUSDCurrency(parseFloat(v)) : formatToUSDCurrency(v);

const formatToPercStrHandled = (v : string | number | null) => typeof v === "string" ? isNaN(parseFloat(v)) ? v : formatToPerc(parseFloat(v)) : formatToPerc(v);

const formatJsonStringify = (v : any) => {
  if (typeof v === "object" && v !== null) {
    const formattedString = JSON.stringify(v, null, 2);
    return (
      <pre className="whitespace-pre-wrap text-sm text-gray-800">
        {formattedString}
      </pre>
    );
  }
  return v;

}

export const changeTypes: ChangeType[] = [
  { jsonValue: "opportunityMAOB", displayValue: "MAOB", format: formatToUSDCurrencyStrHandled },
  { jsonValue: "opportunityMAOS", displayValue: "MAOS", format: formatToUSDCurrencyStrHandled },
  { jsonValue: "opportunityAAO", displayValue: "AAO", format: formatToUSDCurrencyStrHandled },
  { jsonValue: "opportunityAIV", displayValue: "AIV", format: formatToUSDCurrencyStrHandled },
  { jsonValue: "opportunityUPB", displayValue: "UPB", format: formatToUSDCurrencyStrHandled },
  { jsonValue: "opportunityCOCR", displayValue: "COCR", format: formatToUSDCurrencyStrHandled },
  { jsonValue: "adjustedOpportunityMAOB", displayValue: "adjustedMAOB", format: formatToUSDCurrencyStrHandled },
  { jsonValue: "adjustedOpportunityMAOS", displayValue: "adjustedMAOS", format: formatToUSDCurrencyStrHandled },
  { jsonValue: "dicountPercentMAOB", displayValue: "dicountMAOB", format: formatToPercStrHandled },
  { jsonValue: "opportunityAAO", displayValue: "dicountMAOB", format: formatToPercStrHandled },
  { jsonValue: "opportunityNetProfitMargin", displayValue: "NetProfitMargin", format: formatToPercStrHandled },
  { jsonValue: "premiumPercentMAOS", displayValue: "MAOSPermium", format: formatToPercStrHandled },
  { jsonValue: "opportunityLeveragesMAOB", displayValue: "OpportunityLeveragesMAOB", format: formatJsonStringify },
  { jsonValue: "opportunityLeveragesMAOS", displayValue: "OpportunityLeveragesMAOS", format: formatJsonStringify },
  { jsonValue: "pricing_windows", displayValue: "PricingWindows", format: formatJsonStringify },
  { jsonValue: "properties", displayValue: "Properties", format: formatJsonStringify },
];

export function getDisplayValue(jsonValue: string): ChangeType {
  const found = changeTypes.find(item => item.jsonValue === jsonValue);
  if(!found) return { jsonValue:jsonValue, displayValue:jsonValue};
  return found;
}

const ActivityActionItems: React.FC<{ label: string, oldValue: any, newValue: any }> = ({ label, oldValue, newValue }) => {
  return (
    <div className="flex items-center space-x-3 text-gray-800">
      <strong className="text-sm font-semibold">{label}:</strong>
      <span className="text-sm text-gray-900">{oldValue}</span>
      <ArrowRight className="text-sm text-gray-900" size={20} />
      <span className="text-sm italic text-gray-900">{newValue}</span>
    </div>
  );
};

export default ActivityActionItems;