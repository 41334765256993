import React from "react";

interface BannerProps {
  text?: string;
  color?: string;
}

const Banner: React.FC<BannerProps> = ({ text = "NEW", color = "#FFC107" }) => {
  return (
    <div
      className="inline-block px-2 py-1 rounded-lg w-50 text-white font-bold text-md"
      style={{
        backgroundColor: color,
        display: "inline-block"
      }}
    >
      {text}
    </div>
  );
};

export default Banner;