import { useEffect, useState } from "react";
import { formatToDays, formatToPerc, formatToUSDCurrency } from "@/lib/utils";
import { ExistingOrNewScope } from "../PricingRuleFlowGrid";
import { GlobalParameters } from "../models/PricingRules";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../ui/dialog';

interface CheckRuleDialogProps {
  rule: ExistingOrNewScope;
  profitMargin: GlobalParameters;
  onClose: () => void;
}

const CheckRuleDialog: React.FC<CheckRuleDialogProps> = ({
  rule,
  profitMargin,
  onClose,
}) => {
  console.log(rule);

  const [aaoInput, setAAOInput] = useState(400000);
  const [aivInput, setAIVInput] = useState(450000);
  const [upbInput, setUPBInput] = useState(300000);

  interface RunOutput {
    ltv: number;
    maob: number;
    maos: number;
    offers: {
      daysToClose: number;
      discountPerc: number;
      daysForDueDiligence: number;
    }[];
  }

  const [runOutput, setRunOutput] = useState<RunOutput>();

  useEffect(() => {

    const ltv = aaoInput / aivInput;
    const maob = ltv <= rule.maobCalculation.ltvThreshold
      ? rule.maobCalculation.whenLessOrEqual.selectLesserOf
        ? Math.min(
          rule.maobCalculation.whenLessOrEqual.aaoPerc * aaoInput,
          rule.maobCalculation.whenLessOrEqual.aivPerc * aivInput,
          rule.maobCalculation.whenLessOrEqual.upbPerc * upbInput
        )
        : Math.max(
          rule.maobCalculation.whenLessOrEqual.aaoPerc * aaoInput,
          rule.maobCalculation.whenLessOrEqual.aivPerc * aivInput,
          rule.maobCalculation.whenLessOrEqual.upbPerc * upbInput
        )
      : rule.maobCalculation.whenGreater.selectLesserOf
        ? Math.min(
          rule.maobCalculation.whenGreater.aaoPerc * aaoInput,
          rule.maobCalculation.whenGreater.aivPerc * aivInput,
          rule.maobCalculation.whenGreater.upbPerc * upbInput
        )
        : Math.max(
          rule.maobCalculation.whenGreater.aaoPerc * aaoInput,
          rule.maobCalculation.whenGreater.aivPerc * aivInput,
          rule.maobCalculation.whenGreater.upbPerc * upbInput
        );

    const maos = (1 + profitMargin.profitMargin) * maob;

    setRunOutput({
      ltv,
      maob,
      maos,
      offers: [0, 1, 2].map(i => ({
        daysToClose: rule.offers[i]?.daysToClose,
        discountPerc: rule.offers[i]?.discountPerc,
        daysForDueDiligence: rule.offers[i]?.daysForDueDiligence,
      }))
    });
  }, [rule, aaoInput, aivInput, upbInput]);

  const handleOverlayClick = () => {
    onClose();
  };

  const handleContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Dialog open={true} onOpenChange={handleOverlayClick}>
      <DialogContent className="max-w-screen flex flex-col p-5 max-h-screen overflow-auto">
        <DialogHeader>
          <DialogTitle>Validate Rule</DialogTitle>
        </DialogHeader>

      <div className="p-4">
        <div className="mb-10">
        <table className="w-full border border-gray-300 rounded-lg overflow-hidden">
          <tbody>
            {[
              { label: "Enter AAO Amount ($)", value: aaoInput, setValue: setAAOInput },
              { label: "Enter AIV Amount ($)", value: aivInput, setValue: setAIVInput },
              { label: "Enter UPB Amount ($)", value: upbInput, setValue: setUPBInput }
            ].map(({ label, value, setValue }) => (
              <tr key={label} className="border-b last:border-b-0 hover:bg-gray-100 transition">
                <td className="py-2 px-4 font-medium text-gray-700 bg-gray-50">{label}</td>
                <td className="py-2 text-center">
                  <input
                    type="number"
                    value={value}
                    onChange={(e) => setValue(Number(e.target.value))}
                    className="w-full max-w-[150px] border border-gray-300 rounded-md p-0 text-center focus:ring-2 focus:ring-blue-500 outline-none transition"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        </div>

        <h3 className="my-2 border-b text-lg font-semibold">Result:</h3>
        <div className="flex flex-col" onClick={handleContentClick}>
          {/* RuleOutput Table */}
          <div className="flex flex-col gap-5 px-4">
            <p>
              <b className="text-gray-400 text-sm">LTV:</b> {formatToPerc(runOutput?.ltv)}
            </p>
            <p>
              <b className="text-gray-400 text-sm">Risk:</b> {runOutput == null || runOutput.ltv == null ? '-' : runOutput.ltv <= rule.maobCalculation.ltvThreshold ? 'Low' : 'High'} (for an LTV Threshold of{' '}
              {formatToPerc(rule.maobCalculation.ltvThreshold)})
            </p>
            <p>
              <b className="text-gray-400 text-sm">MAOB:</b> {formatToUSDCurrency(runOutput?.maob)}
            </p>
            <p>
              <b className="text-gray-400 text-sm">MAOS:</b> {formatToUSDCurrency(runOutput?.maos)} (at {formatToPerc(profitMargin.profitMargin)} Profit Margin)
            </p>
            {/* <br /> */}

            {/* <h2 className="text-lg font-semibold text-center mb-2">Rule Output</h2> */}
            <div className="overflow-hidden border rounded-lg shadow-sm">
            <table className="w-full border-collapse text-gray-700">
              <thead className="bg-gray-200 ">
                <tr key="header" className="border-b">
                  <th className=""></th>
                  <th className="px-2 py-3">Offer 1</th>
                  <th className="px-2 py-3">Offer 2</th>
                  <th className="px-2 py-3">Offer 3</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr key="offerPrices" className="border-b hover:bg-gray-50 transition">
                  <td className="px-4 py-2 font-bold">Price</td>
                  {runOutput?.offers.map((offer, index) => (
                    <td key={index} className="px-4 py-2">
                      {formatToUSDCurrency((1 - offer.discountPerc) * runOutput.maos)}
                    </td>
                  ))}
                </tr>
                <tr key="offerDiscounts" className="border-b hover:bg-gray-50">
                  <td className="px-4 py-2 font-bold">Discount</td>
                  {runOutput?.offers.map((offer, index) => (
                    <td key={index} className="px-4 py-2">
                      {formatToPerc(offer.discountPerc)}
                    </td>
                  ))}
                </tr>
                <tr key="daysToClosing" className="border-b hover:bg-gray-50">
                  <td className="px-4 py-2 font-bold">Days to Closing</td>
                  {runOutput?.offers.map((offer, index) => (
                    <td key={index} className="px-4 py-2">
                      {formatToDays(offer.daysToClose)}
                    </td>
                  ))}
                </tr>
                <tr key="dueDiligenceTime" className="border-b hover:bg-gray-50">
                  <td className="px-4 py-2 font-bold">Due Diligence Time</td>
                  {runOutput?.offers.map((offer, index) => (
                    <td key={index} className="px-4 py-2">
                      {formatToDays(offer.daysForDueDiligence)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CheckRuleDialog;
