import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import "./EmailComposer.css";

interface EmailComposerProps {
  open: boolean;
  opportunityID: string;
  organizationID: string;
  email: string;
  defaultSubject?: string;
  defaultBody?: string;

  onSend?: (opportunityID: string, organizationID: string, email: string, subject: string, body: string) => void;
  onClose?: () => void;
}

const EmailComposer = ({ open, opportunityID, organizationID, email, defaultSubject, defaultBody, onSend, onClose }: EmailComposerProps) => {

  const [subject, setSubject] = useState(defaultSubject || "");
  const [body, setBody] = useState(defaultBody || "");

  useEffect(() => {
    setBody(defaultBody || "");
  }, [defaultBody]);

  useEffect(() => {
    setSubject(defaultSubject || "");
  }, [defaultSubject]);

  useEffect(() => {
    setSubject("");
    setBody("");
    setSubject(defaultSubject || "");
  }, [opportunityID, organizationID, email]);

  const handleClose = () => {
    // setSubject("");
    // setBody("");
    onClose?.();
  };

  const handleSend = () => {
    onSend?.(opportunityID, organizationID, email, subject, body);
    setSubject("");
    setBody("");
    handleClose();
  }

  return (
    <Dialog style={{ overflowY: 'visible' }} open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Compose Email</DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <div className="flex-col">
          <div className="mb-2 flex">
            <span>To : {email}</span>
          </div>
          <div className="mb-2 flex items-center gap-2">
            <input type="text" className="icon-input-box flex-1"
              placeholder="Enter subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <ReactQuill theme="snow" value={body} onChange={setBody} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" type="submit" disabled={!body} onClick={handleSend}>Send</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailComposer;
