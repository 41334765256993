import React, { createContext, useState, useContext } from "react";
import { BannerDetailsViewModel } from "./dashboard/pages/deals/view-models/BannerDetailsViewModel";

interface OpportunityCountsContextType {
  opportunityCounts: BannerDetailsViewModel[];
  setOpportunityCounts: (banneerDetails: BannerDetailsViewModel[]) => void;
}

const OpportunityCountsContext = createContext<OpportunityCountsContextType | undefined>(undefined);

export const useOpportunityCounts = () => {
  const context = useContext(OpportunityCountsContext);
  if (!context) {
    throw new Error("useOpportunityCounts must be used within a OpportunityCountsProvider");
  }
  return context;
};

export const OpportunityCountsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [opportunityCounts, setOpportunityCounts] = useState<BannerDetailsViewModel[]>([]);

  return (
    <OpportunityCountsContext.Provider value={{ opportunityCounts, setOpportunityCounts }}>
      {children}
    </OpportunityCountsContext.Provider>
  );
};
