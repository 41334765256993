//import { Loader } from "lucide-react";

interface DialogLoadingProps {
    loadingMessage:string;
    className?: string;
    isLoading:boolean;

};

const DialogLoading: React.FC<DialogLoadingProps> = ({loadingMessage, className, isLoading}) => {
  return (
    <>
    {isLoading && (
        <div className={`inset-0 bg-white flex flex-grow w-full items-center justify-center ${className || ""}`}>
            {/* <Loader className="animate-spin text-blue-500 w-10 h-10" /> Spinning icon */}
            <p className="text-lg ">{loadingMessage}</p>
        </div>
    )}
    </>
  );
};

export default DialogLoading;
