import { ChevronDown } from 'lucide-react';
import { PricingRuleDropDownData } from '../models/PricingRules';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu.tsx";
import moment from 'moment';

interface RuleDropDownProps{
    activeRule: PricingRuleDropDownData;
    rules: PricingRuleDropDownData[] | undefined;
    onRuleChange: (id: string) => void;
}

const RuleDropDown:React.FC<RuleDropDownProps> = ({activeRule, rules, onRuleChange}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <button className="flex flex-row items-center justify-center rounded-sm bg-gray-100 px-4 py-2 text-blue-600 hover:bg-white">
          {((activeRule.name && activeRule.name.length) || activeRule.id ) ? 
            <>
            <span className="max-w-[12rem] truncate mr-2">{activeRule.name ? activeRule.name : activeRule.id}</span>
            <span
                  className={`h-1.5 w-1.5 rounded-full transition-all ${
                    activeRule.status === 'Active'
                      ? 'bg-green-400 shadow-[0_0_2px_1px_rgba(34,197,94,0.7)]'
                      : activeRule.status === 'Inactive'
                        ? 'bg-red-500 shadow-[0_0_2px_1px_rgba(239,68,68,0.7)]'
                        : 'bg-gray-200 shadow-[0_0_2px_1px_rgba(156,163,175,0.6)]'
                  }`}
                />
          <div className="text-blue-600">
            <ChevronDown className=" ml-2 h-5 w-5" />
          </div>
          </>
            :
            <>
            <span className="max-w-[12rem] truncate text-gray-400 italic">Please Select</span>
            <div className="text-gray-400">
            <ChevronDown className=" ml-2 h-5 w-5" />
          </div>
            </>
          }
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={5}
      >
        {rules?.map((rule) => (
          <DropdownMenuItem
            key={rule.id}
            className={`flex flex-col border-b items-start justify-center px-5 py-3 text-base  ${activeRule.id === rule.id ? 'text-blue-600' : ''}`}
            onClick={() => onRuleChange(rule.id)}
            
          >
            <div className="flex w-full flex-row justify-between">
              <span className="max-w-[10rem] truncate font-medium" title={rule.name}>{rule.name}</span>
              <span className="flex items-center gap-2 text-xs">
                {rule.status}
                <span
                  className={`h-1.5 w-1.5 rounded-full transition-all ${
                    rule.status === 'Active'
                      ? 'bg-green-400 shadow-[0_0_2px_1px_rgba(34,197,94,0.7)]'
                      : rule.status === 'Inactive'
                        ? 'bg-red-500 shadow-[0_0_2px_1px_rgba(239,68,68,0.7)]'
                        : 'bg-gray-200 shadow-[0_0_2px_1px_rgba(156,163,175,0.6)]'
                  }`}
                />
              </span>
            </div>
            <span className="max-w-[18rem] truncate text-xs text-gray-500" title={rule.description}>{rule.description}</span>
            <span className="flex w-full max-w-[18rem] justify-end truncate text-[0.6rem] italic text-gray-500">{moment.utc(rule.dateUpdated).local().format("YYYY-MM-DD hh:mm:ss A")}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RuleDropDown;
