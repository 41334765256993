import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { ChevronRight } from "lucide-react";
import { formatCurrency,  } from "@/lib/utils"; //getTimeInDefault
import { GetFilterPreviewResponse } from "./types";
import Checkbox from "@mui/material/Checkbox";

interface PropertyDealsFilterTableProps {
    properties: GetFilterPreviewResponse[];
    onDetailsClick?: (propertyId: string) => void,
    onPropertySelected: (propertyId: string, isSelected:boolean)=>void
}


const PropertyDealsFilterTable: React.FC<PropertyDealsFilterTableProps> = ({ properties, onDetailsClick, onPropertySelected }) => {
    const renderTableCell = (prefix: string, value: string) =>
        <TableCell className="text-base whitespace-nowrap">
            <span className="text-customGray929DAD">{prefix}</span><span className="text-black dark:text-white">{value}</span>
        </TableCell>;

    // console.log("properties len "+ properties.length);
    // console.log("properties addr "+ JSON.stringify(properties.map((property) => (property.address))));
    // console.log("properties "+ JSON.stringify(properties.map((property) => (property))));

    return (
        <Table>
            <TableBody className="[&_tr:nth-child(even)]:bg-stone-100">
                {properties.length > 0 && <TableRow className="border-t-[1px]">

                        <TableCell></TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Zip</TableCell>
                        <TableCell>APN</TableCell>
                        <TableCell>Assett Class</TableCell>
                        <TableCell>Current UPB</TableCell>
                        <TableCell>Current AIV</TableCell>
                        <TableCell>AAO</TableCell>
                        <TableCell>Tax Assessed Value</TableCell>
                        <TableCell>Loan Type</TableCell>

                        <TableCell>Mortgage Term</TableCell>
                        <TableCell>Lender</TableCell>
                        <TableCell>Borrower</TableCell>
                        <TableCell>Corporate Owned</TableCell>
                        <TableCell>Preforeclosure</TableCell>
                        <TableCell>Property Use</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Open Mortgage</TableCell>
                        <TableCell>Tax Lien</TableCell>
                        <TableCell>Property ID</TableCell>
                    </TableRow>}
    {
                    properties.map((property, index) => (
                        
                        <TableRow key={index} className="border-t-[1px]">
                            <TableCell><Checkbox value={property.id} key={index} className="bg-transparent border-black w-[16px] h-[18px] border-2 mr-3" checked={property.isSelected??false}  onChange={(e)=>{onPropertySelected(property.id, e.target.checked)}}/></TableCell>
                            {/* <TableCell className="flex mb-2">
                                <div className="absolute text-blue-500 text-lg">{property.loanDocumentsCount}</div>
                                <div className="relative"><DocumentIcon /></div> 
                                </TableCell> */}
                            <TableCell className="hidden">{index + 1}</TableCell>
                            {renderTableCell("", property.address)}
                            {renderTableCell("", property.zip5)}
                            {renderTableCell("", String(property.apn))}
                            {renderTableCell("", property.assetClassGroup)}
                            {renderTableCell("", formatCurrency(Number(property.estimated_mortgage_balance)))}
                            {renderTableCell("", formatCurrency(Number(property.estimated_value)))}
                            {renderTableCell("", formatCurrency(Number(property.firstLienPostionMortgageOrginationAmount)))}

                            {renderTableCell("", formatCurrency(Number(property.assessedValue)))}
                            {renderTableCell("", property.firstLienPostionMortgageLoanType || '--')}
                            {renderTableCell("", property.firstLienPositionMortgageLoanTerm !== null ? (String(property.firstLienPositionMortgageLoanTerm) || '') : '--')}
                            {renderTableCell("", property.lenderName || '--')}
                            {renderTableCell("", property.borrowerCalc)}

                            {renderTableCell("", property.is_corporate_owned === true ? "YES" : "NO")}
                            {renderTableCell("", property.is_preforeclosure === true ? "YES" : "NO")}
                            {renderTableCell("", property.property_use)}
                            {renderTableCell("", property.state)}
                            {renderTableCell("", String(property.has_mortgage) === 'true' ? "YES" : "NO")}
                            {renderTableCell("", String(property.is_tax_delinquent === true ? "YES" : "NO"))}
                            {renderTableCell("", property.id)}
                            <TableCell>
                                <button className="text-black dark:text-white" onClick={() => onDetailsClick && onDetailsClick(property.id)}>
                                    <ChevronRight />
                                </button>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
       
    );
};

export default PropertyDealsFilterTable;