import { Dialog, DialogContent } from "@/components/ui/dialog";

interface ConfirmationBoxProps {
  open: boolean;
  onClose: () => void
  onConfirm: () => void
  message: string
}

export default function ConfirmationBox({ open, onClose, onConfirm, message }: ConfirmationBoxProps) {
  return (
    <Dialog open={open} onOpenChange={onClose} >
      <DialogContent showCloseButton={false} className="max-w-[50%] overflow-x-hidden border-0 px-3 py-3 outline-none">
        <div className="space-y-4 px-6 py-2 max-h-80 overflow-auto">
          <div className="h-full p-2">
            {message || "Are you sure you want to proceed?"}
          </div>
        </div>
        <div className="flex justify-around mb-3">
          <button className={`apply-new-query-button`} onClick={() => onClose()}>
            Cancel
          </button>
          <button className={`apply-new-query-button`} onClick={() => onConfirm()}>
            Confirm
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
