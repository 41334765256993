import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
// import { WandSparkles } from 'lucide-react';
import PricingRuleSettings from './PricingRuleSettings';
import ConfirmDialog from '../dashboard/pages/global-pricing-rule/components/ConfirmDialog';

export interface PricingRuleSettingsDialogProps {
  isOpen: boolean;
  onOpenChange: () => void;
  onLoadingStart?: () => void;
  onLoadingComplete?: () => void;
}

const PricingRuleSettingsDialog = ({ isOpen, onOpenChange }: PricingRuleSettingsDialogProps) => {

  const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);

  const handleOpenChange = () => {
    setShowCloseDialog(false);
    onOpenChange();
  }

  return (
    <>
    <Dialog open={isOpen || !showCloseDialog} onOpenChange={() => setShowCloseDialog(true)}>
      <DialogContent className="max-w-screen flex h-full w-[85%] flex-col p-5"> {/* border-0 p-5 outline-none */}
        <DialogHeader>
          <DialogTitle>Global Pricing Rule Engine</DialogTitle>
        </DialogHeader>
        {/* <DialogDescription className='flex flex-row justify-between'>
          
        </DialogDescription> */}
        <div className="flex-grow overflow-auto">
          <PricingRuleSettings onClose={onOpenChange}/>
        </div>
        {/* <DialogFooter className='bg-red-500'>
        </DialogFooter> */}
      </DialogContent>

    </Dialog>
    
    {showCloseDialog && (
        <Dialog open = {true}>
          <DialogContent className='w-[40rem]'>
          <ConfirmDialog 
            open={showCloseDialog} 
            title="Please Confirm"
            message="Do you want to close the Pricing Rule Engine?" 
            handleClose={() => setShowCloseDialog(false)} // Only closes confirm dialog
            handleConfirm={handleOpenChange} // Closes everything
          />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default PricingRuleSettingsDialog;
