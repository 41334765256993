import { GET_ACTIVITY_LOGS } from "@/config/config";
import { ActivityChange, ActivityEvent, ChangeType, getDislplayDescripion, getEventType } from "./models/Timeline";
import { getDisplayValue } from "./ActivityActionItems";


const userNameDisplayValue = (name:string, email:string, congId:string):string=> {
    if(name || name != ""){
        return name;
    }
    else if(email || email!="") {
        return email;
    }
    else {
        return congId;
    }
}

export const fetchActivities = async (opportunityId:string, cognitoID:string): Promise<ActivityEvent[]> => {
    console.log(opportunityId);
    console.log(cognitoID);
    let url = GET_ACTIVITY_LOGS;

      url = url.replace("{reourceId}", opportunityId)
                    .replace("{congId}", cognitoID)
                    .replace("{resourceType}", "opportunity");

    const res = await fetch(url);
    const data = await res.json();
    if (!res.ok) {
        const errorText = await res.text();
        console.error('Response Error Text:', errorText);
        throw new Error('Failed to fetch Document.');
    }
    if (data.statusCode != 200) {
        throw new Error(data.body);
    }
    if (!data.body.rows) {
        throw new Error("Failed to parse data!");
    }
    return parseActivities(data.body.rows).sort((a, b)=> b.timestamp.getTime()-a.timestamp.getTime());
  };
  const parseActivitiesDescription = (description: any): ActivityChange[] => {
    if (!description) return [];
    const details: ActivityChange[] = [];
    description.forEach((element: any) => {
        const type:ChangeType = getDisplayValue(element.field);
        if (type.format) {
            details.push({
                type: type.displayValue,
                oldValue: type.format(element.old_value),
                newvalue: type.format(element.new_value)
            })
        }
        else {
            details.push({
                type: type.displayValue,
                oldValue: element.old_value?.toString() || "",
                newvalue: element.new_value?.toString() || ""
            })
        }
    });
    return details;
}
const parseActivities = (data: any): ActivityEvent[] => {
    const activities: ActivityEvent[] = [];
    data.forEach((element: any) => {
        return activities.push(
            {
                description: getDislplayDescripion(element.event_type, element.description),
                timestamp: new Date(element.event_timestamp),
                type: getEventType(element.event_type),
                user: {
                    cognitoID: element.user_cognitoID,
                    email: element.user_email,
                    name: userNameDisplayValue(element.user_name, element.user_email, element.user_cognitoID || element.user_cognitoid )           
                },
                changes: parseActivitiesDescription(JSON.parse(element.differential))
            }
        );
    });
    return activities;
}