import { FILTER_PREVIEW_API_URL, PROPERTIES_QUERY_API_URL } from '@/config/config';
import { ApiResponse, ApiBodyResponse } from './Properties.interface';

export const fetchProperties = async (page: number, limit: number): Promise<ApiBodyResponse | null> => {
  const response = await fetch(`${PROPERTIES_QUERY_API_URL}?limit=${limit}&page=${page}`);
  const data: ApiResponse = await response.json();

  if (data.statusCode !== 200) {
    console.error('Fetched properties failed with status:', data.statusCode);
    return null;
  }

  const parsedBody: ApiBodyResponse = JSON.parse(data.body);
  return parsedBody;
};

export const fetchPropertiesTableData = async (address: string, page: number, limit: number, filters:string): Promise<{ tableData: ApiBodyResponse; totalRecord: string | undefined } | null> => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(filters && { body: JSON.stringify(filters) }), // Add body only if filters exist
  };
  const response = await fetch(`${FILTER_PREVIEW_API_URL}?address=${address}&from_index=${page}&size=${limit}`, options);
  const data: ApiResponse = await response.json();

  if (data.statusCode !== 200) {
    console.error('Fetched properties failed with status:', data.statusCode);
    return null;
  }

  const parsedBody: ApiBodyResponse = JSON.parse(data.body);
  return { tableData: parsedBody, totalRecord: data.message };
};
