import React from "react";
import { Badge } from "./Badge";

interface CountBadgeProps {
  count?: number;
  absolute?: boolean;
  maxCount?: number;
  className?: string;
  hideZeroCounts?: boolean;
}

export const CountBadge: React.FC<CountBadgeProps> = ({ count, maxCount = 9, absolute = true, className = '', hideZeroCounts = true }) =>
  hideZeroCounts && !count ? null :
    (<Badge>
      <span className={(absolute ? 'absolute' : '') + " -bottom-1 -right-2 bg-red-500 text-white text-xs font-bold " + (className)}>
        {count == null || count == undefined ? '...' : count > maxCount ? `${maxCount}+` : count}
      </span>
    </Badge>);
