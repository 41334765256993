import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getPricingRulesWithID, getAllPricingRule, Scope, PricingRuleDropDownData, getActivePricingRule, SellerOffer, addOrUpdatePricingRules, deletePricingRule, PricingRule } from './models/PricingRules';
import { AlertCircle, Bolt, Copy, Plus, Save, WandSparkles } from 'lucide-react';
// import { Button } from '../ui/button';
import PricingRuleFlowGrid, { ExistingOrNewScope, guessedMastAssetClassList } from './PricingRuleFlowGrid';
import RuleDropDown from './components/RuleDropDown';
import RuleDialog from './components/RuleDialog';
import WizardDialog from './components/WizardDialog';
import { AssetClassesStage } from './components/stages/AssetClassesStage';
import { MAOBCalculationStage } from './components/stages/MAOBCalculationStage';
import { SellerOffersStage } from './components/stages/SellerOffersStage';
import { EditablePercentBox } from './components/stages/EditablePercentBox';
import EditRuleDialog from './components/EditRuleDialog';
import { Dialog, DialogContent } from '../ui/dialog';
import ConfirmDialog from '../dashboard/pages/global-pricing-rule/components/ConfirmDialog';

export interface PricingRuleSettingsProps {
  onLoadingStart?: () => void;
  onLoadingComplete?: () => void;
  onClose: () => void;
};

export type ExistingOrNewRule = Omit<PricingRule, 'id' | 'scopes'> & { id?: string, scopes: ExistingOrNewScope[] };

const defaultScope: Omit<Scope, 'id'> = {
  maobCalculation: {
    ltvThreshold: 0.45,
    whenLessOrEqual: {
      id: "",
      selectLesserOf: true,
      aaoPerc: 0.6,
      aivPerc: 0.4,
      upbPerc: 0.6,
    },
    whenGreater: {
      id: "",
      selectLesserOf: true,
      aaoPerc: 0.65,
      aivPerc: 0.4,
      upbPerc: 0.65,
    },
  },
  offers: [
    {
      id: "",
      daysToClose: 60,
      discountPerc: 0,
      daysForDueDiligence: 30,
    },
    {
      id: "",
      daysToClose: 45,
      discountPerc: 0.07,
      daysForDueDiligence: 20,
    },
    {
      id: "",
      daysToClose: 30,
      discountPerc: 0.12,
      daysForDueDiligence: 10,
    },
  ],
  // maosCalculation: {
  //   profitMargin: 0.03
  // }
};

const defaultRule: ExistingOrNewRule = {
  name: "Default Rule",
  description: "",
  dateUpdated: "",
  status: "Open",
  dateCreated: "",
  profitMargin: 0.03,
  cashDownPercent: 0.2,
  globalDefault: false,
  createdCognitoID: "",
  dateExpired: "",
  lastUpdatedCognitoID: "",
  overridesIDS: [],
  scopes: []
};

const areRulesEqual = (rule: PricingRule | undefined, modifiedRule: ExistingOrNewRule | undefined): boolean => {
  if (!rule || !modifiedRule) return false;

  const removeId = (rule: PricingRule | ExistingOrNewRule) => {
    const { id, ...rest } = rule;
    return JSON.stringify(rest);
  };

  const newRule = removeId(rule);
  const newModifiedRule = removeId(modifiedRule);

  return JSON.stringify(newRule) === JSON.stringify(newModifiedRule);
};

const PricingRuleSettings:React.FC<PricingRuleSettingsProps> = ({onClose}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [criticalError, setCriticalError] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (criticalError) {
      toast.error(criticalError);
    }
  }, [criticalError]);

  const [rule, setRule] = useState<PricingRule|undefined>();
  const [modifiedRule, setModifiedRule] = useState<ExistingOrNewRule| undefined>();
  useEffect(() => {
    setModifiedRule(rule);
  },[rule]);
  
  const [tempScopeForWizard, setTempScopeForWizard] = useState<ExistingOrNewScope>(defaultScope);
  const [ruleDropDownData, setRuleDropDownData] = useState<PricingRuleDropDownData[] | undefined>();
  const [openAddNewRuleDialog, setOpenAddNewRuleDialog] = useState<boolean>(false);
  const [openEditRuleDialog, setOpenEditRuleDialog] = useState<boolean>(false);
  const [currentRule, setCurrentRule] = useState<PricingRuleDropDownData| undefined>();
  const [openWizardSteps, setOpenWizardSteps] = useState<boolean>(false);
  const [showDataRemovalWarning, setShowDataRemovalWarning] = useState<string|undefined>();

  // Fetch predefined roles set on component mount
  useEffect(() => {
    const fetchAll = async () => {
      setIsLoading(true);

      try {
        setRuleDropDownData(await getAllPricingRule());
        const currentRule = await getActivePricingRule();
        setCurrentRule(currentRule);
        if(currentRule){
          const rule = await getPricingRulesWithID(currentRule.id);
          setRule(rule.rule);
        };
      } catch (error) {
        setCriticalError(`Error fetching Pricing Rules: ${error}`);
      }
      setIsLoading(false);
    };

    fetchAll();
  }, []);

  const usedAssetClasses = new Set(modifiedRule?.scopes?.flatMap((rule) => rule.assetClasses || []));
    const allAssetClasses = new Set([
      ...guessedMastAssetClassList,
      ...usedAssetClasses, // make sure that all used Asset Classes are also in the list!
    ]);
    const unusedAssetClasses = [...allAssetClasses].filter((assetClass) => !usedAssetClasses.has(assetClass));
    
  const wizardSteps = [
    {
      name: 'Asset Classes',
      component: (
        <div>
          <AssetClassesStage 
          values={tempScopeForWizard.assetClasses || []}  
          availableForUse={unusedAssetClasses}
          onValueChanged={(value) => setTempScopeForWizard((prev) => ({ ...prev, assetClasses: value }))} />
        </div>
      ),
    },
    {
      name: 'LTV Threshold',
      component: (
        <div>
          <EditablePercentBox
          title='LTV Threshold'
          value={tempScopeForWizard.maobCalculation.ltvThreshold} 
          onChange={(value) => setTempScopeForWizard((prev) => ({ ...prev, maobCalculation: { ...prev.maobCalculation, ltvThreshold: value } }))}
          />
        </div>
      ),
    },
    {
      name: 'MOAB Calculation',
      component: (
        <div className="flex flex-col justify-between gap-4 px-10">
          <MAOBCalculationStage 
          ltvThreshold={tempScopeForWizard.maobCalculation.ltvThreshold} 
          defaultValue={tempScopeForWizard.maobCalculation.whenLessOrEqual}
          value={tempScopeForWizard.maobCalculation.whenLessOrEqual}
          lessThenOrEqualLTVThreshold={true} 
          onValueChanged={(value) => setTempScopeForWizard((prev) => ({...prev, maobCalculation: {...prev.maobCalculation, whenLessOrEqual: value}}))} />
          <MAOBCalculationStage
          ltvThreshold={tempScopeForWizard.maobCalculation.ltvThreshold}
          defaultValue={tempScopeForWizard.maobCalculation.whenGreater}
          value={tempScopeForWizard.maobCalculation.whenGreater} 
          lessThenOrEqualLTVThreshold={false} 
          onValueChanged={(value) => setTempScopeForWizard((prev) => ({...prev, maobCalculation: {...prev.maobCalculation, whenGreater: value}}))} />
        </div>
      ),
    },
    {
      name: 'Seller Offers',
      component: (
        <div>
          <SellerOffersStage 
          defaultValue={tempScopeForWizard.offers} 
          value={tempScopeForWizard.offers} 
          onChange={(value: SellerOffer[]) => setTempScopeForWizard((prev) => ({...prev, offers:value}))}
          className='m-2' />
        </div>
      ),
    },
  ];
  
  const scrollTo = (row: number, col: number) => {
    const cell = document.querySelector<HTMLTableCellElement>(`table.comp-grid tr.comp-body-row-${row} td.comp-body-col-${col}`);
    if (cell) cell.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const onAddScope = (_delta: Omit<Scope, 'id'>): void => {
    setTimeout(() => {
      scrollTo(modifiedRule?.scopes && modifiedRule?.scopes.length ? modifiedRule?.scopes.length : 0, 0);
      // const container = scrollableContainerRef.current;
      // if (container) {
      //   container.scrollTo({
      //     top: container.scrollHeight,
      //     behavior: 'smooth'
      //   });
      // }
    }, 100);
  };

  const handleAddScope = () => {
    const { id, assetClasses, ...partialWithDefaults } = modifiedRule?.scopes?.find((r) => r.id && (!r.assetClasses || !r.assetClasses.length)) || { id: undefined, ...defaultScope };
    setModifiedRule((prevRules) => {
      if (!prevRules) return { ...defaultRule, scopes: [partialWithDefaults] };
      return { ...prevRules, scopes: [...prevRules.scopes, partialWithDefaults] };
    });
    onAddScope(partialWithDefaults);
  };

  const handleDeleteScope = (...reference: { rowIndex: number; id?: string }[]) => {
    setModifiedRule((prevRules) => {
      if (!prevRules) return undefined;
      return {
        ...prevRules,
        scopes: prevRules.scopes.filter((_scope, i) => !reference.some((ref) => ref.rowIndex === i))
      };
    });
  };

  
  const handleAddNewRule = async(name: string, description: string) => {
    // const response = await addPricingRules(name, description);
    const ruleDetail = {
      id: "",
      name: name,
      description: description,
      dateUpdated: "",
      status: "Inactive",
      dateCreated: "",
      };
      // setRuleDropDownData((prev) => [...(prev || []), ruleDetail]);
      setCurrentRule(ruleDetail);
      setRule({
        id: "",
        ...defaultRule,
        name: name,
        description: description,
        scopes:[]
      });
    };

    const handleRuleChange = async(id: string) => {
      setIsLoading(true);
      try{
        setCurrentRule(ruleDropDownData?.find((prev) => prev.id === id) || undefined);
      setRule((await getPricingRulesWithID(id)).rule)
    } catch(e){
      console.error(e);
    }
    setIsLoading(false);
  };
  
  const handleCopyRule = () => {
    setRule((prev) => {
      if(!prev) return undefined;
      return{
        ...prev,
        id:"",
        dateCreated: "",
        dateUpdated:"",
        dateExpired:"",
        globalDefault: false,
        status: "Inactive",
      }
    });
    setCurrentRule((prev) => {
      if(!prev) return undefined;
      return{
        ...prev,
        id:"",
        status:"Inactive",
        dateCreated: "",
        dateUpdated:"",
      }
    });
  };
  
  const handleEditRule = async(name: string, description: string, active: boolean, currentRule?: PricingRuleDropDownData) => {
    setRuleDropDownData((prevRules = []) =>
        prevRules.map((v) =>
          v.id === currentRule?.id
            ? { ...v, name, description }
            : v
        )
      );
      setModifiedRule((prev) => {
        if(!prev) return undefined;
        return{
          ...prev,
          name: name,
          description: description,
          status: active? "Active" : prev.status
        }
      })
      setCurrentRule({id: currentRule?.id || '', name: name, description: description, dateUpdated: currentRule?.dateUpdated || '', status: active ? 'Active' : (currentRule?.status || 'Inactive'), dateCreated: currentRule?.dateCreated||''});
      if(active) {
        setRuleDropDownData((prev) => prev?.map((v: PricingRuleDropDownData) => v.status === 'Active' ? { ...v, status: 'Open' } : v));
        setRuleDropDownData((prev) => prev?.map((v: PricingRuleDropDownData) => v.id === currentRule?.id ? { ...v, status: 'Active'} : v));
      }
  };
  
  const handleDeleteRule = async() => {
    setOpenEditRuleDialog(false);
    if(currentRule && currentRule.id.length === 0){
      setCurrentRule(undefined);
      setRule(undefined);
    }else{
      setIsLoading(true);
      try{
        const deletedRule: string|undefined = await deletePricingRule(currentRule?.id || "");
        if(deletedRule){  
          setRuleDropDownData((prevRules) => { 
            return prevRules?.filter((prevRule) => prevRule.id !== deletedRule.toString()) || []
          });
          const currentRule = await getActivePricingRule();
          setCurrentRule(currentRule);
          if(currentRule){
            const rule = await getPricingRulesWithID(currentRule.id);
            setRule(rule.rule);
          } else {
            setRule(undefined);
          }
        };
      } catch(e:any){
        console.error(e);
      }
      setIsLoading(false);
    }
  };
  
  const handleSave = async() => {
    setIsLoading(true);
    try{
      await addOrUpdatePricingRules(modifiedRule as PricingRule);
      onClose();
    } catch(e){
      console.error("Error while updating rules",e);
    }
    setIsLoading(false);
  };
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={`relative h-full w-full overflow-hidden`}>
      <div className="flex h-full flex-col">
        <div className="my-2 flex flex-row justify-between">
          <i className={` w-full text-xs`}>
            Quick Tip: Use the wizard <WandSparkles style={{ fontSize: 'inherit', verticalAlign: 'middle', display: 'inline-block' }} className="h-6 w-6 px-1" />
            for a more intuitive way to add or edit scopes.
          </i>
          <div className="flex w-full flex-row justify-end gap-2">
            <button
              className="flex items-center gap-2 rounded-md px-1 py-2 text-blue-500 disabled:cursor-not-allowed disabled:bg-gray-400"
              onClick={() => setOpenAddNewRuleDialog(true)}
              title='Add New Rule'
            >
              <Plus className="h-5 w-5" />
            </button>
            {currentRule && (
              <>
              <button
              className="flex items-center gap-2 rounded-md px-1 py-2 text-blue-500 disabled:cursor-not-allowed disabled:bg-gray-400"
              onClick={handleCopyRule}
              title='Copy Rule'
            >
              <Copy className="h-5 w-5" />
            </button>
              <button
                className="flex items-center gap-2 rounded-md px-1 py-2 text-blue-500 disabled:cursor-not-allowed disabled:bg-gray-400"
                onClick={() => setOpenEditRuleDialog(true)}
                title='Settings'
              >
                <Bolt className="h-5 w-5" />
              </button>
              </>
            )}
            <RuleDropDown
              activeRule={currentRule ? currentRule : { id: '', name: '', description: '', dateUpdated: '', status: 'Inactive', dateCreated: '' }}
              rules={ruleDropDownData}
              onRuleChange={(id: string) => {
                // setCurrentRule(ruleDropDownData?.find((rule) => rule.id === id));
                if((currentRule && currentRule.id.length === 0) || (rule && modifiedRule && !areRulesEqual(rule, modifiedRule))){
                  setShowDataRemovalWarning(id);
                }else{
                  handleRuleChange(id);
                }
              }}
            />
          </div>
        </div>
        {/* Main Content Area */}
        <div ref={scrollableContainerRef} className={`h-full w-full ${criticalError ? 'overflow-hidden' : 'overflow-auto'}`}>
          <PricingRuleFlowGrid
            lastSavedScope={rule?.scopes}
            globalParameters={{ profitMargin: rule?.profitMargin || defaultRule.profitMargin, cashDownPercent: rule?.cashDownPercent || defaultRule.cashDownPercent }}
            scopes={modifiedRule?.scopes}
            onAdd={onAddScope}
            onDelete={handleDeleteScope}
            onChange={(scopes) =>
              setModifiedRule((prev) => {
                if (!prev) return undefined;
                return {
                  ...prev,
                  scopes: scopes,
                };
              })
            }
            onGlobalParameterChange={(newGlobalParameters) =>
              setModifiedRule((prev) => {
                if (!prev) return;
                return {
                  ...prev,
                  profitMargin: newGlobalParameters.profitMargin,
                  cashDownPercent: newGlobalParameters.cashDownPercent,
                };
              })
            }
            defaultMessage={`${isLoading ? 'Loading...' : ruleDropDownData?.length ? (currentRule ? '' : 'No Active Rule. Please select a rule.') : 'No Rules Available. Please create a new rule.'}`}
          />
          {/* <GlobalPricingSettingsScreen ref={childRef}/> */}
        </div>

        {/* Footer */}
        <div className="mb-1 flex flex-col-reverse pt-5 sm:flex-row sm:justify-end sm:space-x-2">
          <button
            className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400"
            onClick={handleAddScope}
            disabled={!currentRule}
          >
            <Plus className="h-4 w-4" />
            Add New Scope
          </button>
          <button
            className={` flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400`}
            onClick={() => setOpenWizardSteps(true)}
            disabled={!currentRule}
          >
            <WandSparkles className="h-4 w-4" />
            Add Scope With Wizard
          </button>
          {/* <button
            className="flex items-center gap-2 px-4 py-2 text-black bg-white rounded-md shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Undo className="w-4 h-4" />
            Revert
          </button> */}
          <button
            className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400"
            onClick={handleSave}
            disabled={!currentRule || (areRulesEqual(rule, modifiedRule) && rule?.id.length != 0)}
          >
            <Save className="h-4 w-4" />
            Save
          </button>
        </div>
      </div>

      {/* Critical Error Overlay */}
      {criticalError && (
        <div className="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center bg-black bg-opacity-70">
          <div className="flex flex-col items-center rounded-lg bg-black bg-opacity-50 p-5">
            <AlertCircle className="mb-3 h-10 w-10 text-red-500" />
            <p className="text-center text-lg text-white">{criticalError}</p>
          </div>
        </div>
      )}

      {openAddNewRuleDialog && (
        <RuleDialog
          heading="Add New Rule"
          onOpenChange={() => setOpenAddNewRuleDialog(false)}
          defaultRuleName={ruleDropDownData ? `Rule ${ruleDropDownData.length + 1}` : ''}
          onCreateClick={handleAddNewRule}
        />
      )}

      {openEditRuleDialog && (
        <EditRuleDialog
          heading="Edit Rule"
          onOpenChange={() => setOpenEditRuleDialog(false)}
          defaultRuleName={currentRule?.name}
          defaultRuleDescription={currentRule?.description}
          currentRule={currentRule}
          onSaveClick={handleEditRule}
          onDeleteRule={handleDeleteRule}
        />
      )}

      {openWizardSteps && (
        <WizardDialog
          title="Add New Scope Using Wizard"
          steps={wizardSteps}
          onOpenChange={() => {setOpenWizardSteps(false); setTempScopeForWizard(defaultScope)}}
          onSaveClick={() => {
            setOpenWizardSteps(false);
            setModifiedRule((prev) => {
              if (!prev) return undefined;
              return {
                ...prev,
                scopes: [...(prev.scopes || []), tempScopeForWizard],
              };
            });
            onAddScope(tempScopeForWizard);
            setTempScopeForWizard(defaultScope);
          }}
        />
      )}

      {showDataRemovalWarning !== undefined && (
        <Dialog open={true}>
          <DialogContent className="w-[40rem]">
            <ConfirmDialog
              open={true}
              title="Please Confirm"
              message="All the current changes will be lost. Do you want to continue?"
              handleClose={() => setShowDataRemovalWarning(undefined)}
              handleConfirm={() => {
                handleRuleChange(showDataRemovalWarning);
                setShowDataRemovalWarning(undefined);
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      {isLoading && (
        <div className="fixed inset-0 z-[999999] flex items-center justify-center bg-black bg-opacity-20">
          <div className="h-12 w-12 animate-spin rounded-full border-4 border-blue-600 border-t-transparent"></div>
        </div>
      )}
    </div>
  );
};

export default PricingRuleSettings;
