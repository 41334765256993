import { FilePenLine, FileX2, HandCoins, Handshake, HeartHandshake, LandPlot, Lightbulb, RotateCcwSquare, ScanEye, Settings, TicketCheck, UserRound } from 'lucide-react'; // LayoutGridIcon, ShoppingBagIcon, UsersRound, ListTree

export const SIDE_NAV_LINKS = {
  top: [
    // {
    //     label: "Dashboard",
    //     icon: <LayoutGridIcon className="fill-current" />,
    //     to: "/",
    //     key: "dashboard"
    // },
    // {
    //     label: "Investments",
    //     icon: <ShoppingBagIcon />,
    //     to: "/investments",
    //     key: "investments"
    // },
    // {
    //     label: "Lender Profiles",
    //     icon: <UsersRound />,
    //     to: "/lender-profiles",
    //     key: "lender-profiles"
    // },
    // {
    //   label: 'Deals',
    //   icon: <Handshake />,
    //   to: '/deals',
    //   key: 'deals',
    // },
    {
      label: 'Opportunities',
      icon: <Lightbulb />,
      to: '/v2/deals',
      key: 'dealsv2',
    },
    {
      label: 'In Review Opportunities',
      icon: <FilePenLine />,
      to: '/inreview/deals',
      key: 'inreviewopportunities',
    },
    {
      label: 'Rejected Opportunities',
      icon: <FileX2 />,
      to: '/reject/deals',
      key: 'rejectopportunities',
    },
    {
      label: 'Offers Made',
      icon: <Handshake />,
      to: '/offersmade/deals',
      key: 'offermadeopportunities',
    },
    {
      label: 'Offers Accepted',
      icon: <HeartHandshake />,
      to: '/offersaccepted/deals',
      key: 'offeracceptedopportunities',
    },
    {
      label: 'Offers Countered',
      icon: <RotateCcwSquare />,
      to: '/offerscountered/deals',
      key: 'offercounteredopportunities',
    },
    {
      label: 'Offers Pending Review',
      icon: <ScanEye />,
      to: '/offerspendingreview/deals',
      key: 'offerpendingreviewdopportunities',
    },
    // {
    //     label: "Products",
    //     icon: <Codesandbox />,
    //     to: "/products",
    //     key: "products"
    // },
    // {
    //     label: "Demo S3 File Tree",
    //     icon: <ListTree />,
    //     to: "/demo-s3-file-tree",
    //     key: "demo-s3-file-tree"
    // },
    {
      label: 'Properties',
      icon: <LandPlot />,
      to: '/properties',
      key: 'properties',
    },
    {
      label: 'Offers',
      icon: <HandCoins />,
      to: '/offers',
      key: 'offers',
    },
    {
      label: 'Buyers',
      icon: <TicketCheck />,
      to: '/buyers',
      key: 'buyers',
    },
  ],
  bottom: [
    {
      label: 'My Account',
      icon: <UserRound />,
      to: '/me',
      key: 'my-account',
    },
    {
      label: 'Settings',
      icon: <Settings />,
      to: '/settings',
      key: 'settings',
    },
  ],
};

export const menuRoute = [
  'investments',
  'lender-profiles',
  'properties',
  'products',
  'me',
  'settings',
  'demo-s3-file-tree',
  'deals',
  'offers',
  'buyers',
  'query-builder',
  'splash',
];
