import React, { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../../ui/dialog';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { ArrowLeft, ArrowRight, Save } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';

interface WizardDialogProps {
  title?: string;
  steps: { name: string; component: JSX.Element }[];
  onOpenChange: () => void;
  onSaveClick: () => void;
}

const stepVariants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 100 : -100, // Enter from the right or left
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.1 },
  },
  exit: (direction: number) => ({
    x: direction > 0 ? -100 : 100, // Exit to the left or right
    opacity: 0,
    transition: { duration: 0.1 },
  }),
};

const stepHeading = [
    {
      label: 'Asset Classes',
      description: `The applicable Asset Classes ("Residential", "Multi-family", "Commercial", or "Industrial") for this scope, to calculate the MAOB and offer prices to sellers.`,
    },
    {
      label: 'LTV Threshold',
      description:
        `The LTV Threshold (Loan-to-Value Threshold) is the percentage limit used to assess risk in lending. 
        If the LTV is below the threshold, the loan is considered lower risk; if it exceeds the threshold, 
        it indicates higher risk.`,
    },
    {
      label: 'MOAB Calculation',
      description: `MAOB Calculation determines property value limits based on the LTV Threshold. 
      Depending on whether the LTV is above or below the threshold, set the percentages of AAO, AIV and UPB and the selection (minimum or maximum) to determine the property values and offers.`,
    },
    {
      label: 'Seller Offers',
      description: `A Seller Offer includes terms like price, discount, days to close, and due diligence period, 
      outlining the conditions under which a seller is willing to sell a property. You can provide 3 such offers to sellers.`,
    },
  ];

const WizardDialog: React.FC<WizardDialogProps> = ({ title, steps, onOpenChange, onSaveClick }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [direction, setDirection] = useState(1);

  const handleSaveClick = () => {
    onSaveClick();
    onOpenChange();
  };

  const handleNext = () => {
    if (activeStep < stepHeading.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setDirection(1); // Moving forward
    }
  };

  const handleBack = () => {
    setDirection(-1); // Moving backward
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog open={true} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen min-w-[45rem] overflow-auto p-5">
        {' '}
        {/* border-0 p-5 outline-none */}
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription className="mb-6 flex flex-row gap-4">
          <div className="w-1/3">
            <CustomStepper steps={stepHeading} activeStep={activeStep} />
          </div>
          <div className="flex flex-grow items-center justify-center overflow-hidden">
            <AnimatePresence mode="wait" custom={direction}>
              <motion.div
                key={activeStep} // Unique key to trigger re-animation
                custom={direction}
                variants={stepVariants}
                initial="enter"
                animate="center"
                exit="exit"
                className="flex flex-grow items-center justify-center"
              >
                {steps[activeStep].component}
              </motion.div>
            </AnimatePresence>
          </div>
        </DialogDescription>
        <DialogFooter>
          <div className="flex w-full flex-row justify-between">
            <button
              className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <ArrowLeft className="h-4 w-4" />
              Previous
            </button>

            {activeStep === stepHeading.length - 1 ? (
              <button
                className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400"
                onClick={handleSaveClick}
              >
                <Save className="h-4 w-4" />
                Save
              </button>
            ) : (
              <button
                className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400"
                onClick={handleNext}
              >
                Next
                <ArrowRight className="h-4 w-4" />
              </button>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default WizardDialog;

interface CustomStepperProps {
  steps: { label: string; description: string }[];
  activeStep: number;
}

const CustomStepper: React.FC<CustomStepperProps> = ({ steps, activeStep }) => {
  return (
    <Stepper activeStep={activeStep} orientation="vertical" className="my-6">
      {steps.map(({ label, description }) => {
        return (
          <Step key={label}>
            <StepLabel
              sx={{
                '& .MuiStepLabel-label': { fontSize: '1.2rem' }, // Increase label font size
                '& .MuiSvgIcon-root': { fontSize: '1.7rem' }, // Increase icon size
              }}
            >
              {label}
            </StepLabel>
            <StepContent className=''>{description}</StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
};
