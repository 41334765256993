import { useAuth } from '@/components/AuthProvider';
import BlueCircleCheckIcon from '@/components/icons/BlueCircleCheckIcon';
import BlueCircleXIcon from '@/components/icons/BlueCircleXIcon';
import ThumbUpFilledIcon from '@/components/icons/ThumbUpFilledIcon';
import ThumbDownIcon from '@/components/icons/ThumbDownIcon';
import ThumbUpIcon from '@/components/icons/ThumbUpIcon';
import CircleCheckIcon from '@/components/icons/CircleCheckIcon';
import UnCircleCheckIcon from '@/components/icons/UnCircleCheckIcon';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { entryType, gender, partyType, permissionsArr } from '@/constants/ThirdPartyConstants';
import { formatCurrencyShort } from '@/lib/utils';
import { CalendarIcon } from '@radix-ui/react-icons';
import { Pencil, SearchIcon, Mail, Phone } from 'lucide-react';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import CircleChart from '../../charts/CircleChart';
import SemiCircleChart from '../../charts/SemiCircleChart';
import CarouselComponent from '../../common/CarouselComponent';
import EditableField, { UpdatedOpportunityField } from '../../common/EditableField';
import NumberDisplay from '../../common/NumberDisplay';
import ProgressComponent from '../../common/ProgressComponent';
import SliderComponent from '../../common/SliderComponent';
//import LocationDotsMap from '../../deals/LocationDotsMap';
import CarouselCardActionMenu from './CarouselCardActionMenu';
import './DealsDesktop.css';
import { DEAL_STATUS } from './enum';
import { OpportunityQueryKeys, useUpdateOpportunityPricing } from './hooks';
import { UpdateOpportunityPricingInput, UpdateOpportunityStatusInput, UpdateOpportunityStatusResponse } from './types'; //DealStatusType,
import { OpportunityViewModel } from './view-models/OpportunityViewModel';
import TimelineDialog from '../../common/TimelineDialog';
import ThumbDownFilledIcon from '@/components/icons/ThumbDownFilledIcon';
import LoadingModal from '../../common/LoadingModal';
import { useIsFetching } from 'react-query';
import { useRef } from "react";
import { motion } from 'framer-motion';
import EmailComposer from './EmailComposer';
import { addNewThirdPartyProfile, ContactInfo, getThirdPartyProfile, sendEmail, ThirdPartyProfile, updateOpportunityStatus, updateThirdPartyProfile } from './services/dealService';
import Banner from '@/components/ui/Banner';
import TimezoneSelect from "react-timezone-select"

interface DealsDesktopProps {
  initialOpportunities: OpportunityViewModel[];
  onSlideChange?: (index: number) => void;
  hideProgressBar?: () => void;
  showProgressBar?: () => void;
  onNoteSave: (opportunityID: string, note: string) => void;
  onUpdateValue?: (opportunityId: string, values: Record<string, number>) => void;
  onOpportunityUpdate: (opportunityID:string) => void;
}

export interface OpportunityCalculations {
  discountPercentMAOB: number;
  adjustOpportunityMAOB: number;
  opportunityProperties: Object;
  profit: number;
  opportunityNetProfitMargin: number;
  percentage: number;
}
export interface OptionType {
  label?: string;
  value?: string;
}


const DealsDesktop: React.FC<DealsDesktopProps> = ({ initialOpportunities, onSlideChange, onNoteSave, onOpportunityUpdate, onUpdateValue }) => {
  const colorList = [
    '#ceecf9',
    '#1d2634',
    '#08a0e2',
    '#d2d4d6',
    '#84d0f1',
    '#dddddd',
    '#0000FF', // Blue
    '#FFFF00', // Yellow
    '#FFA500', // Orange
    '#FF0000', // Red
    '#00FF00', // Green
    '#800080', // Purple
    '#00FFFF', // Cyan
    '#FF00FF', // Magenta
    '#00FF00', // Lime
    '#FFC0CB', // Pink
    '#A52A2A', // Brown
    '#808080', // Gray
    '#808000', // Olive
  ];

  const MAX_DISCOUNT = 0.3; // 30%

  const activeIcons: { [key: string]: React.ReactNode } = {
    [DEAL_STATUS.Rejected]: <ThumbDownFilledIcon />,
    [DEAL_STATUS.InReview]: <ThumbUpFilledIcon />,
  };

  const inactiveIcons: { [key: string]: React.ReactNode } = {
    [DEAL_STATUS.Rejected]: <ThumbDownIcon />,
    [DEAL_STATUS.InReview]: <ThumbUpIcon />,
  };

  const [opportunityStatuses, setOpportunityStatuses] = useState<UpdateOpportunityStatusResponse[]>([]);
  const [oppDiscountPercentMAOBMap, setOppDiscountPercentMAOBMap] = useState<Record<string, number>>({});
  const [opportunities, setOpportunities] = useState<OpportunityViewModel[]>([]);
  const [editedMAOBList, setEditedMAOBList] = useState<UpdatedOpportunityField[]>([]);
  const [existingOrg, setExistingOrg] = useState(false);
  const [createOrg, setCreateOrg] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [opportunityId, setOpportunityId] = useState('');
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null);
  const [isContactLoading, setIsContactLoading] = useState<boolean>(true);
  const [isNotesLoading,] = useState<boolean>(false);
  const [opportunityNote, setOpportunityNote] = useState<string>("");
  const [isNotesEditing, setIsNotesEditing] = useState<boolean>(false);
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (opportunities.length > 0) {
      setIsNotesEditing(false);
      const opportunity = opportunities[currentIndex];
      if (!opportunity) {
        if (currentIndex != 0) {
          setCurrentIndex(currentIndex - 1);
        }
        return;
      }
      const thirdPartyId = opportunity.opportunityData.organizationID;
      setOpportunityNote(opportunity.opportunityData.notes);
      if (thirdPartyId) {
        setIsContactLoading(true);
        getThirdPartyProfile(opportunity.opportunityData.organizationID)
          .then((thirdPartyProfile) => {
            if (!thirdPartyProfile || !thirdPartyProfile.contactInfo) {
              setContactInfo(null);
            }
            else {
              const contactInfo = thirdPartyProfile.contactInfo.find((ci => ci.primary)) || thirdPartyProfile.contactInfo[0]
              setContactInfo(contactInfo);
            }
          })
          .catch(() => toast.error("Failed to fetch contact info"))
          .finally(() => {
            setIsContactLoading(false);
          });
      }
      else {
        setIsContactLoading(false);
      }
    }
    else {
      setIsContactLoading(false);
    }
  }, [currentIndex, opportunities])
  const [totalProperty, setTotalProperty] = useState(0);
  const [lenderName, setlenderName] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const isExtendedUpdating = useRef(false);
  const [thridParty, setThirdParty] = useState<ThirdPartyProfile>(
    {
      contactInfo: [
        {
          firstName: '',
          lastName: '',
          name: '',
          email: '',
          gender: '',
          phone: '',
          address1: '',
          city: '',
          state: '',
          postalCode: '',
          timezone: '',
          tags: [],
          primary: true,
          sync_error: []
        },
      ],
      dbas: [],
      enity_type: [],
      other_names: [],
      party_name: "",
      party_type: [],
      permissions: [],
      entity_registration_date: moment(new Date()).format('MM/DD/YYYY')
    }
  );
  useEffect(() => {
    validateForm();
  }, [thridParty]);

  //const { mutate: updateOpportunityStatus, error: updatedStatusError, isLoading: isUpdatingOpportunityStatus } = useOpportunityStatusUpdate();
  const { mutate: updateOpportunityPricing, error: updateOpportunityPricingError, isLoading: isUpdatingOpportunityPricing } = useUpdateOpportunityPricing();
  const isFetchingOpportunities = useIsFetching({ queryKey: [OpportunityQueryKeys] });
  const isUpdating = isFetchingOpportunities > 0 || isExtendedUpdating.current;
  const auth = useAuth();
  const [isTimelineDialogOpen, setIsTimelineDialogOpen] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isUpdating) {
      setLoadingMessage("Fetching Opportunities...")
    }
    else {
      setLoadingMessage(null)
    }
  }, [isUpdating])
  useEffect(() => {
    if (initialOpportunities) {
      setOpportunities([...initialOpportunities]);
      const initialOpportunityStatuses = initialOpportunities.map((opportunity) => ({
        status: opportunity.status,
        opportunityId: opportunity.opportunityId,
      }));

      setOpportunityStatuses(initialOpportunityStatuses);

      const initialDicountPercentMAOBMap: Record<string, number> = {};
      initialOpportunities.forEach((opportunity: any) => {
        if (oppDiscountPercentMAOBMap[opportunity?.opportunityId] === undefined) {
          initialDicountPercentMAOBMap[opportunity.opportunityId] = calculateDiscountPercentForSlider(opportunity.carouselCard.opportunityData.discountPercentMAOB);
        } else {
          initialDicountPercentMAOBMap[opportunity.opportunityId] = oppDiscountPercentMAOBMap[opportunity.opportunityId];
        }
      });
      setOppDiscountPercentMAOBMap(initialDicountPercentMAOBMap);
      if (initialOpportunities.length > 0)
        setCurrentIndex(0);
    }
  }, [initialOpportunities]);

  useEffect(() => {
    const error = updateOpportunityPricingError;
    if (!error) return;

    toast.error('An error occurred while updating the opportunity status. Please try again later.');
    setLoadingMessage(null)
  }, [updateOpportunityPricingError]);

  useEffect(() => {
    if (opportunities.length > 0 && !isUpdatingOpportunityPricing) {
      setLoadingMessage(null);
    }
    else if (isUpdatingOpportunityPricing) {
      setLoadingMessage("Updating Opportunity Price...");
    }
    else {
      //setLoadingMessage("Fetching Opportunities..."); 
    }
  }, [isUpdatingOpportunityPricing]);

  const opportunityStatusChangeHandler = (opportunity: OpportunityViewModel | any, newStatus: DEAL_STATUS): void => {
    if (!auth || !opportunity) return;

    const latestStatus = opportunity.status === newStatus ? DEAL_STATUS.Open : newStatus;
    const isConfirmed = window.confirm(`Are you sure you want to add this to ${latestStatus} items?`);

    if (!isConfirmed) return;

    const cachedOpportunityStatus = { status: latestStatus, opportunityId: opportunity.opportunityId };
    const updatedOpportunityStatuses = [...opportunityStatuses];

    // Find the index of the existing opportunity status
    const index = updatedOpportunityStatuses.findIndex((status) => status.opportunityId === cachedOpportunityStatus.opportunityId);

    if (index !== -1) {
      // Update the existing status
      updatedOpportunityStatuses[index].status = cachedOpportunityStatus.status;
    } else {
      // Add the new status
      updatedOpportunityStatuses.push(cachedOpportunityStatus);
    }

    const updatedOpportunity: UpdateOpportunityStatusInput = {
      opportunityId: opportunity.opportunityId,
      userId: auth.sub,
      totalProperties: opportunity.opportunityData.totalProperties ?? undefined,
      newStatus: latestStatus,
    };
    if (!updatedOpportunity['totalProperties']) {
      toast.error(`Missing field: totalProperties`)
      return
    }
    setLoadingMessage("Updating Opportunity Status...")
    updateOpportunityStatus(updatedOpportunity)
      .then(() => {
        setOpportunityStatuses(updatedOpportunityStatuses);
        setTotalProperty(opportunity.properties.length);
        setOpportunities(opportunities.filter(o => o.opportunityId != opportunity.opportunityId));
        toast.success("Opportunity updated successfully");
        onOpportunityUpdate(opportunity.opportunityId);
      })
      .catch(() => toast.error("Failed to update Opportunity Status!"))
      .finally(() => {
        setLoadingMessage(null);
      })
    //updateOpportunityStatus(updatedOpportunity);
  };
  const validateForm = () => {
    let newErrors: { [key: string]: string } = {};

    if (!thridParty.party_name.trim()) {
      newErrors.party_name = "Third Party Name is required.";
    }

    thridParty.contactInfo.forEach((contact, index) => {
      if (!contact.firstName.trim()) {
        newErrors[`contactInfo-${index}-firstName`] = "First Name is required.";
      }
      if (!contact.lastName.trim()) {
        newErrors[`contactInfo-${index}-lastName`] = "Last Name is required.";
      }
      if (!contact.email.trim() || !/\S+@\S+\.\S+/.test(contact.email)) {
        newErrors[`contactInfo-${index}-email`] = "Valid Email is required.";
      }
      if (!contact.phone) {
        newErrors[`contactInfo-${index}-phone`] = "Phone number is required";
      }
    });

    // thridParty.addresses.forEach((address, index) => {
    //   if (!address.address) {
    //     newErrors[`addresses-${index}-address`] = "Address is required";
    //   }
    //   if (!address.city) {
    //     newErrors[`addresses-${index}-city`] = "City is required";
    //   }
    //   if (!address.state) {
    //     newErrors[`addresses-${index}-state`] = "State is required";
    //   }
    //   if (!address.zipCode) {
    //     newErrors[`addresses-${index}-zipCode`] = "Zip Code is required";
    //   }
    // });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const onSlideChanged = (index: number) => {
    onSlideChange && onSlideChange(index);
    setContactInfo(null);
    setCurrentIndex(index);
  }
  const onThirdPartySave = async (opportunityId: string, totalProperties: number) => {
    const thirdPartyId: string | undefined = opportunities.find((s) => s.opportunityId === opportunityId)?.opportunityData.organizationID;
    if (!validateForm()) {
      return;
    }
    try {
      setCreateOrg(false);
      if (!auth?.sub) throw new Error("Invalid session! Please retry");
      setLoadingMessage("Saving Third Party Organization...");
      const { partyID, ...data } = thridParty;
      data.contactInfo.forEach(p => p.name = `${p.firstName} ${p.lastName}`)
      const savedThirdPartyProfile = !thirdPartyId || !thirdPartyId.length ? // new??
        await addNewThirdPartyProfile(opportunityId, totalProperties, data)
        : await updateThirdPartyProfile(thirdPartyId, opportunityId, totalProperties, data);
      const updatedOpportunities = opportunities.map((o) => {
        if (o.opportunityId === opportunityId) {
          o.opportunityData.organizationID = savedThirdPartyProfile.partyID;
        }
        return o;
      });
      setOpportunities(updatedOpportunities);
      toast.success("Third party organization saved successfully");
    } catch (ex) {
      toast.error("Failed to save third party organization");
    }
    finally {
      setLoadingMessage(null);
    }
  }
  const renderThumbUpDownIcon = (opportunity: OpportunityViewModel | any, status: string): ReactNode => {
    const opportunityStatus = opportunityStatuses.find((s) => s.opportunityId === opportunity.opportunityId)?.status || opportunity.status;
    if (opportunityStatus === status) {
      return activeIcons[status];
    }

    return inactiveIcons[status];
  };

  const handleSliderValueChange = (opportunity: OpportunityViewModel | any, value: number) => {
    const { opportunityID, opportunityMAOB, opportunityMAOS } = opportunity.carouselCard.opportunityData;
    // console.log("opportunityId " + opportunityID)
    // console.log("value " + value)
    const newOppDiscountPercentMAOBMap = { ...oppDiscountPercentMAOBMap };
    newOppDiscountPercentMAOBMap[opportunityID] = value;
    setOppDiscountPercentMAOBMap(newOppDiscountPercentMAOBMap);

    const metric = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, value);

    const updatedValues = { adjustedOpportunityMAOB: metric.adjustOpportunityMAOB };
    onUpdateValue && onUpdateValue(opportunityID, updatedValues);
  };

  const handleMenuItemClick = async (action: string, opportunity: OpportunityViewModel | any) => {
    if (!auth) {
      toast.error('User not authenticated');
      return;
    }
    // Handle the action accordingly
    switch (action) {
      case 'edit':
        // Handle edit action
        break;
      case 'save':
        const { opportunityMAOS, opportunityMAOB, opportunityLeveragesMAOS, opportunityProperties, premiumPercentMAOS, opportunityAIV, opportunityUPB, opportunityAAO } =
          opportunity.carouselCard.opportunityData;
        const metric = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0);
        // const savedSliderValue = (discountPercentMAOB * 0.333) * 100;
        console.log('opportunityProperties ... ' + JSON.stringify(opportunityProperties));
        console.log('discountPercentMAOB ... ' + metric.discountPercentMAOB);

        // console.log("new value AIV2 "+ (calcMOAB) / (opportunity.carouselCard.opportunityData.opportunityLeveragesMAOB.AIV))

        const newOpportunityMAOB = {
          AIV: Number((metric.adjustOpportunityMAOB / opportunityAIV).toFixed(2)),
          UPB: Number((metric.adjustOpportunityMAOB / opportunityUPB).toFixed(2)),
          AAO: Number((metric.adjustOpportunityMAOB / opportunityAAO).toFixed(2)),
        };

        // Handle save action
        const input: UpdateOpportunityPricingInput = {
          dicountPercentMAOB: metric.discountPercentMAOB,
          premiumPercentMAOS,
          adjustedOpportunityMAOB: metric.adjustOpportunityMAOB,
          adjustedOpportunityMAOS: opportunityMAOS,
          opportunityCOCR: metric.percentage,
          opportunityLeveragesMAOS,
          opportunityLeveragesMAOB: newOpportunityMAOB,
          opportunityNetProfitMargin: metric.opportunityNetProfitMargin,
        };

        updateOpportunityPricing({
          input,
          opportunityID: opportunity.opportunityId,
          totalProperties: opportunity.properties.length,
          cognitoID: auth.sub,
        });
        setTotalProperty(opportunity.properties.length);
        break;
      case 'pricing':
        // Handle pricing action
        break;
      case 'existing-organization':
        // Handle existing organization action
        setExistingOrg(true);
        document.body.style.pointerEvents = 'auto';
        break;
      case 'create-organization':
        // Handle create organization action
        setOpportunityId(opportunity.opportunityId);
        setTotalProperty(opportunity.properties.length);
        setCreateOrg(true);
        setThirdParty(prevThirdParty => ({
          ...prevThirdParty,
          contactInfo: [
            {
              firstName: '',
              lastName: '',
              name: '',
              email: '',
              gender: '',
              phone: '',
              address1: '',
              city: '',
              state: '',
              postalCode: '',
              timezone: '',
              tags: [],
              primary: true,
              sync_error: []
            }
          ],
          permissions: [],
          dbas: [],
          enity_type: [],
          party_name: opportunity.lenderName,
          party_type: [],
        }));
        document.body.style.pointerEvents = 'auto';
        break;
      case 'modify-organization':
        document.body.style.pointerEvents = 'auto';
        try {
          setLoadingMessage("Retrieving Third Party Organization...");
          const bodyData = await getThirdPartyProfile(opportunity.opportunityData.organizationID);
          setOpportunityId(opportunity.opportunityId);
          setTotalProperty(opportunity.properties.length);
          setLoadingMessage(null);
          setCreateOrg(true);
          if(!bodyData){
            setThirdParty(prevThirdParty => ({
              ...prevThirdParty,
              contactInfo: [
                {
                  firstName: '',
                  lastName: '',
                  name: '',
                  email: '',
                  gender: '',
                  phone: '',
                  address1: '',
                  city: '',
                  state: '',
                  postalCode: '',
                  timezone: '',
                  tags: [],
                  primary: true,
                  sync_error: []
                }
              ],
              permissions: [],
              dbas: [],
              enity_type: [],
              party_name: opportunity.lenderName,
              party_type: [],
            }));
            break;
          }
          const typeMapping: any = {
            email: { label: 'Email', value: 'email' },
            phone: { label: 'Phone', value: 'phone' },
          };
          const contactInfoOutput = bodyData.contactInfo.map((contact: any) => ({
            ...contact,
            type: typeMapping[contact?.type] || { label: 'Email', value: 'email' },
          }));
          if (contactInfoOutput.length == 1) {
            contactInfoOutput[0].primary = true;
          }
          const filteredPermissions = permissionsArr.filter((permission: { value: string }) => bodyData.permissions.includes(permission.value));
          setThirdParty(prevThirdParty => ({
            ...prevThirdParty,
            partyID: bodyData.partyID,
            // addresses: bodyData.addresses,
            contactInfo: contactInfoOutput,
            permissions: filteredPermissions,
            dbas: bodyData.dbas,
            enity_type: bodyData.enity_type,
            party_name: opportunity.lenderName,
            party_type: bodyData.party_type,
          }));
          // contanctType
        } catch (error) {
          setIsSubmitting(false);
          console.error('Error submitting form:', error);
        }
        break;
      case 'timeline':
        setIsTimelineDialogOpen(true);
        setOpportunityId(opportunity.opportunityId);
        setlenderName(opportunity.lenderName);
        document.body.style.pointerEvents = 'auto';
        // Handle pricing action
        break;
      default:
        break;
    }
  };

  const handleEditMAOBClick = (e: React.MouseEvent, opportunity: OpportunityViewModel | any, field: string, value: number) => {
    e.preventDefault();
    e.stopPropagation(); // Stop event propagation
    const editedMAOBItem = new UpdatedOpportunityField(opportunity.opportunityId, field, value);
    const newEditedMAOBList = [...editedMAOBList];
    newEditedMAOBList.push(editedMAOBItem);
    setEditedMAOBList(newEditedMAOBList);
  };

  const handleSaveMAOBClick = (newItem: UpdatedOpportunityField, opportunity: OpportunityViewModel | any) => {
    const editedMAOB = editedMAOBList.find((item) => item.id === newItem.id);
    if (!editedMAOB) return;

    const newAdjustOpportunityMAOB = newItem.value;

    const { adjustedOpportunityMAOB, opportunityMAOB } = opportunity.carouselCard.opportunityData;

    let updatedValues: Record<string, number> = {};

    if (newAdjustOpportunityMAOB >= opportunityMAOB) {
      updatedValues['discountPercentMAOB'] = (newAdjustOpportunityMAOB / opportunityMAOB - 1) * -1;
      // console.log("here " + updatedValues["discountPercentMAOB"])
    } else {
      updatedValues['discountPercentMAOB'] = 1 - newAdjustOpportunityMAOB / opportunityMAOB;
      // console.log("here " + updatedValues["discountPercentMAOB"])
    }

    // Update opportunities
    if (adjustedOpportunityMAOB !== newAdjustOpportunityMAOB) {
      updatedValues['adjustedOpportunityMAOB'] = newAdjustOpportunityMAOB;
      // console.log("here adj " + updatedValues["adjustedOpportunityMAOB"])
    }

    const sliderDiscountPercentMAOBMap = { ...oppDiscountPercentMAOBMap };
    sliderDiscountPercentMAOBMap[newItem.id] = calculateDiscountPercentForSlider(updatedValues['discountPercentMAOB']);
    setOppDiscountPercentMAOBMap(sliderDiscountPercentMAOBMap);

    onUpdateValue && onUpdateValue(newItem.id, updatedValues);

    // Save the edited values
    setEditedMAOBList(editedMAOBList.filter((item) => item.id !== newItem.id));
  };

  const handleDiscardMAOBClick = (opportunity: OpportunityViewModel) => {
    setEditedMAOBList(editedMAOBList.filter((item) => item.id !== opportunity.opportunityId));
  };

  const calculateDiscountPercentForSlider = (discountPercentMAOB: number): number => {
    // console.log("discountPercent here ... " + (1 - (discountPercentMAOB / MAX_DISCOUNT)) * 100)
    return (1 - discountPercentMAOB / MAX_DISCOUNT) * 100;
  };

  const calculateOpportunityMetrics = (opportunityMAOB: number, opportunityMAOS: number, sliderDiscountPercent: number): OpportunityCalculations => {
    const sliderValue = Number(sliderDiscountPercent / 100);
    let discountPercentMAOB;
    if (sliderValue > 1) {
      discountPercentMAOB = (1 - sliderValue) * MAX_DISCOUNT;
    } else {
      discountPercentMAOB = Math.abs((1 - sliderValue) * MAX_DISCOUNT);
    }
    // console.log("opportunityMAOB here ... " + opportunityMAOB)
    const adjustedMAOB = opportunityMAOB * (1 - discountPercentMAOB); // Bluebutton $
    if (discountPercentMAOB < 0) {
    }
    const profit = opportunityMAOS - adjustedMAOB;
    const opportunityNetProfitMargin = Number((profit / adjustedMAOB).toFixed(2)); // saved value
    const percentage = Number(((profit / (adjustedMAOB * 0.2)) * 100).toFixed(2)); // 3260000 profit / 1670000*0.2 = 33400
    const calcMOAB = opportunityMAOB * (1 - MAX_DISCOUNT + sliderValue * MAX_DISCOUNT); //
    return {
      discountPercentMAOB: Number(discountPercentMAOB),
      opportunityProperties: {},
      adjustOpportunityMAOB: calcMOAB,
      profit,
      opportunityNetProfitMargin,
      percentage,
    };
  };

  const handleSubmitOfferClick = (e: React.MouseEvent, opportunity: OpportunityViewModel | any) => {
    e.preventDefault();
    if (!confirm('Are you sure you want to submit the offer?')) {
      return;
    }

    const updatedOpportunity: UpdateOpportunityStatusInput = {
      opportunityId: opportunity.opportunityId,
      userId: auth!.sub,
      totalProperties: opportunity.properties.length,
      newStatus: DEAL_STATUS.OfferMade,
    };
    //updateOpportunityStatus(updatedOpportunity);
    setLoadingMessage("Updating Opportunity Status...")
    updateOpportunityStatus(updatedOpportunity)
      .then(() => {
        //setOpportunityStatuses(updatedOpportunityStatuses);
        setTotalProperty(opportunity.properties.length);
        setOpportunities(opportunities.filter(o => o.opportunityId != opportunity.opportunityId));
        toast.success("Opportunity updated successfully");
      })
      .catch(() => toast.error("Failed to update Opportunity Status!"))
      .finally(() => {
        setLoadingMessage(null);
      })
  };
  // const addAddress = () => {
  //   setThirdParty(prevThirdParty => ({
  //     ...prevThirdParty,
  //     contactInfo: [
  //       ...prevThirdParty.contactInfo,
  //       {
  //         address1: '',
  //         city: '',
  //         label: '',
  //         primary: prevThirdParty.addresses.length == 0,
  //         state: '',
  //         zipCode: '',
  //       }
  //     ]
  //   }));
  // };
  // const removeAddress = (index: number) => {
  //   if (thridParty.addresses.length > 1) {
  //     setThirdParty(prevThirdParty => ({
  //       ...prevThirdParty,
  //       addresses: [
  //         ...prevThirdParty.addresses.filter((_, i) => i !== index),
  //       ]
  //     }));
  //   }
  // };

  const addContact = () => {
    setThirdParty(prevThirdParty => ({
      ...prevThirdParty,
      contactInfo: [
        ...prevThirdParty.contactInfo,
        {
          firstName: '',
          lastName: '',
          name: '',
          email: '',
          gender: '',
          phone: '',
          address1: '',
          city: '',
          state: '',
          postalCode: '',
          timezone: '',
          tags: [],
          primary: prevThirdParty.contactInfo.length == 0,
          sync_error: []
        }
      ]
    }));
  };
  // primary: prevThirdParty.contactInfo.length == 0,
  const removeContact = (index: number) => {
    if (thridParty.contactInfo.length > 1) {
      setThirdParty(prevThirdParty => ({
        ...prevThirdParty,
        contactInfo: [
          ...prevThirdParty.contactInfo.filter((_, i) => i !== index),
        ]
      }));
    }
  };

  const onSelectChange = (selectedOption: OptionType[], type: string) => {
    const options: string[] = selectedOption
      .filter(d => d.value !== undefined) // Ensures only defined values
      .map(d => d.value as string);
    if (type === 'dbas') {
      setThirdParty(prevThirdParty => ({
        ...prevThirdParty,
        dbas: options
      }));
    }
    if (type === 'enity_type') {
      setThirdParty(prevThirdParty => ({
        ...prevThirdParty,
        enity_type: options
      }));
    }
    if (type === 'party_type') {
      setThirdParty(prevThirdParty => ({
        ...prevThirdParty,
        party_type: options
      }));
    }
  };

  const onLenderNameClicked = (e: any, opportunity: OpportunityViewModel) => {
    e.preventDefault();
    if (opportunity.opportunityData.organizationID == '') {
      handleMenuItemClick('create-organization', opportunity)
    }
    else {
      handleMenuItemClick('modify-organization', opportunity)
    }
  }

  // const handleAddressChange = (index: number, field: keyof any, value: string | boolean) => {
    // const updatedAddresses = [...thridParty.contactInfo];
    // if (field === 'primary' && value === true) {
    //   updatedAddresses.forEach((address) => (address.primary = false));
    // }
    // // updatedAddresses[index][field] = value as string & boolean;
    // setThirdParty(
    //   prevThirdParty => ({
    //     ...prevThirdParty,
    //     contactInfo: updatedAddresses
    //   })
    // );
  // };
  const handleContactChange = (index: number, field: keyof ContactInfo, value: string | boolean) => {
    const updatedContacts = [...thridParty.contactInfo];
    if (field === 'primary' && value === true) {
      updatedContacts.forEach((contact) => (contact.primary = false));
    }
    updatedContacts[index][field] = value as string & boolean & object & any;
    setThirdParty(
      prevThirdParty => ({
        ...prevThirdParty,
        contactInfo: updatedContacts
      })
    );
  };

  const onSaveNote = () => {
    const opportunity = opportunities[currentIndex];
    if (opportunity?.opportunityId) {
      onNoteSave(opportunity.opportunityId, opportunityNote ?? "");
    }
    setIsNotesEditing(false)
  }

  const [openEmailComposer, setOpenEmailComposer] = useState<boolean>(false);
  const onEmailSend = async (opportunityID: string, organizationID: string, email: string, subject: string, body: string) => {
    setLoadingMessage("Seding email...");
    try {
      await sendEmail(opportunityID, organizationID, email, subject, body);
    } catch (e) {
      console.error(e);
      toast.error("Failed to send email!");
    } finally {
      setLoadingMessage(null);
    }
  }

  return (
    <>
      <div>
        <CarouselComponent onSlideChange={onSlideChanged}>
          {opportunities.map((opportunity: any, index: number) => {
            const {
              totalUPB,
              //locations,
              lenderName,
              totalTaxLiens,
              totalVolume,
              totalAmount,
              countOfAssetClassMix,
              countOfBorrower,
              TotalDealFirstLienPositionOriginationAmount,
              totalProperties,
              opportunityData,
            } = opportunity.carouselCard;
            const assetClassMixKeys = Object.keys(countOfAssetClassMix);
            const colorMapAssetClassMix = assetClassMixKeys.map((key, index) => ({
              color: colorList[index % colorList.length],
              description: key,
            }));
            // setTotalProperty(opportunity.carouselCard.totalProperties)
            // console.log("opportunityData " , opportunityData.opportunityID)

            // Transform countOfBorrower to the expected array format
            const borrowerArray = Object.entries(countOfBorrower).map(([name, count]) => ({
              name,
              count,
            }));

            const borrowerNames = borrowerArray.map((borrower) => borrower.name);
            const colorMapBorrower = borrowerNames.map((key, index) => ({
              color: colorList[index % colorList.length],
              description: key,
            }));

            const reformattedCountOfBorrower = borrowerArray.map((borrower) => borrower.count);

            // console.log(" " + JSON.stringify(opportunities))
            const countOfAssetClassMixValues = Object.values(countOfAssetClassMix);

            const { opportunityMAOS, opportunityMAOB, opportunityAIV, opportunityUPB, opportunityAAO, adjustedOpportunityMAOB } = opportunityData;

            const { percentage, adjustOpportunityMAOB } = calculateOpportunityMetrics(opportunityMAOB, opportunityMAOS, oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0);

            // const sliderValue = (oppDiscountPercentMAOBMap[opportunity.opportunityId] || 0) / 100;
            // const profit = opportunityMAOS - adjustedMAOB;
            // const percentage = ((profit / (adjustedMAOB * 0.2)) * 100).toFixed(0);
            // console.log("opportunity.opportunityId " + opportunity.opportunityId)
            const blueButtonValue = adjustOpportunityMAOB;
            const editedMAOB = editedMAOBList.find((item) => item.id === opportunity.opportunityId);
            const opportunityCreatedTimestamp = moment.utc(opportunity.createdDate).local();
            const opportunityUpdatedTimestamp = moment.utc(opportunity.updatedDate).local();
            const created = moment().diff(opportunityCreatedTimestamp, 'hours') <= 24;
            const updated = moment().diff(opportunityUpdatedTimestamp, 'hours') <= 24;
            return (
              <div className="relative carousel-slide px-5 pb-5 pt-3 2xl:px-8" key={`opportunity-${opportunity.opportunityId}`}>
                {(created || updated) &&
                  <div className="absolute inline-block ml-10" >
                    {created ?
                      (<span className='pr-2' title={opportunityCreatedTimestamp.fromNow()}>
                        <Banner text="NEW" />
                      </span>) :
                      (<span className='pr-2' title={opportunityUpdatedTimestamp.fromNow()}>
                        <Banner color='green' text="UPDATED" />
                      </span>)
                    }
                  </div>
                }
                <div className="flex w-full justify-between items-center mb-3 gap-2">
                  {/* Left-aligned content */}
                  <div className="flex items-center gap-2">

                    <div className="flex min-h-8 min-w-8 items-center justify-center rounded-full border text-stone-600">
                      {index + 1}
                    </div>

                  </div>
                  <div className="flex items-center space-x-2">
                    <a
                      className="desktop-opportunity-title underline cursor-pointer uppercase font-bold xl:text-xl 2xl:text-2xl"
                      href="#"
                      onClick={(e) => onLenderNameClicked(e, opportunity)}
                    >
                      {lenderName}
                    </a>
                    <span>
                      {opportunity.opportunityData.organizationID !== '' ? (
                        <CircleCheckIcon color="green" />
                      ) : (
                        <UnCircleCheckIcon color="red" />
                      )}
                    </span>

                  </div>
                  {/* Right-aligned content */}
                  <div className="flex">
                    <CarouselCardActionMenu
                      onItemClick={(action) => handleMenuItemClick(action, opportunity)}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="mr-5 mt-5 w-[32%] 2xl:w-[30%]">
                    {/* <div className="w-full">
                      <p className="thin-title mb-10 mt-8 text-xs uppercase">LOCATIONS</p>
                      <div className="max-h-[238px]">
                        <LocationDotsMap locations={locations} />
                      </div>
                      </div> */}
                    <div className="flex w-full justify-between items-center mb-5">
                      <div className="flex items-center">
                        <p className="thin-title text-xs uppercase">
                          Primary Contact Info
                        </p>
                      </div>
                      <div className='flex items-center'>
                        <Pencil className='h-3 w-3 hover:cursor-pointer' onClick={(e) => onLenderNameClicked(e, opportunity)}></Pencil>
                      </div>
                    </div>
                    {isContactLoading && currentIndex == index && (
                      <motion.div
                        className="w-5 h-5 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"
                        animate={{ rotate: 360 }}
                        transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                      />
                    )}
                    {currentIndex == index && !isContactLoading && (
                      <>
                        <div className="flex w-full">
                          <div className="mr-3 w-">
                            <p className="estimated-value-row-wrapper text-sm 2xl:text-base">
                              <span className="text-[#1d2634]">
                                {contactInfo ? `${contactInfo.firstName} ${contactInfo.lastName}` : "Not available."}
                              </span>
                              <span className="leading-[10px] text-[#929dad]"></span>
                            </p>
                          </div>
                        </div>
                        <div className='flex flex-col justify-start gap-y-2 mt-5 max-w-48'>
                          {contactInfo?.email &&
                            <div
                              className="pill-wrapper flex h-8 cursor-pointer items-center text-white !justify-start gap-2"
                              onClick={() => setOpenEmailComposer(true)}
                            >
                              <Mail className='ml-1' size={16}></Mail>

                              <span className='max-w-48 truncate'>{contactInfo.email}</span>
                            </div>
                          }
                          {contactInfo?.phone &&
                            <div
                              className="pill-wrapper flex h-8 cursor-pointer items-center text-white !justify-start gap-2"
                              onClick={() => window.open("https://app.gohighlevel.com/agency_dashboard/", "_blank")}
                            >
                              <Phone className='ml-1' size={16}></Phone>
                              <span>{contactInfo.phone}</span>
                            </div>
                          }
                        </div>
                      </>
                    )}
                    <div className="flex w-full justify-between items-center mb-5 mt-5  max-w-44">
                      <div className="flex items-center">
                        <p className="thin-title text-xs uppercase">
                          Notes
                        </p>
                      </div>
                      {!isNotesEditing && (<div className='flex items-center'>
                        <Pencil className='h-3 w-3 hover:cursor-pointer' onClick={() => {
                          setOpportunityNote(opportunityData.notes ?? "")
                          setIsNotesEditing(true)
                        }}></Pencil>
                      </div>)}
                    </div>
                    {isNotesLoading && (
                      <motion.div
                        className="w-5 h-5 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"
                        animate={{ rotate: 360 }}
                        transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                      />
                    )}
                    {currentIndex == index && !isNotesEditing && (
                      <div className='flex max-w-44 max-h-48 overflow-y-auto text-sm 2xl:text-base estimated-value-row-wrapper !text-[#1d2634]'>
                        {!opportunityData.notes || opportunityData.notes == "" ? "Not Available" : opportunityData.notes}
                      </div>
                    )}
                    {currentIndex == index && isNotesEditing && (
                      <>
                        <div className="flex w-full  max-w-44">
                          <div className="mr-3 w-">
                            <textarea className='max-w-44 max-h-48 resize-none'
                              value={opportunityNote}
                              onChange={(e) => setOpportunityNote(e.target.value)} />
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center max-w-44">
                          <div
                            className="pill-wrapper flex h-8 cursor-pointer items-center text-white !justify-center mt-3"
                            onClick={onSaveNote}
                          >
                            <span className='max-w-48 truncate'>Save</span>
                          </div>
                          <div
                            className="pill-wrapper flex h-8 cursor-pointer items-center text-white !justify-center mt-3"
                            onClick={() => setIsNotesEditing(false)}
                          >
                            <span className='max-w-48 truncate'>Cancel</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="w-[40%]">
                    <div className="center-card">
                      <div className="center-card-wrapper xl:p-3 2xl:p-5">
                        <div className="mt-2 flex justify-between">
                          <button className="btn" onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.Rejected)}>
                            {renderThumbUpDownIcon(opportunity, DEAL_STATUS.Rejected)}
                          </button>
                          {/* <div className="text-white pill-wrapper xl:w-[70%] h-8">
                              <span className="font-bold">{formatCurrencyShort({ amount: adjustedMAOB, decimals: 2 })}</span>
                              <span className="font-light mx-1">|</span>
                              <span className="font-bold">{percentage}%</span>
                            </div> */}
                          <div
                            className="pill-wrapper flex h-8 cursor-pointer items-center text-white xl:w-[70%]"
                            onClick={(e) => !editedMAOB && handleSubmitOfferClick(e, opportunity)}
                          >
                            {editedMAOB ? (
                              <>
                                <EditableField
                                  initialValue={editedMAOB}
                                  onSave={(value) => handleSaveMAOBClick(value, opportunity)}
                                  onDiscard={() => handleDiscardMAOBClick(opportunity)}
                                />
                                <span className="mx-1 font-light">|</span>
                                <span className="font-bold">{percentage}%</span>
                              </>
                            ) : (
                              <>
                                <div className="flex items-center">
                                  <span title="Click to edit" className="font-bold">
                                    {formatCurrencyShort({ amount: adjustedOpportunityMAOB, decimals: 2 })}
                                  </span>
                                  <span className="cursor-pointer">
                                    <Pencil
                                      onClick={(e) => handleEditMAOBClick(e, opportunity, 'adjustedOpportunityMAOB', adjustedOpportunityMAOB)}
                                      className="ml-2 h-4 w-4 cursor-pointer"
                                    />
                                  </span>
                                </div>
                                <span className="mx-1 font-light">|</span>
                                <span className="font-bold">{percentage}%</span>
                              </>
                            )}
                          </div>
                          <button className="btn" onClick={() => opportunityStatusChangeHandler(opportunity, DEAL_STATUS.InReview)}>
                            {renderThumbUpDownIcon(opportunity, DEAL_STATUS.InReview)}
                          </button>
                        </div>
                        <div className="mt-5 flex">
                          <div className="w-[100%]">
                            <div className="my-0 w-[100%] rounded-lg border border-gray-300 p-2 2xl:w-[100%]">
                              <div className="mt-3 flex items-center">
                                <div className="thin-title mb-3 flex w-[10%] text-sm uppercase">PP</div>
                                <div className="w-[90%]">
                                  {/* <SliderComponent 
                                      initialProgress={Number((opportunity.carouselCard.opportunityData.opportunityMAOS || 0).toFixed(2))} 
                                      fromLabel={formatCurrencyShort({ amount: (opportunity.carouselCard.opportunityData.opportunityMAOB * .7), decimals: 2 })} 
                                      toLabel={formatCurrencyShort({ amount: opportunity.carouselCard.opportunityData.opportunityMAOB, decimals: 2 })} 
                                      onValueChange={handleSliderValueChange}
                                    />     */}
                                  {oppDiscountPercentMAOBMap[opportunity.opportunityId] !== undefined && (
                                    <SliderComponent
                                      initialProgress={oppDiscountPercentMAOBMap[opportunity.opportunityId]}
                                      fromLabel={formatCurrencyShort({ amount: opportunityMAOB * 0.7, decimals: 2 })}
                                      toLabel={formatCurrencyShort({ amount: opportunityMAOB, decimals: 2 })}
                                      onValueChange={(value) => handleSliderValueChange(opportunity, value)}
                                    />
                                  )}
                                </div>
                              </div>
                              {/* <div className="mt-2 text-center text-sm">
                                    Slider Value: {sliderValue / 100}

                                ((((totalOfferPrice*0.7)-totalOfferPrice)*(sliderValue/100))+(totalOfferPrice*0.7))
                                {formatCurrencyShort({ amount: ((((totalOfferPrice*0.7)-totalOfferPrice)*(sliderValue/100))+(totalOfferPrice)), decimals: 2 })}

                                </div>                           */}
                              <div className="flex items-center">
                                <div className="thin-title mb-3 w-[10%] text-sm uppercase">LEV</div>
                                <div className="w-[90%]">
                                  <div className="my-0 flex justify-evenly p-2">
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex text-sm">AIV</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {((blueButtonValue / opportunityAIV) * 100).toFixed(0)}%
                                      </div>
                                      {/* {formatCurrencyShort({ amount: totalOfferPrice * (0.7 + (sliderValue / 100) * 0.3), decimals: 2 })} */}
                                    </div>
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">UPB</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {((blueButtonValue / opportunityUPB) * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">AAO</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {((blueButtonValue / opportunityAAO) * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <ProgressComponent progress={Number((offerRange.percentage || 0).toFixed(2))} fromLabel={formatCurrencyShort({ amount: offerRange.min, decimals: 2 })} toLabel={formatCurrencyShort({ amount: offerRange.max, decimals: 2 })} /> */}
                            </div>
                          </div>
                          {/* <div className="2xl:w-[40%] xl:w-[50%]">
                              <div className="w-full flex flex-col">
                                <div className="uppercase thin-title text-xs mb-3">AS IS VALUE (AIV)</div>
                                <div className="text-base font-medium">{formatCurrencyShort({ amount: Number(totalmarketvalue), decimals: 2 }) || 0}-{formatCurrencyShort({ amount: totalmarketvalue, decimals: 2 }) || 0}</div>
                              </div>
                            </div> */}
                          {/*estimatedAssetValue is totalassetvalue*/}
                        </div>
                        <div className="flex">
                          <div className="w-[100%]">
                            <div className="mt-5 flex w-[100%] flex-col rounded-lg border border-gray-300 p-2 2xl:w-[100%]">
                              <div className="mt-3 flex items-center">
                                <div className="thin-title mb-3 flex w-[10%] text-sm uppercase">SP</div>
                                <div className="w-[90%]">
                                  <ProgressComponent
                                    progress={Number((0).toFixed(2))}
                                    fromLabel={formatCurrencyShort({ amount: opportunity.carouselCard.opportunityData.opportunityMAOS, decimals: 2 })}
                                    toLabel={formatCurrencyShort({
                                      amount: opportunity.carouselCard.opportunityData.opportunityMAOS + opportunity.carouselCard.opportunityData.opportunityMAOS * 0.3,
                                      decimals: 2,
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="flex items-center">
                                <div className="thin-title mb-3 w-[10%] text-sm uppercase">LEV</div>
                                <div className="w-[90%]">
                                  <div className="my-0 flex justify-evenly p-2">
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">AIV</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {(opportunity.carouselCard.opportunityData?.opportunityLeveragesMAOS?.AIV * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">UPB</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {(opportunity.carouselCard.opportunityData?.opportunityLeveragesMAOS?.UPB * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                    <div className="thin-title text-xs uppercase">
                                      <div className="flex">AAO</div>
                                      <div className="estimated-value-row-wrapper mb-1 text-center text-sm font-bold 2xl:text-base">
                                        {(opportunity.carouselCard.opportunityData?.opportunityLeveragesMAOS?.AAO * 100).toFixed(0)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 flex flex-col md:flex-col lg:flex-col">
                          {/* <div className="2xl:w-[55%] xl:w-[90%] lg:w-[70%] flex">
                              <div className="2xl:mr-3 xl:mr-5 xl:ml-3">
                                <div className="uppercase thin-title text-xs mb-3 mt-8">DEFAULT RATE</div>
                                <div className="flex items-start justify-around">
                                  <div className="w-[90px] h-[80px] ">
                                    <CircleChart
                                      series={[100 - lenderDefaultRatePercentage, lenderDefaultRatePercentage]}
                                      title={`${lenderDefaultRatePercentage}%`}
                                      donutSize="70%"
                                      className="2xl:font-base xl:font-xs"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="2xl:w-[60%] lg:w-[30%] lg:ml-3">
                                <div className="uppercase thin-title text-xs mb-3 mt-8 2xl:mr-4 xl:mr-2">DEFAULT AMOUNT</div>
                                <div className="flex items-center 2xl:justify-center xl:justify-left">
                                  <div className="2xl:w-[40%] xl:w-[30%] h-[60px]">
                                    <NumberDisplay topNumber={formatCurrencyShort({ amount: lenderDefaultRateAmount.min, decimals: 2 })} bottomNumber={formatCurrencyShort({ amount: lenderDefaultRateAmount.max, decimals: 2 })} className="font-light xl:text-sm lg:text-xs" />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          <div className="ml-0 lg:px-5 xl:ml-0 2xl:w-[100%]">
                            <div className="flex justify-between">
                              <div className="flex flex-col items-center md:px-3">
                                <div className="thin-title text-xs uppercase xl:mb-2 2xl:mb-3">TOTAL PROP</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="md:mb-2">{opportunityData && opportunityData.opportunityProperties && opportunityData.opportunityProperties.filter((p: { status: string; }) => p.status == "Active").length} of {totalProperties}</div>
                              </div>
                              <div className="flex flex-col items-center md:px-3">
                                <div className="thin-title text-xs uppercase xl:mb-2 2xl:mb-3">
                                  TAX LIEN <span className="2xl:visibility:visible xl:visibility:hidden;"></span>
                                </div>
                                {/* ({totalTaxLiens}) */}
                                <div className="md:mb-2">{totalTaxLiens !== 0 ? <BlueCircleCheckIcon /> : <BlueCircleXIcon />}</div>
                              </div>
                              <div className="flex flex-col items-center md:px-3 lg:px-3">
                                <div className="thin-title text-xs uppercase lg:mb-0 xl:mb-2 2xl:mb-3">HOA LIEN</div>
                                <div className="md:mb-2">
                                  <BlueCircleXIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex h-full items-center justify-center">
                            <div className="justify-around pt-2 xl:ml-0 2xl:w-1/3">
                              <div className="flex w-1/2 items-start space-x-3 md:px-3">
                                <div className="thin-title flex justify-end pr-1 text-xs uppercase">AIV</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                                    {formatCurrencyShort({ amount: opportunityAIV, decimals: 2 }) || 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="justify-around pt-2 lg:px-0 xl:ml-0 2xl:w-1/3">
                              <div className="flex w-1/2 items-start space-x-3 md:px-3">
                                <div className="thin-title flex justify-end pr-1 text-xs uppercase">AO</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                                    {formatCurrencyShort({ amount: TotalDealFirstLienPositionOriginationAmount, decimals: 2 }) || 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="justify-around pt-2 lg:px-0 xl:ml-0 2xl:w-1/3">
                              <div className="flex w-1/2 items-start space-x-3 md:px-3">
                                <div className="thin-title flex justify-end pr-1 text-xs uppercase">UPB</div>
                                {/* ({totalTaxLiens}) */}
                                <div className="flex flex-col space-y-0">
                                  <div className="estimated-value-row-wrapper text-center text-sm font-bold 2xl:text-base">
                                    {formatCurrencyShort({ amount: totalUPB, decimals: 2 }) || 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-8 w-[35%] 2xl:w-[30%]">
                    <div>
                      <div className="mt-5 flex flex-wrap">
                        <div className="thin-title w-1/2 text-xs uppercase">
                          LOANS: DEFAULT / TOTAL
                          <br />
                          VOLUME LAST 24 MONTHS
                        </div>
                        <div className="thin-title w-1/2 text-xs uppercase">LOANS: DEFAULT AMOUNT / TOTAL AMOUNT LAST 24 MONTHS</div>
                      </div>
                      <div className="mt-5 flex">
                        <div className="w-1/2">
                          <div className="w-full">
                            <SemiCircleChart
                              label={<NumberDisplay topNumber={totalVolume.min} bottomNumber={totalVolume.max} />}
                              series={[Number(totalVolume.percentage.toFixed(2))]}
                            />
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="w-full">
                            <SemiCircleChart
                              label={
                                <NumberDisplay
                                  topNumber={formatCurrencyShort({ amount: totalAmount.min, decimals: 2 })}
                                  bottomNumber={formatCurrencyShort({ amount: totalAmount.max, decimals: 2 })}
                                />
                              }
                              series={[Number(totalAmount.percentage.toFixed(2))]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="items-center justify-center ">
                      <div className="mt-5 flex ">
                        <div className="thin-title mb-1 w-1/2 items-center justify-center text-xs uppercase ">COUNT OF ASSET CLASS MIX</div>
                        <div className="thin-title w-1/2 items-center justify-center text-xs uppercase lg:mb-2 ">COUNT OF BORROWER</div>
                      </div>
                      <div className="mt-5 flex justify-between">
                        <div className="w-1/2 flex">
                          <div className="w-full items-center justify-center">
                            <CircleChart
                              className="font-bold "
                              series={countOfAssetClassMixValues}
                              fillColors={colorMapAssetClassMix.map((item) => item.color)}
                              title={countOfAssetClassMixValues.length.toString()}
                              donutSize="60%"
                              labels={assetClassMixKeys}
                            />
                          </div>
                        </div>
                        <div className="w-1/2 flex">
                          <div className="w-full items-center justify-center">
                            <CircleChart
                              className="font-bold"
                              series={Object.values(reformattedCountOfBorrower)}
                              fillColors={colorMapBorrower.map((item) => item.color)}
                              title={reformattedCountOfBorrower.length.toString()}
                              donutSize="55%"
                              labels={borrowerNames}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-between">
                        <div className='w-1/2 justify-center items-center'>
                          {colorMapAssetClassMix.map((item, index) => (
                            <div key={index} className="color-item flex items-center text-xs lg:ml-0 2xl:ml-10">
                              <div className="me-3" style={{ backgroundColor: item.color, width: '10px', height: '10px', borderRadius: '5px' }}></div>
                              <span className=" font-normal text-[#929dad] ">{item.description}</span>
                            </div>
                          ))}
                        </div>
                        <div className='w-1/2'>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </CarouselComponent>
      </div>

      <div>
        {/* Add to existing Third Party */}
        {existingOrg && (
          <Dialog open={existingOrg} onOpenChange={setExistingOrg}>
            <DialogContent className="max-w-screen h-[90%] w-[50%] overflow-x-hidden border-0 p-0 outline-none">
              <DialogHeader>
                <div className="flex min-h-[54px] w-full items-center bg-[#3B4F72] text-xl text-white">
                  <div className="ps-7">
                    <DialogTitle className="space-y-14 text-xl font-bold">Add to existing Third Party</DialogTitle>
                  </div>
                </div>
              </DialogHeader>

              <div className="">
                <div className={`org-search flex items-center `}>
                  <Input
                    iconStart={<SearchIcon className="" />}
                    className={``}
                    value={`search`}
                    placeholder="Search Profiles"
                  // onChange={(e) => handleSearch(e)}  <Searchbar />
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {/* Add to existing Third Party */}

        {/* Create new Third Party */}
        <LoadingModal open={(loadingMessage != null && !createOrg)} message={loadingMessage || ""} />

        {createOrg && (
          <Dialog open={createOrg} onOpenChange={setCreateOrg}>
            <DialogContent className="max-w-screen h-[80%] w-[50%] overflow-x-hidden border-0 p-0 outline-none flex flex-col">
              <DialogHeader>
                <div className="mb-5 flex min-h-[54px] w-full items-center bg-[#3B4F72] text-xl text-white">
                  <div className="ps-7">
                    <DialogTitle className="space-y-14 text-xl font-bold ">{thridParty.partyID ? 'Modify Third Party' : 'Create new Third Party'}</DialogTitle>
                  </div>
                </div>
              </DialogHeader>
              <div className="flex-grow overflow-y-auto p-4">
                <div className="icon-element mb-3 flex items-center gap-2">
                  <label className="after:content-['*'] after:text-red-500 after:ml-1">Third Party Name:</label>
                  <input type="text" placeholder="Enter Third Party Name" id="party_name"
                    value={thridParty.party_name}
                    style={{ border: errors[`party_name`] ? "1px solid red" : "" }}
                    onChange={(e) => setThirdParty(prevThirdParty => ({
                      ...prevThirdParty,
                      party_name: e.target.value
                    }))}
                    className="icon-input-box w-full" />
                </div>
                {/* Contanct Info */}
                <div className="mb-3">
                  <div className="flex">
                    <h2 className="mb-2 flex-1 text-lg font-semibold">
                      Contact Info
                    </h2>
                    <span onClick={addContact} className="block cursor-pointer text-sm font-medium text-blue-600">
                      + Add Contact
                    </span>
                  </div>
                  {thridParty.contactInfo.map((contact, index) => {
                    return (
                      <div className="icon-element-sectoin relative mb-3" key={index}>
                        {thridParty.contactInfo.length > 1 && (
                          <div className="absolute right-0 top-0 cursor-pointer" onClick={() => removeContact(index)}>
                            <UnCircleCheckIcon color="black" width={18} height={18} />
                          </div>
                        )}

                        <div className="grid grid-cols-2 gap-4">
                          {/* First Name */}
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label className="after:content-['*'] after:text-red-500 after:ml-1">First Name:</label>
                            <input
                              type="text"
                              placeholder="Enter First Name"
                              id={`first-name-${index}`}
                              value={contact.firstName || ""}
                              className="icon-input-box flex-1"
                              style={{ border: errors[`contactInfo-${index}-firstName`] ? "1px solid red" : "" }}
                              onChange={(e) => handleContactChange(index, 'firstName', e.target.value)}
                            />
                          </div>
                          {/* Last Name */}
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label className="after:content-['*'] after:text-red-500 after:ml-1">Last Name:</label>
                            <input
                              type="text"
                              placeholder="Enter Last Name"
                              id={`last-name-${index}`}
                              value={contact.lastName || ""}
                              className="icon-input-box flex-1"
                              style={{ border: errors[`contactInfo-${index}-lastName`] ? "1px solid red" : "" }}
                              onChange={(e) => handleContactChange(index, 'lastName', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {/* Email */}
                          <div className="grid mt-1 mb-1 grid-cols-[100px_auto] items-center gap-2 relative">
                            <label className="after:content-['*'] after:text-red-500 after:ml-1">Email:</label>
                            <input
                              type="email"
                              placeholder="Enter Email"
                              id={`email-${index}`}
                              value={contact.email || ""}
                              className="icon-input-box flex-1 w-full pr-10"
                              style={{ border: errors[`contactInfo-${index}-email`] ? "1px solid red" : "" }}
                              onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                            />
                            <button
                              type="button"
                              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-blue-500"
                            >
                              <Mail size={16} />
                            </button>
                          </div>

                          {/* Phone */}
                          <div className="grid mt-1 mb-1 grid-cols-[100px_auto] items-center gap-2 relative">
                            <label className="after:content-['*'] after:text-red-500 after:ml-1">Phone:</label>
                            <input
                              type="tel"
                              placeholder="Enter Phone Number"
                              id={`phone-${index}`}
                              value={contact.phone || ""}
                              className="icon-input-box flex-1 w-full pr-10"
                              style={{ border: errors[`contactInfo-${index}-phone`] ? "1px solid red" : "" }}
                              onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
                            />
                            <button
                              type="button"
                              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-blue-500"

                            >
                              <Phone size={16} />
                            </button>
                          </div>
                          {/* <div className="icon-element-sectoin relative mb-3" data-id={index} key={index}> */}
                          {/* {thridParty.addresses.length > 1 && (
                          <div className="absolute right-0 top-0 cursor-pointer" onClick={() => removeAddress(index)}>
                            <UnCircleCheckIcon color="black" width={18} height={18} />
                          </div>
                        )} */}



                        </div>
                        <div className="grid grid-cols-2 gap-4">
                        <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label htmlFor="partyType">Gender : </label>
                            <Select
                              id="gender"
                              isClearable
                              options={gender}
                              onChange={(selectedOption) => handleContactChange(index, 'gender', selectedOption ? selectedOption.value : '')}
                              value={gender.find((option: any) => option.value === contact.gender) || null}
                              className="icon-multi-select-box"
                              classNamePrefix="select"
                              noOptionsMessage={() => 'No Party Type available'}
                              placeholder="Select Gender"
                            />
                          </div>
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label htmlFor="timeZone">Timezone : </label>
                            <TimezoneSelect
                              className="timezone-picker"
                              value={contact.timezone || currentTimezone}
                              onChange={(selected) => handleContactChange(index, 'timezone', selected.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="grid mb-0 grid-cols-[100px_auto] items-center gap-2">
                            <label>Street Address:</label>
                            <input
                              type="text"
                              placeholder="Enter Address"
                              id={`address-${index}`}
                              value={contact.address1}
                              className="icon-input-box flex-1 mb-2"
                              onChange={(e) => handleContactChange(index, 'address1', e.target.value)}
                            />
                          </div>
                          <div className="grid mb-0 grid-cols-[100px_auto] items-center gap-2">
                            <label>City:</label>
                            <input
                              type="text"
                              placeholder="Enter City"
                              id={`city-${index}`}
                              value={contact.city}
                              className="icon-input-box flex-1 mb-2"
                              onChange={(e) => handleContactChange(index, 'city', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label>State:</label>
                            <input
                              type="text"
                              placeholder="Enter State"
                              id={`state-${index}`}
                              value={contact.state}
                              className="icon-input-box flex-1"
                              onChange={(e) => handleContactChange(index, 'state', e.target.value)}
                            />
                          </div>
                          <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                            <label>Zipcode:</label>
                            <input
                              type="text"
                              placeholder="Enter Zipcode"
                              id={`zipCode-${index}`}
                              value={contact.postalCode}
                              className="icon-input-box flex-1"
                              onChange={(e) => handleContactChange(index, 'postalCode', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="grid mt-1 col-span-4 grid-cols-[100px_auto] items-center gap-2">
                            <label>Tags:</label>
                            <CreatableSelect
                              id="tags"
                              isMulti
                              isClearable
                              options={[]}
                              value={contact.tags || []}
                              className="icon-multi-select-box"
                              isSearchable={true}
                              classNamePrefix="select"
                              onChange={(selectedOption: any) => handleContactChange(index, 'tags', selectedOption)}
                              placeholder="Add new tags"
                              noOptionsMessage={() => 'Add new tags'}
                            />
                          </div>
                        </div>
                        {thridParty.contactInfo.length > 1 &&
                          (<div className="flex items-center space-x-2">
                            <Checkbox
                              checked={contact.primary}
                              id={`primary-${index}`}
                              className="h-[18px] w-[18px] border-2 border-black data-[state=checked]:bg-transparent"
                              onCheckedChange={(e) => handleContactChange(index, 'primary', e)}
                            />
                            <div className="grid gap-1.5 leading-none">
                              <label
                                htmlFor={`primary-${index}`}
                                className="text-sm leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-neutral-400"
                              >
                                Primary
                              </label>
                            </div>
                          </div>)
                        }
                      </div>
                    );
                  })}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                    <label htmlFor="dbas">DBAS:</label>
                    <CreatableSelect
                      id="dbas"
                      isMulti
                      isClearable
                      options={[]}
                      value={thridParty.dbas.map((e: string) => ({ label: e, value: e })) || []}
                      className="icon-multi-select-box"
                      isSearchable={true}
                      classNamePrefix="select"
                      onChange={(value: any) => onSelectChange(value, 'dbas')}
                      placeholder="Create new DBAS"
                      noOptionsMessage={() => 'No DBAS available'}
                    />
                  </div>
                  <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                    <label htmlFor="entryType">Entry Type:</label>
                    <Select
                      id="entryType"
                      isMulti
                      isClearable
                      options={entryType}
                      onChange={(value: any) => onSelectChange(value, 'enity_type')}
                      value={entryType.filter((entry: { value: string }) => thridParty.enity_type.includes(entry.value)) || []}
                      className="icon-multi-select-box"
                      isSearchable={true}
                      classNamePrefix="select"
                      placeholder="Select Entry Type"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="grid mb-1 grid-cols-[100px_auto] items-center gap-2">
                    <label htmlFor="partyType">Party Type:</label>
                    <Select
                      id="partyType"
                      isMulti
                      isClearable
                      options={partyType}
                      onChange={(value: any) => onSelectChange(value, 'party_type')}
                      value={partyType.filter((party: { value: string }) => thridParty.party_type.includes(party.value)) || []}
                      className="icon-multi-select-box"
                      isSearchable={true}
                      classNamePrefix="select"
                      noOptionsMessage={() => 'No Party Type available'}
                      placeholder="Select Party Type"
                    />
                  </div>
                  <div className="grid grid-cols-[100px_auto] items-center gap-2">
                    <label htmlFor="registrationDate">Registration Date:</label>
                    <DatePicker
                      id="registrationDate"
                      showIcon
                      selected={thridParty.entity_registration_date ? new Date(thridParty.entity_registration_date) : new Date()}
                      onChange={(date: any) => setThirdParty(prevThirdParty => ({
                        ...prevThirdParty,
                        entity_registration_date: date
                      }))}
                      icon={<CalendarIcon />}
                      maxDate={new Date()}
                      className="icon-input-box icon-date-picker"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
              </div>
              <DialogFooter className="relative my-1 bottom-0 flex w-full flex-col items-center !justify-center py-1">
                {/* Button Container */}
                <div className="relative flex w-full justify-center">
                  {/* Error Message (Top-Right) */}
                  <button
                    type="submit"
                    onClick={() => onThirdPartySave(opportunityId, totalProperty)}
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    data-tooltip-content={`Missing mandatory fields: ${Object.values(errors).join(", ")}`}
                    className={`${isSubmitting || Object.keys(errors).length > 0 ? 'disabled:cursor-not-allowed disabled:opacity-50' : ''} mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700`}
                  >
                    Submit
                  </button>
                  <button
                    disabled={isSubmitting}
                    type="button"
                    className="mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                    onClick={() => setCreateOrg(false)}
                  >
                    Cancel
                  </button>
                </div>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        )}
        {/* Create new Organization */}

        {isTimelineDialogOpen && (<TimelineDialog lenderName={lenderName} opportunityId={opportunityId} isOpen={isTimelineDialogOpen} toggleModal={() => { setIsTimelineDialogOpen(!isTimelineDialogOpen) }} >

        </TimelineDialog>)}
      </div>
      <div className="w-[70%]">
        {openEmailComposer && opportunities[currentIndex] && opportunities[currentIndex].opportunityId && opportunities[currentIndex].opportunityData.organizationID &&
          <EmailComposer
            open={openEmailComposer && opportunities[currentIndex] && opportunities[currentIndex].opportunityId && opportunities[currentIndex].opportunityData.organizationID}
            email={contactInfo?.email ?? ""}
            opportunityID={opportunities[currentIndex].opportunityId!}
            organizationID={opportunities[currentIndex].opportunityData.organizationID!}
            onClose={() => setOpenEmailComposer(false)}
            onSend={onEmailSend}
          />
        }
      </div>
    </>
  );
};

export default DealsDesktop;
// name - get from main card - landername
// permission - multi select

// organizationID in opportunityData for verification check = verified or not

// street address - address
// apt/suite - addressLine2
// description - label
// remove email from top
// add email & phone validation
// 3rd option in main list, modify thord party
