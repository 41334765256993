import React from 'react';

interface SmallValueProps {
  children: React.ReactNode;
  className?: string;
}

const SmallValue: React.FC<SmallValueProps> = ({ children, className }) => {
  return (
    <p className={`text-sm ${className || ''}`}>
        {children}
    </p>
  );
};

export default SmallValue;