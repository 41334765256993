import React from "react";
import { useEffect, useState } from "react";
import { MAOBCalculation } from "../../models/PricingRules";
import { EditableFractionPercentageField } from "../EditableNumberField";
import { formatToPerc } from "@/lib/utils";

const selectLesserOfOptions = {
  true: "Lesser Of",
  false: "Greater Of",
};

const defaultMAOBCalculation: MAOBCalculation = {
  id:"",
  selectLesserOf: true,
  aaoPerc: 40,
  aivPerc: 40,
  upbPerc: 40,
};

type MAOBCalculationPercFields = "aaoPerc" | "aivPerc" | "upbPerc";

interface MAOBCalculationStageProps {
  ltvThreshold: number;
  lessThenOrEqualLTVThreshold: boolean;
  defaultValue?: MAOBCalculation;
  value?: MAOBCalculation;
  onValueChanged: (value: MAOBCalculation) => void;
  className?: string;
}

export const MAOBCalculationStage: React.FC<MAOBCalculationStageProps> = ({
  ltvThreshold,
  lessThenOrEqualLTVThreshold,
  defaultValue,
  value: originalValue,
  onValueChanged,
  className,
}) => {
  const [maobCalculation, setMAOBCalculation] = useState<MAOBCalculation>(originalValue || defaultValue || defaultMAOBCalculation);

  useEffect(() => {
    setMAOBCalculation(originalValue || defaultValue || defaultMAOBCalculation);
  }, [originalValue]);

  const onSelectLesserOfChanged = (value: boolean) => {
    const newMAOBCalculation = { ...maobCalculation, selectLesserOf: value };
    setMAOBCalculation(newMAOBCalculation);
    // setMAOBCalculation(prev => ({ ...prev, selectLesserOf: value }));
    onValueChanged?.(newMAOBCalculation);
  }
  const onPercChanged = (key: MAOBCalculationPercFields, value: number) => {
    const newMAOBCalculation = { ...maobCalculation, [key]: value };
    setMAOBCalculation(newMAOBCalculation);
    // setMAOBCalculation(prev => ({ ...prev, [key]: value }));
    onValueChanged?.(newMAOBCalculation);
  }

  return (
    <div
      className={`p-4 ${lessThenOrEqualLTVThreshold ? "bg-teal-50" : "bg-red-50"} shadow-lg border border-gray-400 rounded-sm ${className || ""}`}
    >
      <div className="font-base text-sm text-center mb-0">{lessThenOrEqualLTVThreshold ? "Low" : "High"} Risk</div>
      <div className="font-base text-sm text-center mb-5">{`(LTV ${lessThenOrEqualLTVThreshold ? "≤" : ">"} ${formatToPerc(ltvThreshold)})`}</div>
      <div className="flex items-center justify-center">
        <select
          value={maobCalculation.selectLesserOf ? "true" : "false"}
          className="text-gray-600 text-xs mb-2 p-1 border border-gray-300 rounded-sm text-center w-auto"
          onChange={e => onSelectLesserOfChanged(e.target.value === "true")}
        >
          {Object.entries(selectLesserOfOptions).map(([key, value]) =>
            <option value={key}>{value}</option>
          )}
        </select>
      </div>
      <table className="border-collapse table-auto w-auto">
        <tbody>
          {[
            { label: "AAO", key: "aaoPerc", value: maobCalculation.aaoPerc },
            { label: "AIV", key: "aivPerc", value: maobCalculation.aivPerc },
            { label: "UPB", key: "upbPerc", value: maobCalculation.upbPerc },
          ].map(({ label, key, value }) => (
            <tr key={key}>
              <td className="border border-transparent bg-white text-xs font-bold pl-2 whitespace-nowrap">
                {label}
              </td>

              <td className="border border-transparent bg-white text-center max-w-[10rem]">
                <EditableFractionPercentageField
                className="text-xs"
                  defaultValue={defaultValue?.[key as MAOBCalculationPercFields]}
                  value={value}
                  onChange={v => onPercChanged(key as MAOBCalculationPercFields, v)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
