import clsx from 'clsx';
import React from 'react';
import ApexChart from 'react-apexcharts';

interface CircleChartProps {
    series: any;
    className?: string;
    fillColors?: string[];
    donutSize?: string;
    title?: string;
    labels?: string[];
}

const CircleChart: React.FC<CircleChartProps> = ({
    series,
    labels,
    title,
    className,
    fillColors = ['#d2d4d6', '#13BBF6'],
    donutSize = "80%"
}) => {
    const options = {
        fill: {
            colors: fillColors,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: donutSize,
                },
                expandOnClick: false,
            },
        },
        chart: {
            fontFamily: 'Inter, sans-serif',
            dropShadow: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
            y: {
                formatter: (value: number, { seriesIndex }: { seriesIndex: number }) => {
                    if (labels && labels[seriesIndex] !== undefined) {
                        return `${labels[seriesIndex]}: ${value}`;
                    }
                    return `${value}`;
                }
            }
        },
        legend: {
            show: false,
        },
        grid: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
            },
        },
        stroke: {
            width: 0,
        },
        dataLabels: {
            enabled: false,
        },
    };

    return (
        <div className={clsx('relative', className)}>
            <ApexChart options={options} series={series} type="donut" />
            <div className="absolute inset-0 flex items-center justify-center font-sans">
                {title}
            </div>        
        </div>
    );
};

export default CircleChart;