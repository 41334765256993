import * as React from 'react';
import { TriangleAlert } from 'lucide-react';


interface AlertStickyToastProps {
  open: boolean;
  message: string;
}

const AlertStickyToast: React.FC<AlertStickyToastProps> = ({ message, open }) => !open ? undefined :
  <div
    className="fixed bottom-0 right-0 m-4 bg-orange-600 z-50 text-white px-4 py-2 rounded-lg shadow-lg 
    transform -translate-x-full animate-slide-in flex items-center justify-center opacity-85"
  >
    <TriangleAlert className="w-6 h-6" />
    <p className="pl-2">
      {message.split(/\n|\\n/).map((m, i) => <div key={i}>{m}</div>)}
    </p>
  </div>

export default AlertStickyToast;