import React from "react";
import { EditableFractionPercentageField } from "../EditableNumberField";

interface EditablePercentBoxProps {
  title?: string;
  defaultValue?: number;
  value?: number;
  onChange?: (value: number) => void;
}

export const EditablePercentBox: React.FC<EditablePercentBoxProps> = ({
  title,
  defaultValue,
  value,
  onChange
}) =>
  <div className="p-4 w-full bg-white shadow-lg rounded-sm shadow-custom-button border border-gray-400" >
    <div className="font-base text-sm text-center mb-5">{title}</div>
    <EditableFractionPercentageField defaultValue={defaultValue} value={value} onChange={onChange} className="text-xs"/>
  </div>;