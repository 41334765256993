import { Check, X } from 'lucide-react';
import React, { LegacyRef } from 'react';

interface CreateNewSourceProps {
  inputRef?: LegacyRef<HTMLInputElement>;
  inputRefValue?: LegacyRef<HTMLInputElement>;
  sourceName: string;
  setSourceName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setNumberValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSourceURL: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: { [key: string]: string } | null;
  numberValue: number;
  handleCancel: () => void;
  handleSave: () => void;
  sourceURL: string;
}

function CreateNewSource({
  inputRef = null,
  inputRefValue = null,
  sourceName,
  setSourceName,
  setNumberValue,
  setSourceURL,
  error,
  numberValue,
  handleCancel,
  handleSave,
  sourceURL,
}: CreateNewSourceProps) {
  return (
    <>
      <tr className="hover:bg-gray-50">
        <td className="w-[10px] p-2">
          <input type="radio" name="priceOption" disabled={true} className="h-5 w-5 border-gray-300 text-blue-600 focus:ring-blue-500" />
        </td>
        <td className="relative w-[150px] p-2">
          <input
            type="text"
            ref={inputRef}
            value={sourceName}
            onChange={setSourceName}
            className="h-[1.5em] w-full rounded border px-1 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Source name"
          />
          {Object.keys(error ? error : {}).length != 0 && <p className=" block text-xs text-red-500">{error ? error['source'] : ''}</p>}
        </td>
        <td className="relative w-[200px] p-2">
          <div className="flex">
            <span className="left-0 flex items-center text-sm">$</span>
            <input
              type="number"
              ref={inputRefValue}
              value={numberValue}
              onChange={setNumberValue}
              className="ml-1 h-[1.5em] w-[8em] rounded border px-1 py-3 text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Exact amount"
            />
          </div>
          {Object.keys(error ? error : {}).length != 0 && <p className="block text-xs text-red-500">{error ? error['exact'] : ''}</p>}
        </td>
        <td className="w-[100px] p-2">
          <div className="flex items-center space-x-2">
            <button onClick={handleCancel} className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-blue-500 bg-white text-blue-500" title="Cancel">
              <X className="h-5 w-5" />
            </button>
            <button onClick={handleSave} className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-blue-500 bg-white text-blue-500" title="Add">
              <Check className="h-5 w-5" />
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={2}>
          <input
            type="text"
            value={sourceURL}
            onChange={setSourceURL}
            className="ml-2 h-[1.5em] w-full rounded border px-1 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="https://www.example.com"
          />
          {Object.keys(error ? error : {}).length != 0 && <p className="block text-xs text-red-500">{error ? error['sourceURL'] : ''}</p>}
        </td>
      </tr>
    </>
  );
}

export default CreateNewSource;
