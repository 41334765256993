import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export interface ComboBoxOption {
  label: string;
  value: string;
}

interface ComboBoxProps {
  options: ComboBoxOption[];
  onSelect: (selected: ComboBoxOption) => void;
  defaultValue?: string;
}

const ComboBox: React.FC<ComboBoxProps> = ({ options, onSelect, defaultValue = null }) => {
  const [query, setQuery] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownStyle, setDropdownStyle] = useState({ top: '0px', left: '0px', width: '0px' });

  const comboboxRef = useRef<HTMLDivElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    // console.log('defaultValue', defaultValue);
    if (defaultValue) {
      const option = options.find(option => option.value === defaultValue);

      if (option?.label) {
        setQuery(option.label);
      }
    }
  }, [defaultValue, options]);

  const filteredOptions = options.filter(option =>
    !query || option.label.toLowerCase().includes(query.toLowerCase())
  );
  useEffect(() => {
    if (isOpen && comboboxRef.current) {
      const rect = comboboxRef.current.getBoundingClientRect();
      setDropdownStyle({
        top: `${rect.bottom}px`,
        left: `${rect.left + window.scrollX}px`,  // Account for page scroll
        width: `${rect.width}px`,  // Match dropdown width with the container
      });
    }
  }, [isOpen]);
  const handleSelectOption = (option: ComboBoxOption) => {
    setQuery(option.label);
    setIsOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (comboboxRef.current && 
      !comboboxRef.current.contains(event.target as Node) && (ulRef.current && !ulRef.current.contains(event.target as Node))) {
      setIsOpen(false);
    }
  };

  const handleClear = () => {
    console.log('clear');
    setQuery('');
    setIsOpen(true);
    onSelect({ label: '', value: '' });
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const enableScroll = (e: Event) => e.stopPropagation();
    
    const dropdown = ulRef.current;
    dropdown?.addEventListener("wheel", enableScroll, { passive: false });

    return () => dropdown?.removeEventListener("wheel", enableScroll);
}, []);


  const handlerSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setIsOpen(true);
  }
  const dropdownContent = isOpen && filteredOptions.length > 0 && (
    <ul
      ref={ulRef}
      onWheelCapture={(e) => {
        console.log("Wheel event detected!", e);
        e.stopPropagation(); // Ensure event doesn't bubble up
      }}
      className="absolute z-[9999] bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto"
      style={{
        top: dropdownStyle.top,
        left: dropdownStyle.left,
        width: dropdownStyle.width,
        pointerEvents: 'auto', // Ensure pointer events are allowed
      }}
    >
      {filteredOptions.map((option) => (
        <li
          style={
            {pointerEvents: 'auto' }  
          }
          key={option.value}
          className="px-4 py-2 cursor-pointer hover:bg-blue-500 hover:text-white"
          onClick={() => handleSelectOption(option)}
        >
          {option.label}
        </li>
      ))}
    </ul>
  );

  
  return (
    <div className="relative w-64" ref={comboboxRef}>
      <div className="relative flex items-center border border-gray-300 rounded-md focus-within:ring-2 focus-within:ring-blue-500">
        <input
          type="text"
          className="w-full px-4 py-2 focus:outline-none"
          placeholder="Select an option"
          value={query}
          onChange={handlerSearchChange}
          onClick={() => setIsOpen(!isOpen)}
        />
        {query && (
          <button
            type="button"
            title="Clear"
            className="absolute right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={handleClear}
          >
            &times;
          </button>
        )}
      </div>
      {ReactDOM.createPortal(dropdownContent, document.body)}
    </div>
  );
};

export default ComboBox;