import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ensureAbsoluteUrl, formatCurrency, formatToUSDate } from "@/lib/utils";
import {Plus, Trash } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { deletePropertyValueSource, fetchAIVValues as fetchPropertyValues, savePropertyValueSource, updatePricingForPropertyChanges } from "../pages/deals/services/dealService";
import { toast } from "react-toastify";
import DialogLoading from "@/components/ui/dialog-loading";
import { OpportunityData, OpportunityDataProperties } from "../pages/deals/view-models/CarouselCardViewModel";
import React from "react";
import { useAuth } from "@/components/AuthProvider";
import CreateNewSource from "./CreateNewSource";
import ConfirmationBox from "./ConfirmationBox";

export enum PropertyValuationType {
    AIV, UPB, OA
}

export interface PropertyUpdate {
    propertyID: string,
    operation: string,
    attribute: string,
    value: number | string,
    newDataSourceID?: string,
    oldDataSourceID?: string
}

export interface PropertyValuationCalculation {
    id: string
    source: string;
    field: string;
    requestField: string
    value: number;
    valueType: string;
    status: string;
    createdAt?: string;
    sourceURL?: string;
}
interface PropertiesValueChangeDialogProps {
    property?: OpportunityDataProperties;
    totalProperties: number
    propertyId: string;
    opportunityId: string;
    isOpen: boolean;
    type: PropertyValuationType;
    toggleModal: () => void;
    onChange: (opportunityId: string, opportunityData: OpportunityData) => void;
}

const PropertiesValueChangeDialog: React.FC<PropertiesValueChangeDialogProps> = ({ isOpen, type, totalProperties, propertyId, opportunityId, toggleModal, onChange }) => {
    const [isNew, setIsNew] = useState<boolean>(false);
    const [propertyValuationList, setPropertyValuationList] = useState<PropertyValuationCalculation[]>([]);
    const [error, setError] = useState<{ [key: string]: string } | null>();
    const [selectedOption, setSelectedOption] = useState<string>();
    const [selectedPrevOption, setSelectedPrevOption] = useState<string>();
    const [sourceName, setSourceName] = useState<string>("");
    const [sourceURL, setSourceURL] = useState<string>("");
    const [loadingMessage, setLoadingMessage] = useState<string>("Please wait....");
    const [numberValue, setNumberValue] = useState<number>(0);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const inputRefValue = useRef<HTMLInputElement | null>(null);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const auth = useAuth();
    const prefilledSources = ["Trulia", "Realtor", "Zillow", "Redfin", "Injew"];
    const [editPrefill, setEditPrefill] = useState('')
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [toBeDeleteSource, setToBeDeleteSource] = useState<PropertyValuationCalculation | null>(null)

    useEffect(() => {
        const houseCanary = propertyValuationList.find(option => option.source === "House Canary");
        const crawlBee = propertyValuationList.find(option => option.source === "CrawlBee");
        let selectedSource = "";
        let selectedSourceId = "";
        if (houseCanary) {
            selectedSource = houseCanary.source;
            selectedSourceId = houseCanary.id;
        }
        else if (crawlBee) {
            selectedSource = crawlBee.source;
            selectedSourceId = crawlBee.id;
        }
        // If not any of them select 1st one 
        else if (propertyValuationList.length > 0) {
            selectedSource = propertyValuationList[0].source;
            selectedSourceId = propertyValuationList[0].id;
        }
        setSelectedOption(selectedSource);
        setSelectedPrevOption(selectedSourceId);

    }, [propertyValuationList])
    useEffect(() => {
        switch (type) {
            case PropertyValuationType.AIV:
                setDialogTitle("Select AIV Source");
                break;
            case PropertyValuationType.UPB:
                setDialogTitle("Select UPB Source");
                break;
            case PropertyValuationType.OA:
                setDialogTitle("Select OA Source");
                break;
        }
    }, [type]);

    useEffect(() => {
        if (opportunityId != "" && propertyId != "") {
            setLoadingState(true);
            setLoadingMessage("Please wait...");
            let field = ""
            let saveField = ""
            switch (type) {
                case PropertyValuationType.AIV:
                    field = "estimated_value";
                    saveField = "AIV"
                    break;
                case PropertyValuationType.UPB:
                    field = "estimated_mortgage_balance";
                    saveField = "UPB"
                    break;
                case PropertyValuationType.OA:
                    field = "firstLienPostionMortgageOrginationAmount";
                    saveField = "OA"
                    break;
            }
            fetchPropertyValues(opportunityId, propertyId, field).then((allData: any) => {
                const aivData: PropertyValuationCalculation[] = [];
                allData.forEach((data: { id: any; value: any; source: any; createdAt: string; valueType: string; status: string; sourceURL:string; }) => {
                    aivData.push({
                        id: data.id,
                        value: data.value,
                        field: field,
                        requestField: saveField,
                        source: data.source,
                        valueType: data.valueType,
                        status: data.status,
                        createdAt: data.createdAt?.length ? formatToUSDate(new Date(data.createdAt)) : "",
                        sourceURL:data.sourceURL
                    })
                });
                setPropertyValuationList(aivData);

            }).catch(() => {
                toast.error('Failed to fetch AIV sources');
                toggleModal();
            }).finally(() => {
                setLoadingState(false);
            })
        }

    }, [propertyId, opportunityId, type]);

    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
        setError(null); // Clear error when a selection is made
    };


    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!sourceName || sourceName.trim() === "") {
            newErrors.source = "Source is required.";
        }
        else if (propertyValuationList.some((item) => item.source.toLowerCase() === sourceName.toLowerCase())) {
            newErrors.source = "Source name already exists.";
        }
        if (numberValue === undefined || numberValue === null || numberValue <= 0) {
            newErrors.exact = "Amount is required and must be greater than 0.";
        }
        if (!sourceURL || !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(sourceURL)) {
            newErrors.sourceURL = "Source URL is required and must be a valid URL.";
        }
        setError(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSave = () => {
        if (validateForm()) {
            setLoadingState(true);
            setLoadingMessage("Please wait...");
            let field = "";
            let saveField = "";
            switch (type) {
                case PropertyValuationType.AIV:
                    field = "estimated_value";
                    saveField = "AIV"
                    break;
                case PropertyValuationType.UPB:
                    field = "estimated_mortgage_balance";
                    saveField = "UPB"
                    break;
                case PropertyValuationType.OA:
                    field = "firstLienPostionMortgageOrginationAmount";
                    saveField = "OA"
                    break;
            }
            savePropertyValueSource(opportunityId, propertyId, {
                source: sourceName,
                value: numberValue,
                valueType: "Number",
                status: "Active",
                sourceURL: ensureAbsoluteUrl(sourceURL)
            }, field)
                .then((data: any) => {
                    setPropertyValuationList((prevList) => [...prevList, {
                        id: data.id,
                        value: data.value,
                        source: data.source,
                        field: field,
                        requestField: saveField,
                        valueType: data.valueType,
                        status: data.status,
                        createdAt: formatToUSDate(new Date(data.createdAt)),
                        sourceURL:data.sourceURL
                    }]);
                })
                .catch(() => {
                    toast.error('Failed to fetch AIV sources');
                }).finally(() => {
                    setLoadingState(false);
                    setIsNew(false);
                    setSourceName("");
                    setSourceURL("");
                    setNumberValue(0);
                    setEditPrefill("");
                })
            //setAivList((prevList) => [...prevList, newEntry]);
        }
    };
    const handleSubmit = () => {
        const filteredData = propertyValuationList.filter(item => item.source === selectedOption)[0];
        if (opportunityId) {
            setLoadingState(true);
            setLoadingMessage("Please wait while the opportunity is repriced...");
            updatePricingForPropertyChanges(auth!.sub, opportunityId, totalProperties, [
                {
                    attribute: filteredData.requestField,
                    newDataSourceID: filteredData.id != "undefined" ? filteredData.id : "",
                    oldDataSourceID: selectedPrevOption,
                    operation: "update",
                    propertyID: propertyId,
                    value: filteredData.value
                }
            ]).then((oppor) => {
                onChange(opportunityId, oppor);
                toast.success("Opportunity updated successfully");
            }).catch((e) => {
                toast.error(e.toString());
            })
                .finally(() => {
                    setLoadingState(false)
                });
        }
    };
    const handleCancel = () => {
        setEditPrefill("");
        setSourceName("");
        setNumberValue(0);
        setIsNew(false);
        setError(null);
    }
    // const handleInputChange = (key: keyof AIVCalculation, value: string | number) => {
    //     setFormData((prev) => ({ ...prev, [key]: value }));
    // };
    const modalToggle = () => {
        setIsNew(false);
        toggleModal();
        setSourceName("");
        setNumberValue(0);
        setError(null);
        setEditPrefill("");
    }
    const onCreateNew = () => {
        setIsNew(true);
        setTimeout(() => inputRef.current?.focus(), 0);
    }

    const handleEditPrefill = (source : string)=>{
        setIsNew(false)
        setTimeout(() => inputRefValue.current?.focus(), 0);
        setEditPrefill(source);
        setSourceName(source);
        setNumberValue(0);
    }

    const handleRemoveSource = (source : PropertyValuationCalculation)=>{
        setOpenDeleteDialog(true)
        setToBeDeleteSource(source)
    }

    const deleteSource = (source : PropertyValuationCalculation)=>{
        setOpenDeleteDialog(false)
        setLoadingState(true);
        setLoadingMessage("Please wait...");
        let field = "";
        switch (type) {
            case PropertyValuationType.AIV:
                field = "estimated_value";
                break;
            case PropertyValuationType.UPB:
                field = "estimated_mortgage_balance";
                break;
            case PropertyValuationType.OA:
                field = "firstLienPostionMortgageOrginationAmount";
                break;
        }
        deletePropertyValueSource(opportunityId, propertyId, source, field)
            .then(() => {
                toast.success('AIV successfully deleted')
                setPropertyValuationList(propertyValuationList.filter(p => p.id !== source.id))
            })
            .catch(() => {
                toast.error('Failed to delete');
            }).finally(() => {
                setLoadingState(false);
                setOpenDeleteDialog(false)
                setToBeDeleteSource(null)
            })
    }

    const onClose = ()=>{
        setOpenDeleteDialog(false)
        setToBeDeleteSource(null)
    }

    return (
        <>
            {toBeDeleteSource && <ConfirmationBox open={openDeleteDialog} onClose={onClose} onConfirm={()=>deleteSource(toBeDeleteSource)} message={`Are you sure you want to delete ${toBeDeleteSource.source} ?`} ></ConfirmationBox>}

            <Dialog open={isOpen} onOpenChange={modalToggle} >
                <DialogContent className="max-w-[50%] overflow-x-hidden border-0 p-0 outline-none">
                    <DialogHeader>
                        <div className="flex w-full bg-[#3B4F72] text-white min-h-20 text-xl items-center">
                            <div className="ps-7">
                                <DialogTitle className="font-bold text-xl space-y-14">{dialogTitle}</DialogTitle>
                            </div>
                        </div>
                    </DialogHeader>

                    <>
                        <DialogLoading className="h-[170px] w-[242px] p-6" loadingMessage={loadingMessage} isLoading={loadingState}></DialogLoading>
                        {!loadingState && (<><div className="space-y-4 px-6 py-2 max-h-80 overflow-auto">
                            <div className="h-full p-2">
                                <table className="table-auto w-full text-left">
                                    <tbody>
                                    {propertyValuationList.map((option) => 
                                    (<>
                                       
                                        <tr key={option.id} className="hover:bg-gray-50">
                                            <td className="p-2 w-[10px]">
                                                <input
                                                    type="radio"
                                                    name="priceOption"
                                                    value={option.source}
                                                    disabled={editPrefill.length > 0}
                                                    onChange={() => handleOptionChange(option.source)}
                                                    checked={option.source == selectedOption}
                                                    className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                            </td>
                                            <td className="p-2 w-[150px] text-sm font-medium text-gray-900">
                                                {option.sourceURL ? (
                                                    <a href={ensureAbsoluteUrl(option.sourceURL)} target="_blank" className="text-blue-600 hover:underline">
                                                        {option.source == 'CrawlBee' ? 'Default Data Source' : option.source}
                                                    </a>
                                                ) : (
                                                    <span>{option.source == 'CrawlBee' ? 'Default Data Source' : option.source}</span>
                                                )}
                                            </td>
                                            <td className="p-2 w-[200px] text-sm text-gray-500">
                                                {formatCurrency(option.value ? option.value : 0)}
                                            </td>
                                            <td className="p-2 w-[100px] text-sm text-gray-500">
                                                {option.createdAt}
                                            </td>
                                            {
                                                PropertyValuationType.AIV === type && option.source !== "CrawlBee" &&
                                                <td>
                                                    <button onClick={handleCancel} className="" title="Delete">
                                                        <Trash onClick={() => handleRemoveSource(option)} className="h-4 w-4" />
                                                    </button>
                                                </td>
                                            }                                            
                                        </tr>
                                        </>
                                    ))}
                                        {
                                            PropertyValuationType.AIV === type && prefilledSources.map(source => {
                                                if (!propertyValuationList.some(p => p.source === source) && source !== editPrefill)
                                                    return <tr>
                                                        <td colSpan={4} className="p-2 ">
                                                            <button
                                                                onClick={() => handleEditPrefill(source)}
                                                                className="flex items-center py-1 space-x-2"
                                                                title="Create New Source"
                                                                disabled={editPrefill.length > 0}
                                                            >
                                                                <Plus className={`w-5 h-5 rounded-full ${editPrefill.length > 0 ? "text-blue-200 bg-white border-2 border-blue-200" : "text-blue-500 bg-white border-2 border-blue-500"}`} />
                                                                <span className={`pl-2 text-sm font-medium${editPrefill.length > 0 ? " text-gray-200 italic" : " text-gray-500 italic"}`}>{source}</span>
                                                            </button>
                                                        </td>

                                                    </tr>
                                                else if(source === editPrefill) return <CreateNewSource
                                                    inputRef={inputRef}
                                                    inputRefValue={inputRefValue}
                                                    sourceName={sourceName}
                                                    sourceURL={sourceURL}
                                                    setSourceName={(e) => setSourceName(e.target.value)}
                                                    setNumberValue={e => setNumberValue(Number(e.target.value))}
                                                    setSourceURL={e => setSourceURL(e.target.value)}
                                                    error={error ?? null}
                                                    numberValue={numberValue}
                                                    handleCancel={handleCancel}
                                                    handleSave={handleSave}
                                                ></CreateNewSource>
                                            })
                                        }
                                        {isNew && (
                                            <CreateNewSource
                                                inputRef={inputRef}
                                                inputRefValue={inputRefValue}
                                                sourceName={sourceName}
                                                sourceURL={sourceURL}
                                                setSourceName={(e) => setSourceName(e.target.value)}
                                                setNumberValue={e => setNumberValue(Number(e.target.value))}
                                                setSourceURL={e => setSourceURL(e.target.value)}
                                                error={error ?? null}
                                                numberValue={numberValue}
                                                handleCancel={handleCancel}
                                                handleSave={handleSave}
                                            ></CreateNewSource>
                                        )}
                                        {(!isNew || editPrefill.length > 0) && (<tr>
                                            <td colSpan={4} className="p-2">
                                                <button
                                                    disabled={editPrefill.length > 0}
                                                    onClick={() => onCreateNew()}
                                                    className="flex items-center py-1 space-x-2"
                                                    title="Create New Source"
                                                >
                                                     <Plus className={`w-5 h-5 rounded-full ${editPrefill.length>0? "text-blue-200 bg-white border-2 border-blue-200" :  "text-blue-500 bg-white border-2 border-blue-500"}`} />
                                                    <span className={`pl-2 text-sm font-medium${editPrefill.length>0? " text-gray-200 italic":" text-gray-500 italic"}`}>Create New Source</span>
                                                     </button>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div><div className="flex-shrink-0 p-6">
                                <button
                                    className={`apply-new-query-button ${!selectedOption ? "opacity-50 cursor-not-allowed" : ""}`}
                                    onClick={() => handleSubmit()}
                                    disabled={!selectedOption}
                                >
                                    Save
                                </button>
                            </div></>)}
                    </>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PropertiesValueChangeDialog;