import React from 'react';

interface TitleProps {
  children: React.ReactNode;
  className?: string;
}

const Title: React.FC<TitleProps> = ({ children, className }) => {
  return (
    <h1
      className={`uppercase text-xl font-semibold ${className || ''}`}
    >
      {children}
    </h1>
  );
};

export default Title;