import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useForm } from "react-hook-form";

interface UploadFileDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => Promise<void>;
}

const UploadFileDialog: React.FC<UploadFileDialogProps> = ({ open, onClose, onUpload }) => {
  const { register, reset } = useForm();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setUploading(true);
      await onUpload(selectedFile); 
      setUploading(false);
      reset();
      setSelectedFile(null);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Upload file</DialogTitle>
      <DialogContent>
        <input
          type="file"
          {...register("file")}
          onChange={handleFileChange}
          accept="image/*, application/pdf, .doc, .docx, .txt"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={uploading}>Cancel</Button>
        <Button onClick={handleUpload} variant="contained" color="primary" disabled={!selectedFile || uploading}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialog;
