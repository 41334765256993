export interface ActivityEvent {
  type: EventType; 
  timestamp: Date;
    description: string; // Main action text
    changes?: ActivityChange[]; // Array of details (optional)
    user: {
      cognitoID: string;
      name: string;
      email: string;
    };
  }
  export interface ActivityChange {
    oldValue:any;
    newvalue:any;
    type:string;
  }

export type ChangeType = {
  jsonValue: string;
  displayValue: string;
  format?:(c:number)=>string | React.ReactNode;
};

export enum EventType {
  ModifyProperty,
  RePricing,
  ModifiedOpportunity,
  UploadedDocument,
  Comment,
  GetPermissions,
  Auth,
  Unkonwn
}

export function getDislplayDescripion(jsonEventType:string, description:string):string{

  switch (jsonEventType) {
    case "login_attempt":
      return "tried to login in icon web app";

    case "get_permissions_attempt":
      return "has ask for his Permissions";

    case "opportunity_status_update_attempt":
      return "has updated Opportunity status";

    case "opportunity_pricing_update_attempt":
      return "has updated Opportunity prices";

    case "loan_tape_ingested_attempt":
      return "has updated Properties by ingested Loan Tape";

    case "comment_attempt":
      return "has Commented";

    case "upload_document_attempt":
      return "";

    case "re_pricing_attmpt":
      return "has Reprices";

    case "property_update_attempt":
      return "has updated Properteis";
    case "offer_calculation_dynamo_pricing_properties":
      return "has repriced Opportunity";
    default:
      return description;
  }
}

export function getEventType(jsonEventType:string):EventType {
  switch(jsonEventType){
    case "login_attempt":
      return EventType.Auth;
    case "get_permissions_attempt":
      return EventType.GetPermissions;
    case "opportunity_status_update_attempt":
      return EventType.ModifiedOpportunity;
    case "comment_attempt":
      return EventType.Comment;
    case "upload_document_attempt":
      return EventType.UploadedDocument;
    case "re_pricing_attmpt":
      return EventType.RePricing;
    case "property_update_attempt":
      return EventType.ModifyProperty;
    default:
      return EventType.Unkonwn;

  }
}